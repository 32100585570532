@import "../components/variables";

.schedule-calendar {
  .filter-wrap {
    display: flex;
    .right-wrap {
      margin-left: auto;
      display: flex;
      align-items: center;
      font-size: 12px;
      .time-wrap {
        border: 1px solid #D3D2E5;
        border-radius: 6px;
        background: #FFFFFF;
        line-height: 24px;
        padding: 0 14px;
        user-select: none;
      }

      .week-btn {
        line-height: 24px;
        background-color: #7D79EC;
        border-radius: 6px;
        box-shadow: 0px 2px 17px 0px #BAB9D9;
        margin: 0 6px;
        color: #fff;
        display: flex;
        .label {
          padding: 0 2px;
        }
        .arrow {
          width: 24px;
          cursor: pointer;
          &:hover {
            background-color: rgba(0,0,0,0.2);
          }
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          &:after {
            content: '';
            position: absolute;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-right: 4px solid #fff;
          }
        }
        .pre {
          border-radius: 6px 0 0 6px;
        }
        .after {
          border-radius: 0 6px 6px 0;
          &:after {
            transform: rotate(180deg);
          }
          &.true:after {
            border-right-color: rgba(#fff, .46);
          }
        }
      }

      .reset {
        box-shadow: 0px 2px 17px 0px #CDCCE5;
        border-radius: 6px;
        border: 1px solid #7D79EC;
        line-height: 24px;
        padding: 0 12px;
        cursor: pointer;
        user-select: none;
        &:hover {
          background-color: #eee;
        }
      }
    }
  }

  .table-wrap {
    margin-top: 16px;
    font-size: 12px;
    display: flex;
    box-shadow: 0px 25px 50px 0px rgba(0,0,0,0.04);
    border-radius: 18px;
    overflow: hidden;
    .t-h {
      display: flex;
      background-color: #F2F4FF;
      line-height: 30px;
      border-bottom: 1px solid #E6E8F0;
    }
    .t-1 {
      width: 32px;
      text-align: center;
    }
    .t-2 {
      width: 108px;
      padding-left: 18px;
    }
    .t-b {
      .t-1 {
        background-color: #FAFBFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .t-2 {
        background-color: #fff;
        border-bottom: 1px solid #F3F4F6;
        display: flex;
        align-items: center;
        .red {
          color: #D25B5B;
        }
      }
    }
    .table-left {
      flex: none;
      &.true {
        position: relative;
        z-index: 1;
        box-shadow: 2px 0 8px 0 rgba(0,0,0,0.1);
      }
      .t-h {
        color: #7E86A6;
      }
      .t-b {
        height: 60px;
        display: flex;
        &:last-child {
          .t-2 {
            border-bottom: 0;
          }
        }
      }
    }
    .table-right {
      flex-grow: 1;
      overflow-y: auto;
      display: flex;
      .t-h {
        justify-content: center;
        padding-left: 0;
      }
      .t-b .t-2 {
        padding-left: 4px;
        padding-right: 4px;
        height: 60px;
        justify-content: center;
        color: rgba(#223343, .58);
        font-weight: 600;
        &:last-child {
          border-bottom: 0;
        }
        .link {
          text-align: center;
          &:hover {
            color: #7D79EC;
            cursor: pointer;
          }
        }
      }
      .t-col.true {
        .t-h {
          background-color: #EAECFE;
        }
        .t-2 {
          background-color: #F4F4FE;
          border-bottom: 1px solid #E6E8F0;
        }
      }
      .t-col.dis {
        color: #7E86A6;
        .t-b .t-2 {
          color: #ddd;
        }
      }
    }
  }
}
