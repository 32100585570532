$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$main: #333;
$theme: #7D79EC;
$theme-light: #E8E8FC;
$dark: darken($theme, 8%);

$img: 'https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate';

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin text-eclipse() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin cross($size, $color: #333) {
  position: relative;
  width: $size + 4px;
  height: $size + 5px;
  cursor: pointer;
  &:before, &:after {
    content: '';
    position: absolute;
    top: calc($size / 2) + 2;
    left: 2px;
    width: $size;
    height: 1px;
    background-color: $color;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(135deg);
  }
}