@import "../components/variables";

.dashboard-index {
  width: 1000px;
  margin: 0 auto;

  .title-wrap {
    min-width: 1000px;
    flex-shrink: 0;
    padding-top: 20px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;

    .title {
      font-weight: 600;
      font-size: 14px;
      color: #334463;
      margin-right: auto;
      .ant-picker {
        margin-left: 6px;
        width: 160px;
      }
    }

    .city-title {
      font-weight: 500;
      font-size: 12px;
      color: #8f95b2;
    }
    .city-wrap {
      margin-left: 15px;
      padding: 0 3px;
      display: flex;
      align-items: center;
      height: 30px;
      background: #ffffff;
      box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      border: 1px solid #e6e6e6;
      .city {
        padding: 0 16px;
        line-height: 24px;
        border-radius: 4px;
        font-size: 12px;
        color: #666666;
        cursor: pointer;
        &.true {
          font-weight: 600;
          background: #7d79ec;
          color: #ffffff;
        }
      }
    }

    .select-city {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .content-wrap {
    padding-bottom: 70px;
    .count-wrap {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .item-wrap {
        position: relative;
        flex: 1;
        min-width: 235px;
        height: 325px;
        background: #ffffff;
        box-shadow: 0px -14px 20px 0px #ededf2;
        border-radius: 8px;
        margin-right: 12px;
        &:last-child {
          margin-right: 0;
        }
        .target {
          margin-top: 16px;
          margin-bottom: 20px;
          text-align: center;
          font-size: 12px;
          line-height: 14px;
          color: #131415;
          .num {
            font-size: 14px;
            color: #2d4566;
          }
        }
        &.blue {
          background: #7d79ec;
          .item-type {
            background: #ffffff;
            color: #7d79ec;
          }
        }
        .item-type {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 34px;
          height: 20px;
          border-radius: 5px 0 0 5px;
          font-weight: 600;
          font-size: 14px;

          background: #7d79ec;
          color: #ffffff;
          position: absolute;
          top: 13px;
          right: 0;
        }
        .item-info {
          width: 235px;
          margin: auto;
          &.day {
            color: #ffffff;
            padding-left: 18px;
            .text1 {
              font-size: 12px;
              margin-top: 25px;
              .num {
                line-height: 70px;
                font-size: 70px;
                margin-right: 4px;
              }
            }
            .text2 {
              margin-top: 4px;
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
            }
            .chart {
              margin-top: 35px;
              margin-bottom: 24px;
              width: 200px;
              height: 6px;
              background: #ffffff;
              border-radius: 6px;
              .end {
                width: 0%;
                max-width: 100%;
                position: relative;
                height: 6px;
                background: #ffc000;
                border-radius: 6px;
                transition: all 1s;
                .pre {
                  font-size: 12px;
                  position: absolute;
                  top: -20px;
                  right: -10px;
                }
              }
            }
            .desc {
              line-height: 14px;
              font-size: 12px;
              margin-bottom: 7px;
              .yellow {
                color: #ffc000;
              }
            }
            .msg {
              margin-top: 40px;
              font-weight: 600;
              font-size: 12px;
              .num {
                font-size: 20px;
              }
            }
          }
          &.week {
            .week-chart {
              margin-bottom: 17px;
              padding-left: 18px;
              display: flex;
              align-items: center;
              font-size: 12px;
              line-height: 14px;
              color: #9da3aa;
              &.end {
                color: #2e4d6f;
              }
              &.true {
                color: #254669;
                .chart {
                  .end {
                    background: #7b77eb;
                    color: #ffffff;
                  }
                }
              }
              .name {
                width: 60px;
              }
              .chart {
                width: 110px;
                height: 14px;
                border-radius: 5px;
                border: 1px solid #c8c7f7;
                .end {
                  max-width: 100%;
                  height: 12px;
                  background: #dddcfa;
                  border-radius: 4px;
                  font-size: 12px;
                  color: #7b77eb;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  padding-right: 2px;
                }
              }
              .pre {
                margin-left: 3px;
                font-size: 12px;
                color: #8f95b2;
              }
            }
          }
          &.month {
            .desc {
              font-size: 12px;
              line-height: 14px;
              color: #8e95b5;
              margin-bottom: 7px;
              display: flex;
              align-items: center;
              .label {
                width: 55%;
                text-align: right;
              }
              .gray {
                font-weight: 600;
                color: #8e95b5;
              }
              .red {
                font-weight: 600;
                color: #e2625e;
              }
            }
          }
        }
        &.star {
          background: #efefef;
          .day-num {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            font-size: 12px;
            color: #131415;
            background-image: url($img + "/data/red1.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .num {
              margin-right: 6px;
              width: 40px;
              height: 29px;
              background: #d26a63;
              border-radius: 6px;
              font-size: 24px;
              font-weight: 600;
              color: #fefefe;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .table {
            height: 265px;
            background: #ffffff;
            box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.1);
            border-radius: 8px;

            .col-1 {
              width: 32%;
              text-align: center;
            }
            .col-2 {
              width: 25%;
              text-align: center;
            }
            .col-3 {
              width: 20%;
              text-align: center;
            }
            .col-4 {
              width: 23%;
              text-align: center;
            }
            .table-head {
              height: 50px;
              display: flex;
              align-items: center;
              font-weight: 600;
              font-size: 12px;
              color: #254669;
            }
            .table-body {
              .row {
                height: 30px;
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 12px;
                color: #9d9aef;
                background: #fbfbfb;
                margin-bottom: 1px;
                &.true {
                  margin-top: 10px;
                }
                &.hover {
                  &:hover {
                    background: #efeef9;
                    color: #7b77eb;
                  }
                }
                &.star-1 {
                  color: #e2625e;
                  .col-1 {
                    color: #d26a63;
                  }
                }
                &.star-2 {
                  color: #a67977;
                  .col-1 {
                    color: #a07b79;
                  }
                }
              }
            }
          }
        }
      }
    }
    .chart-wrap {
      margin-top: 10px;
      height: 446px;
      background: #ffffff;
      box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      padding: 14px 20px 12px 16px;

      .info-wrap {
        display: flex;
        align-items: center;

        .chart-title {
          font-weight: 600;
          font-size: 16px;
          color: #333333;
        }

        .chart-desc {
          font-weight: 400;
          font-size: 12px;
          color: #8f95b2;
          margin-left: 10px;
          margin-right: auto;
        }
        .date-wrap {
          margin-right: 3px;
          display: flex;
          align-items: center;
          padding: 3px 4px;
          background: #fdfdfd;
          border-radius: 4px;
          border: 1px solid #eeeeee;
          &.true {
            margin-left: auto;
          }
          .date-type {
            width: 24px;
            height: 24px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #666666;
            cursor: pointer;
            &.true {
              background: #ebeaf9;
              font-weight: 600;
              color: #323232;
            }
          }
        }
        .now-wrap {
          margin: 0 8px;
          padding: 3px 4px;
          background: #fdfdfd;
          border-radius: 4px;
          border: 1px solid #eeeeee;
          .now {
            width: 48px;
            height: 24px;
            border-radius: 4px;
            font-size: 12px;
            color: #666666;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &.true {
              background: #ebeaf9;
              font-weight: 600;
              color: #323232;
            }
          }
        }
        .pr .ant-select-selector {
          padding-right: 40px;
        }
        .ant-select-selection-overflow {
          flex-wrap: nowrap;
          overflow: hidden;
        }

        .chart-type {
          margin-left: 8px;
          display: flex;
          align-items: center;
          background: #fdfdfd;
          border-radius: 4px;
          border: 1px solid #eeeeee;
          padding: 3px;
          .item {
            width: 56px;
            height: 24px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #666666;
            cursor: pointer;
            &.true {
              background: #ebeaf9;
              font-weight: 600;
              color: #323232;
            }
          }
        }
      }

      .target-wrap{
        display: flex;
        align-items: center;
        margin-left: 8px;
      }
    }
  }
  .contrast-wrap {
    display: flex;
    margin-bottom: 10px;
    .contrast-item {
      overflow: hidden;
      flex: 1;
      height: 112px;
      background: #ffffff;
      box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
      .red-title {
        color: #d26a63;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 10px;
        font-size: 12px;
        .gray {
          color: #8e95b5;
        }
        .span-title {
          position: relative;
          color: #000;
          &:after {
            content: "";
            position: absolute;
            bottom: 4px;
            width: 100%;
            height: 6px;
            background: linear-gradient(270deg, #ffffff 0%, #fb9390 100%);
          }
          .value {
            position: relative;
            z-index: 2;
          }
        }
      }
      .number-wrap {
        flex-shrink: 0;
        .number {
          color: #2d4566;
          font-size: 38px;
          line-height: 1.4;
        }
        .unit {
          padding-left: 2px;
          color: #adb2b8;
        }
      }
      .num-box {
        padding-left: 16px;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        .tip-text {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          margin-right: 10px;
          // overflow: hidden;

          .arrow {
            flex-shrink: 0;
            width: 0;
            height: 0;
            border: 8px solid transparent;
            border-right: 18px solid #e8e7ff;
          }
          .text {
            // height: 30px;
            // line-height: 30px;
            background: #e8e7ff;
            border-radius: 7px;
            padding-left: 10px;
            padding-right: 10px;
            padding: 5px 10px;
            font-weight: 400;
            font-size: 12px;
            color: #254669;
            // @include text-eclipse();
          }
        }
      }
      .user-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 16px;
        padding-right: 16px;
        font-size: 12px;
        color: #254669;
        .head-img {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-size: cover;
          margin-right: 7px;
        }
      }
      .first-user {
        margin-top: 27px;
        height: 57px;
        background: #ffffff;
        box-shadow: 0px -10px 12px 0px rgba(174, 169, 169, 0.1);
        border-radius: 8px;
        border: 1px solid #7d79ec;
        display: flex;
        align-items: center;
        .head-img {
          margin-left: 16px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-size: cover;
          margin-right: 7px;
          position: relative;
          .head-icon {
            width: 16px;
            height: 16px;
            background-image: url($img + "/data/crown.png");
            background-size: cover;
            position: absolute;
            top: -8px;
            right: -2px;
          }
        }
        .first-name {
          font-weight: 600;
          font-size: 12px;
          color: #254669;
        }
        .first-num {
          margin-right: 10px;
          margin-left: auto;
          font-weight: 600;
          font-size: 28px;
          color: #d26a63;
        }
      }
    }
    .compare-panel {
      height: 100%;
      min-width: 235px;
      justify-content: space-between;
      padding-left: 16px;
      .title {
        font-size: 12px;
        color: #7d79ec;
        .gray {
          color: #9ba3ab;
        }
      }
      .green {
        color: #00df6c;
      }
      .red {
        color: #e2625e;
      }
      .trend {
        color: #254669;
      }
    }
  }
  // 城市排名
  .city-panel {
    background: #ffffff;
    box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    // height: 84px;
    margin-top: 12px;
    padding: 0 12px 8px;
    position: relative;
    .title {
      position: absolute;
      background-color: #fff;
      border-radius: 8px 0 0 0;
      z-index: 2;
      left: 0;
      top: 0;
      padding: 8px 8px 0px 12px;
      color: #2d4566;
      .icon {
        @include size(16);
        background-image: url($img + "/data/rank.png");
        margin-right: 6px;
        background-size: cover;
        margin-top: -1px;
      }
    }

    .city-wrap {
      overflow-x: auto;
    }
    .item-wrap {
      margin-top: 60px;
      display: inline-flex;
      flex-wrap: nowrap;
      background: linear-gradient(to right, #7d79ec 0%, #c9c7f5 100%);
      position: relative;
      .item {
        height: 40px;
        border-right: 1px solid #fff;
        display: flex;
        justify-content: center;
        color: #fff;
        font-weight: 600;
        padding-top: 6px;
        position: relative;
        .num {
          color: rgba(255, 255, 255, 0.4);
          display: flex;
          justify-content: flex-end;
          padding-right: 6px;
          line-height: 1;
        }
        &.true {
          background-color: #d26a63;
        }
      }
      .rank-badge {
        position: absolute;
        top: -54px;
        left: 100%;
        margin-left: 17px;
        white-space: nowrap;
        padding: 0 6px;
        line-height: 22px;
        color: #254669;
        border-radius: 7px;
        background-color: #e4e3fb;
        &:before {
          content: "";
          position: absolute;
          left: -32px;
          bottom: -10px;
          width: 44px;
          border-top: 1px solid #9e9bee;
          transform: rotate(-72deg);
        }
        &:after {
          content: "";
          position: absolute;
          left: -4px;
          bottom: 2px;
          height: 8px;
          border-left: 1px solid #9e9bee;
        }

        &.true {
          left: unset;
          right: 17px;
          margin-left: 0;
          &:before {
            left: unset;
            right: -32px;
            transform: rotate(73deg);
          }
          &:after {
            left: unset;
            right: -4px;
          }
        }
      }
      .gif-icon {
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        right: 0;
        top: -38px;
        animation-name: run;
        animation-duration: 1.5s;
        animation-timing-function: linear;
      }
      @keyframes run {
        from {
          right: 0;
        }
      }
    }
  }

  //图表
  .half-pie {
    width: 235px;
    height: 80px;
    margin-bottom: 32px;
    position: relative;
    .half-pie-chart {
      width: 100%;
      height: 100%;
    }
    .num {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #2d4566;
      .u {
        font-weight: 400;
        font-size: 12px;
        color: #adb2b8;
        margin-top: 5px;
      }
    }
  }
  .water-pie {
    width: 235px;
    height: 174px;
    margin-bottom: 46px;
    position: relative;
    .water-chart {
      width: 100%;
      height: 100%;
    }
    .num {
      display: flex;
      align-items: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 38px;
      font-size: 54px;
      color: #2d4566;
      font-weight: 600;
      .u {
        font-weight: 600;
        font-size: 12px;
        color: #adb2b8;
        margin-top: 28px;
        margin-left: -2px;
      }
    }
  }
  .line-chart {
    width: 100%;
    height: 400px;
  }
  .blue {
    color: $theme;
  }
}

.replace-model {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.34);
  display: flex;
  align-items: center;
  justify-content: center;
  .account-wrap {
    width: 700px;
    background-color: #ffffff;
    border-radius: 10px;
  }
  .title-wrap {
    height: 65px;
    border-bottom: 1px solid #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      padding-left: 26px;
      font-weight: 600;
      font-size: 16px;
      color: #333333;
    }
    .close {
      width: 65px;
      height: 65px;
      position: relative;
      cursor: pointer;
      &::before {
        content: "";
        width: 20px;
        height: 1px;
        background-color: #333333;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &::after {
        content: "";
        width: 20px;
        height: 1px;
        background-color: #333333;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
  .content-wrap {
    min-height: 200px;
    max-height: 400px;
    overflow: auto;
    padding:0 26px;
    margin: 26px 0;
    .row-line{
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
    .it-group{
      width: 25%;
      &.time{
        width: 35%;
      }
      .label{
        font-weight: 600;
        color: #666;
      }
      .value{
        font-size: 12px;
      }
    }
  }
}
