@import "../components/variables";

.team-page {
  min-width: 1000px;
  max-width: 1600px;
  margin: 0 auto;
  .blue {
    color: $theme;
  }
  .indigo {
    color: #324463;
  }
  .green {
    color: #20b720;
  }
  .light {
    color: #8f95b2;
  }
  .red {
    color: #e2625e;
  }
  .icon-down {
    margin-left: 4px;
    display: inline-flex;
    width: 8px;
    height: 10px;
    background-size: cover;
    background-image: url($img + "/data/down.png");
  }
  .icon-up {
    margin-left: 4px;
    display: inline-flex;
    width: 8px;
    height: 10px;
    background-size: cover;
    background-image: url($img + "/data/up.png");
  }
  .ord {
    width: 18px;
    height: 24px;
    background-size: cover;
    &.ord-0 {
      background-image: url($img + "/data/1.png");
    }
    &.ord-1 {
      background-image: url($img + "/data/2.png");
    }
    &.ord-2 {
      background-image: url($img + "/data/3.png");
    }
  }

  .head-title {
    padding-top: 20px;
    padding-bottom: 16px;
  }

  /* 上半部分*/
  .panel-wrap {
    height: 330px;
    position: relative;
    .panel {
      position: relative;
      z-index: 2;
      flex: 1;
      margin-right: 7px;
      background-color: #fff;
      border-radius: 8px;

      .caption {
        margin-top: 10px;
        text-align: center;
        position: relative;
        .label {
          position: absolute;
          left: 0;
          width: 34px;
          height: 20px;
          font-weight: 600;
          color: #fff;
          background-color: #7d79ec;
          border-radius: 0 5px 5px 0;
        }
        .heavy {
          padding-left: 3px;
        }
      }

      .num-section {
        height: 115px;
        text-align: center;
        .big-number-wrap {
          padding-top: 4px;
          .big-number {
            font-size: 50px;
            margin-right: 2px;
          }
        }
        .progress {
          height: 6px;
          border-radius: 6px;
          background-color: #dde2dd;
          width: 180px;
          margin: 4px auto 0 auto;
          position: relative;
          .value {
            position: absolute;
            height: 6px;
            border-radius: 6px;
            background-color: #00bb00;
          }
          &.dark {
            background-color: #fff;
            .value {
              background-color: #707070;
            }
          }
        }
      }

      .data-section {
        height: 185px;
        display: flex;
        justify-content: center;
        .first-group {
          margin-top: 30px;
          line-height: 24px;
          .c-0 {
            display: inline-flex;
            min-width: 36px;
          }
        }
        .week-section {
          margin-top: 6px;
          min-width: 160px;
          .week-line {
            margin-top: 10px;
          }
          .f-600 {
            display: inline-flex;
            min-width: 28px;
            padding-left: 4px;
          }
          .empty {
            margin-left: 38px;
            justify-content: flex-end;
          }
          .percent {
            margin-top: 2px;
            height: 3px;
            background-color: #dde2dd;
            border-radius: 3px;
            position: relative;
            .value {
              position: absolute;
              left: 0;
              height: 3px;
              background-color: #00bb00;
              border-radius: 3px;
            }
          }
        }
        .count-day {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 28px;
          .day-badge {
            line-height: 24px;
            color: #fff;
            background-color: #d26a63;
            border-radius: 12px;
            padding: 0 11px;
          }
        }
        .blank {
          margin: 20px auto 0 auto;
          width: 69px;
          height: 60px;
          background-image: url($img + "/data/empty.png");
          background-size: cover;
        }
        .day-tip {
          margin-top: 16px;
        }
        .dot-wrap {
          padding-top: 10px;
        }
        .dot-line {
          margin-top: 12px;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            left: -9px;
            top: 7px;
            @include size(4);
            border-radius: 50%;
            background-color: #707070;
          }
        }

        &.blue-bg {
          background: linear-gradient(180deg, #f5f9fc 0%, #fffdfc 100%);
          box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.04);
          border-radius: 8px;
        }
      }

      &.gray-bg {
        z-index: 1;
        background-color: #efefef;
        box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.1);
        .caption .label {
          color: #fff;
          background-color: #2d4566;
          width: 42px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  /* 下半部分 */
  .bottom-half {
    margin-top: 7px;
    .chart-panel {
      background-color: #fff;
      box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      flex: 1;
      margin-right: 7px;
      .title-wrap {
        margin-top: 10px;
        position: relative;
        padding: 0 16px;
        .title {
          position: relative;
          .text {
            position: relative;
            z-index: 1;
          }
          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 2px;
            width: 100px;
            height: 9px;
            background: linear-gradient(270deg, #ffffff 0%, #c8c6ff 100%);
          }
        }
        .badge {
          border-radius: 6px;
          padding: 0 9px;
          line-height: 20px;
          background-color: #e6e5ff;
        }
      }
      .desc-wrap {
        margin-top: 13px;
        padding-left: 16px;
        display: flex;
        align-items: center;
        font-size: 12px;
        .desc-title {
          color: #313131;
        }
        .label {
          padding-left: 30px;
          font-weight: 600;
          color: #686868;
          position: relative;
          &::before {
            content: "";
            width: 5px;
            height: 14px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
          }
          &.green {
            &::before {
              background: linear-gradient(180deg, #a4d252 0%, #ffffff 100%);
            }
          }
          &.red {
            &::before {
              background: linear-gradient(180deg, #d26a63 0%, #ffffff 100%);
            }
          }
        }
        .gray {
          font-weight: 400;
          color: #8e95b5;
        }
      }
    }
    .rank-panel {
      flex: 1;
      .first-line {
        margin-bottom: 7px;
      }
      .rank {
        flex: 1;
        background: linear-gradient(180deg, #ffe8e8 0%, #ffffff 30%);
        height: 133px;
        margin-right: 7px;
        border-radius: 8px;
        box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.04);
        &.false {
          background: linear-gradient(180deg, #e5e5e5 0%, #ffffff 30%);
        }
        &:last-child {
          margin-right: 0;
        }
        .title {
          padding-left: 13px;
          margin-top: 12px;
        }

        .item {
          display: flex;
          justify-content: space-around;
          font-size: 12px;
          margin-top: 11px;
          .index {
            width: 46px;
            display: flex;
            justify-content: center;
            .ord {
              position: absolute;
              margin-top: -2px;
            }
          }
          .city {
            width: 46px;
          }
          .name {
            width: 60px;
            @include text-eclipse;
          }
          .number {
            width: 30px;
          }
          .percent {
            width: 40px;
          }
        }
      }
    }
  }
  

  .half-pie {
    display: inline-block;
    position: relative;
    .half-pie-chart {
      width: 240px;
      height: 115px;
      background-image: url($img + "/data/green.png");
      background-size: cover;
    }
    .num {
      font-weight: 600;
      font-size: 34px;
      color: #324463;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 20px;
    }
    .label-wrap {
      color: #b2beab;
      font-size: 8px;
      display: flex;
      align-items: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 8px;
      .label {
        padding: 0 6px;
        position: relative;
        &::before {
          content: "";
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: #a4d252;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0px;
        }
        &.true {
          &::before {
            background: #74be8b;
          }
        }
      }
    }
  }
  .line-chart {
    width: 100%;
    height: 200px;
  }
}
