@import "../components/variables";

.progress-page {
  min-width: 800px;
  display: flex;
  flex-direction: column;
  .head-title {
    color: #334463;
    padding-top: 20px;
    padding-bottom: 16px;
    flex-shrink: 0;
    .ant-picker {
      width: 110px;
    }
  }
  .content-wrap {
    flex-grow: 1;
    overflow: auto;
    .info {
      .info-left {
        flex-shrink: 0;
        width: 371px;
        height: 59px;
        border-right: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        .month {
          height: 29px;
          background: #72c7ae;
          font-weight: 600;
          font-size: 14px;
          color: #131415;
          text-align: center;
          line-height: 29px;
        }
        .day-wrap {
          padding: 0 6px;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: space-between;
          height: 21px;
          background: #e3e4e6;
          font-weight: 600;
          font-size: 12px;
          color: #666666;
          .black {
            color: #000000;
          }
          .red {
            color: #d24d49;
          }
        }
        .progress {
          height: 8px;
          background: #e43f42;
          .used {
            height: 100%;
            background: #cdcecf;
          }
        }
      }
      .info-right {
        flex-shrink: 0;
        flex-grow: 1;
        height: 59px;
        background: #e3e4e6;
        border-right: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 20px;
        color: #131415;
        position: relative;
        .time {
          margin-left: 20px;
        }
        .desc {
          width: 204px;
          height: 22px;
          background: #d8d8d8;
          font-weight: 400;
          font-size: 14px;
          color: #131415;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 9px;
        }
      }
    }
    .table {
      display: inline-block;
      font-weight: 600;
      font-size: 12px;
      color: #131415;
      .center {
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
      }
      .col-1 {
        width: 61px;
        height: 31px;
      }
      .col-2 {
        width: 81px;
        height: 31px;
      }
      .col-3 {
        width: 67px;
        height: 31px;
      }
      .col-4 {
        width: 95px;
        height: 31px;
      }
      .col-5 {
        width: 67px;
        height: 31px;
      }
      .col-6 {
        width: 134px;
        height: 31px;
        background: #ddebf7;
      }
      .col-7 {
        width: 134px;
        height: 31px;
        background: #cfe3f4;
      }
      .col-8 {
        width: 134px;
        height: 31px;
        background: #c0ddf6;
      }
      .col-9 {
        width: 134px;
        height: 31px;
        background: #a1d2fc;
      }
      .col-10 {
        width: 98px;
        height: 31px;
        background: #77b6eb;
      }
      .col-11 {
        width: 79px;
        height: 31px;
        background: #e7c2c2;
      }
      .col-12 {
        width: 79px;
        height: 31px;
        background: #e4cfa6;
      }

      .table-head {
        display: flex;
        .col-1,
        .col-2,
        .col-3,
        .col-4,
        .col-5 {
          background: #72c7ae;
        }
        .desc {
          font-weight: 400;
          font-size: 12px;
          color: #747e88;
        }
      }
      .table-body {
        .area {
          border-bottom: 1px solid #ffffff;
        }
        .user {
          display: flex;
          .col-1 {
            height: unset;
            @include text-eclipse();
          }
        }
        .city-wrap {
          .col-2 {
            display: flex;
            align-items: center;
            border-right: 1px solid #ffffff;
            border-bottom: 1px solid #ffffff;
            overflow: hidden;
          }
          .city-name {
            margin-left: 10px;
            flex-shrink: 0;
          }
          .star {
            color: #d80408;
          }
          .star-icon {
            width: 14px;
            height: 14px;
            background-image: url($img + "/all/star.png");
            background-size: cover;
          }
          .col-4 {
            border-right: 1px solid #ffffff;
            border-bottom: 1px solid #ffffff;
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: center;
            .percent {
              height: 100%;
              width: 0%;
            }
            .num {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          .col-11 {
            background: #f1e2e2;
          }
          .col-12 {
            background: #eee6d6;
          }
          .red {
            color: #e43f42;
          }
        }
      }
    }
  }
}
