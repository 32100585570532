@import "../components/variables";

.operate-list {
  .operate-fix-wrap {
    max-width: 1600px;
    margin: auto;
    .empty-msg {
      margin-top: 30px;
    }
  }
  .blue1 {
    color: #8891B2;
  }
  .blue2 {
    color: #7E86A6;
  }
  .green {
    color: #09AF7B;
  }
  .tab-wrap {
    display: flex;
    height: 32px;
    align-items: center;
    .tab {
      width: 96px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: #EEEEEE;
      margin-right: 8px;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      user-select: none;
      &:hover {
        background-color: $theme-light;
      }
      &.true {
        background-color: $theme;
        color: #fff;
      }
    }
  }
  .search-section {
    padding-top: 16px;
    margin-bottom: 10px;
    .search-wrap {
      position: relative;
      .search-input {
        width: 320px;
        padding-left: 14px;
        height: 36px;
        border: 0;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 0 0 1px #D3D2E5;
        &:hover {
          box-shadow: 0 0 0 1px $theme;
        }
        &:focus {
          outline: 0;
          box-shadow: 0 0 0 2px $theme;
        }
      }
      .del-wrap {
        display: none;
        position: absolute;
        @include size(36);
        right: 50px;
        top: 0;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .del-icon {
          @include cross(16px);
        }
        &.true {
          display: flex;
        }
      }
      .search-icon {
        cursor: pointer;
        position: absolute;
        width: 50px;
        height: 36px;
        top: 0;
        right: 0;
        background-image: url($img + '/all/search.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        border-radius: 8px;
        &:hover {
          background-color: #eee;
        }
      }
    }
    .filter-wrap {
      display: flex;
      align-items: center;
      .ant-select-selection-item {
        font-weight: 600;
      }
      .type-group {
        display: flex;
        height: 32px;
        border: 1px solid #EEEEEE;
        border-radius: 4px;
        padding: 3px 4px;
        margin-left: 10px;
        .type {
          width: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
          margin-right: 4px;
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            background-color: #EBEAF9;
          }
          &.true {
            background-color: #EBEAF9;
            font-weight: 600;
          }
        }
      }
      .check-group {
        display: flex;
        align-items: center;
        padding-left: 16px;
        font-weight: 600;
        flex: none;
      }
      .filter-btn {
        flex: none;
        box-shadow: 0 2px 17px 0 #CFE0DF;
        border-radius: 4px;
        background-color: #8F8DCF;
        height: 28px;
        padding-left: 28px;
        display: flex;
        align-items: center;
        padding-right: 8px;
        color: #fff;
        margin-left: 16px;
        background-image: url($img + '/all/filter.png');
        background-size: 14px;
        background-repeat: no-repeat;
        background-position: 8px 7px;
        &:hover {
          background-color: darken(#8F8DCF, 8%);
        }
      }
    }
  }

  /*搜索面板*/
  .search-panel {
    min-width: 1128px;
    height: 0;
    overflow: hidden;
    background-color: #fff;
    padding-left: 12px;
    padding-right: 10px;

    &.true {
      height: unset;
      border-radius: 8px;
      box-shadow: 0 5px 10px 0 rgba(0,0,0,0.04);
    }
    .mr-10 {
      margin-right: 10px;
    }

    .first-wrap {
      display: flex;
      align-items: center;
      .time-wrap {
        margin-right: 20px;
        margin-top: 10px;
      }
      .ep-wrap {
        margin-top: 10px;
      }
      .blue1 {
        padding-right: 10px;
      }
      .pl-20 {
        padding-left: 20px;
      }
      .ab-wrap {
        width: 120px;
        margin-right: 10px;
      }
    }

    .second-wrap {
      padding-top: 10px;
      padding-bottom: 10px;
      .act {
        width: 44px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        border-radius: 7px;
        margin-right: 10px;
        &.search {
          background-color: #E1E0FB;
          &:hover {
            background-color: darken(#E1E0FB, 8%);
          }
        }
        &.reset {
          background-color: #EEEEEE;
          &:hover {
            background-color: #ddd;
          }
        }
      }
      .tog-act {
        color: #8F95B2;
        padding-left: 6px;
        line-height: 28px;
        border-radius: 7px;
        padding-right: 22px;
        position: relative;
        &:hover {
          background-color: #f8f8f8;
        }
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          right: 4px;
          @include size(8);
          border-top: 2px solid #8F95B2;
          border-left: 2px solid #8F95B2;
          transform: translate(-50%, -25%) rotate(45deg);
        }
      }
    }

    .data-gap-wrap {
      .blue1 {
        margin-right: 10px;
      }
      .input-group {
        margin-right: 16px;
        .f-600 {
          margin-right: 10px;
        }
        .gap {
          height: 1px;
          width: 12px;
          background-color: #d6d6e6;
          margin: 0 5px;
        }
      }
      input {
        width: 40px;
        height: 28px;
        border-radius: 4px;
        border: 0;
        box-shadow: 0 0 0 1px #d6d6e6;
        padding-left: 7px;
        &:focus {
          outline: 0;
          box-shadow: 0 0 0 1px $theme;
        }
      }
    }
  }

  .city-panel {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    margin-top: 20px;
    height: 60px;
    margin-bottom: -10px;
    &.false {
      display: none;
    }
    .user-day-data {
      flex-grow: 1;
      height: 80px;
      position: relative;
      top: 10px;
      background-color: #E1F0FF;
      border-radius: 14px 14px 0 0;
      display: flex;
      .td {
        flex-grow: 1;
        text-align: center;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 17px;
          right: 0;
          width: 1px;
          height: 27px;
          background-color: #B6CFE7;
        }
        &:last-child:after {
          display: none;
        }
        .num {
          font-weight: bold;
          color: #0096F9;
          font-size: 18px;
          padding-top: 7px;
        }
        .green {
          color: #47BB76;
        }
        .red {
          color: #CA000F;
        }
        .label {
          font-size: 12px;
        }
      }
    }
    .city-wrap {
      display: flex;
      margin-left: auto;
      .city {
        padding: 0 8px;
        border-radius: 7px;
        background-color: #EDEDED;
        margin: 10px 0 8px 10px;
        line-height: 28px;
        cursor: pointer;
        flex: none;
        &.true {
          font-weight: 600;
          background-color: #8F8DCF;
          color: #fff;
        }
      }
    }
  }
  .operate-table-wrap {
    position: relative;
    z-index: 1;
  }
  .operate-table {
    margin-top: 10px;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    font-size: 12px;
    background-color: #fff;
    border-spacing: 0;
    box-shadow: 0 15px 50px 0 rgba(0,0,0,0.04);
    th, td {
      padding: 0;
      margin: 0;
    }
    .table-head {
      color: #7E86A6;
      background-color: #F2F4FF;
      height: 30px;
      th {
        font-weight: 400;
      }
      .sort-wrap {
        display: flex;
        line-height: 20px;
        height: 18px;
        width: 100%;
        justify-content: center;
        .sort {
          width: 22px;
          height: 18px;
          background-size: 11px 9px;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
          &:hover {
            background-color: #ddd;
            border-radius: 4px;
          }
        }
        .sort-0 {
          background-image: url($img + '/data/sort0.png');
        }
        .sort-1 {
          background-image: url($img + '/data/sort1.png');
        }
        .sort-2 {
          background-image: url($img + '/data/sort2.png');
        }
      }
    }
    .table-item {
      height: 60px;
      td {
        text-align: center;
        border-bottom: 1px solid #F3F4F6;
      }
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
      .blue-bg {
        background-color: #FAFBFF;
      }
      .relative {
        position: relative;
        .pre {
          position: absolute;
          left: 0;
          top: 0;
          background-color: #eee;
          font-size: 10px;
          padding: 0 4px;
          border-radius: 0 0 4px 0;
        }
      }
    }
    .table-item.true:nth-child(even) {
      background-color: #FBFAFF;
    }
    .link {
      color: #615FAD;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .cost {
      background-color: #F7E3E3;
      padding: 0 8px;
      color: #D25B5B;
      font-weight: 600;
      border-radius: 6px;
      line-height: 18px;
    }
    .date {
      width: 70px;
    }
    .brand {
      min-width: 82px;
    }
    .direct {
      width: 120px;
      padding: 0 10px;
    }
    .red-badge {
      line-height: 18px;
      background-color: #FFEFF0;
      border-radius: 6px;
      padding: 0 5px;
      display: inline-flex;
    }
    .edit-btn {
      width: 60px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background-color: #F2F1FD;
      font-size: 12px;
      color: $theme;
      cursor: pointer;
      text-decoration: none;
      margin: auto;
      .edit-icon {
        @include size(12);
        background-image: url($img + '/all/e.png');
        background-size: cover;
        margin-right: 6px;
      }
      &:hover {
        color: #fff;
        background-color: $theme;
        .edit-icon {
          background-image: url($img + '/all/e1.png');
        }
      }
    }
  }
  .page-wrap {
    justify-content: flex-end;
    padding: 16px 0;
  }

  /*HR信息*/
  .com-hover-modal {
    position: fixed;
    z-index: 999;
    top: 40%;
    left: 60%;
    width: 700px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 31px 50px 0 rgba(0,0,0,0.13);
    .head-wrap {
      background-color: #E4EBF2;
      font-size: 16px;
      font-weight: 500;
      padding-left: 26px;
      line-height: 44px;
    }
    .hr-wrap {
      padding: 10px;
      .hr {
        font-size: 12px;
        border-radius: 8px;
        background-color: #F6F7F7;
        padding-left: 16px;
        line-height: 34px;
        .group {
          width: 155px;
        }
      }
    }
  }
}