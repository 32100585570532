@import "./components/variables";

@font-face {
  font-family: 'Heavy';
  src: url('https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/talk_admin/font/PuHui-Heavy.otf') format('truetype');
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: system-ui, -apple-system, Roboto, "Helvetica Neue", "Noto Sans", Arial, sans-serif;
  font-size: 14px;
  //letter-spacing: 1px;
  font-weight: 400;
  line-height: 1.5;
  color: $main;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: auto;
  margin: 0;
}

.heavy {
  font-family: 'Heavy', sans-serif;
}

.f-12 {
  font-size: 12px;
}
.f-14 {
  font-size: 14px;
}
.f-16 {
  font-size: 16px;
}
.f-18 {
  font-size: 18px;
}
.f-400 {
  font-weight: 400;
}
.f-500 {
  font-weight: 500;
}
.f-600 {
  font-weight: 600 !important;
}
.l1 {
  line-height: 1;
}
.c-75 {
  color: #757575;
}
.c-9 {
  color: #999;
}
.c-0 {
  color: #000;
}
.red {
  color: #FF0000;
}
.orange {
  color: #FA931C;
}
.gray {
  color: #BEBEBE;
}
.black {
  color: #333;
}
.underline {
  text-decoration: underline;
}
.hand {
  cursor: pointer;
  user-select: none;
}

.flex {
  display: flex;
  &.align {
    align-items: center;
  }
  &.between {
    justify-content: space-between;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.center{
    justify-content: center;
  }
  &.left{
    justify-content: flex-start;
  }
}

.button {
  line-height: 40px;
  padding: 0 20px;
  border-radius: 6px;
  cursor: pointer;
  &.block {
    color: #fff;
    background-color: $theme;
    &:hover {
      background-color: darken($theme, 8%);
    }
  }
  &.hollow {
    color: $theme;
    border: 1px solid $theme;
    &:hover {
      background-color: #eee;
    }
  }
  &.false {
    background-color: #ccc;
    cursor: not-allowed;
    &:hover {
      background-color: #ccc;
    }
  }
}

//跳转按钮
.plus-button {
  flex: none;
  padding: 0 12px 0 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background: #7d79ec;
  box-shadow: 0 2px 10px 0 #BAB9D9;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  letter-spacing: 1px;
  &:hover {
    background-color: darken($theme, 8%);
  }
  &::before {
    content: "";
    width: 10px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffffff;
    position: absolute;
    top: 15px;
    left: 12px;
  }
  &::after {
    content: "";
    width: 2px;
    height: 10px;
    border-radius: 1px;
    background-color: #ffffff;
    position: absolute;
    left: 16px;
    top: 11px;
  }

  &.no-icon {
    padding-left: 12px;

    &:before, &:after {
      display: none;
    }
  }
  .download-icon {
    @include size(14);
    background-image: url($img + '/all/download.png');
    background-size: cover;
    margin-right: 6px;
    margin-top: -2px;
  }
}

// checkbox
.com-check {
  @include size(20);
  border-radius: 6px;
  border: 2px solid #C7C5C5;
  margin-right: 5px;
  &.true {
    position: relative;
    border-color: $theme;
    background-color: $theme;
    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 5px;
      width: 6px;
      height: 10px;
      border-bottom: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(45deg);
    }
  }
}
.com-check-solid {
  @include size(14);
  border-radius: 4px;
  background-color: #9F9EAE;
  margin-right: 5px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 5px;
    width: 5px;
    height: 8px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
  }
  &.true {
    background-color: $theme;
  }
}

//分页器
.pagination {
  margin-top: 16px;
  margin-bottom: 16px;
}
.empty-msg {
  text-align: center;
  font-size: 12px;
  color: #d2d4db;
  padding: 10px 0;
}

@import "./components/form/form";
@import "./common/modal";

/* 滚动条样式 */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}
.logout-link:hover {
  color: $theme;
}
