@import "../components/variables";

.account-content {
  .title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .list-card {
    margin-bottom: 10px;
    .group-name {
      display: inline-block;
      padding: 6px 8px 20px;
      background: #eeeeee;
      border-radius: 7px;
      font-weight: 600;
      font-size: 12px;
      color: #223343;
      line-height: 17px;
      margin-bottom: -16px;
    }
    .info-wrap {
      position: relative;
      min-height: 126px;
      background: #ffffff;
      box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.04);
      border-radius: 16px;
      .wrap-1 {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          padding-left: 5px;
          display: flex;
          align-items: center;
          .user-wrap {
            padding: 0 6px;
            display: flex;
            align-items: center;
            height: 30px;
            background: #fff8eb;
            border-radius: 16px;
            .head-img {
              width: 22px;
              height: 22px;
              border-radius: 50%;
              background-color: #dddddd;
              background-size: cover;
            }
            .username {
              margin-left: 5px;
              font-weight: 600;
              font-size: 14px;
              color: #223343;
            }
            .line {
              width: 1px;
              height: 15px;
              background: #f8e4be;
              margin-left: 4px;
              margin-right: 6px;
              &.org {
                background: #ff754c;
                margin-left: 5px;
                margin-right: 4px;
              }
            }
            .goal-wrap {
              display: flex;
              align-items: center;
              flex-wrap: nowrap;
              position: relative;
            }
            .more-goal {
              text-wrap: nowrap;
              padding: 0 8px;
              max-width: 500px;
              line-height: 30px;
              background: #ffffff;
              box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.11);
              border: 1px solid rgba(255, 255, 255, 0.16);
              border-radius: 10px;
              font-size: 12px;
              color: #223343;
              display: flex;
              position: absolute;
              top: -40px;
              left: 0;
              &::after {
                content: "";
                width: 0;
                height: 0;
                border-top: 8px solid #ffffff;
                border-bottom: 8px solid transparent;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                position: absolute;
                bottom: -15px;
                left: 45px;
              }
              .it-line {
                width: 1px;
                height: 10px;
                background: #d8d8d8;
                opacity: 0.26;
                margin: 0 4px;
              }
              .it {
                display: flex;
                align-items: center;
              }
            }
            .goal {
              margin-left: 6px;
              display: flex;
              align-items: center;
              font-weight: 500;
              font-size: 12px;
              color: #ff754c;
              .num {
                font-weight: 600;
                font-size: 16px;
              }
            }
            .star {
              width: 14px;
              height: 14px;
              background-image: url($img + "/all/star.png");
              background-size: cover;
            }
          }
          .sex {
            margin-left: 10px;
            font-size: 12px;
            color: #999999;
          }
          .tel {
            margin-left: 15px;
            font-weight: 600;
            font-size: 12px;
            color: #7c86a9;
          }
        }
        .right {
          width: 30%;
          padding-right: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .time {
            font-size: 12px;
            color: #999999;
          }
          .stop-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 24px;
            background: #ffebf6;
            border-radius: 6px;
            font-size: 12px;
            color: #ff754c;
            cursor: pointer;
            .stop-icon {
              margin-right: 5px;
              width: 12px;
              height: 12px;
              background-image: url($img + "/all/x.png");
              background-size: cover;
            }

            &:hover {
              background: #f66955;
              color: #ffffff;
              .stop-icon {
                background-image: url($img + "/all/x1.png");
              }
            }
          }
          .start-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 24px;
            background: #7D79EC;
            border-radius: 6px;
            font-size: 12px;
            color: #ffffff;
            cursor: pointer;
            .start-icon {
              margin-right: 5px;
              width: 12px;
              height: 12px;
              background-image: url($img + "/all/active.png");
              background-size: cover;
            }

            &:hover {
              background: darken($theme, 8%);
            }
          }
          .edit-btn {
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 24px;
            background: #f2f1fd;
            border-radius: 6px;
            font-size: 12px;
            color: #7d79ec;
            cursor: pointer;
            .edit-icon {
              margin-right: 5px;
              width: 12px;
              height: 12px;
              background-image: url($img + "/all/e.png");
              background-size: cover;
            }

            &:hover {
              background: #7d79ec;
              color: #ffffff;
              .edit-icon {
                background-image: url($img + "/all/e1.png");
              }
            }
          }
        }
      }
      .col-1 {
        width: 34%;
        padding-left: 13px;
      }
      .col-2 {
        width: 18%;
      }
      .col-3 {
        width: 18%;
      }
      .col-4 {
        width: 20%;
      }
      .col-5 {
        width: 10%;
      }
      .wrap-2 {
        display: flex;
        align-items: center;
        text-align: left;
        height: 30px;
        background: #fafbff;
        border-top: 1px solid #e6e8f0;
        font-size: 12px;
        color: #7e86a6;
      }
      .wrap-3 {
        height: 52px;
        display: flex;
        align-items: center;
        .account {
          margin-right: 5px;
          max-width: 110px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0 5px;
          height: 24px;
          line-height: 24px;
          background: #e8e8fc;
          border-radius: 5px;
          font-size: 12px;
          color: #333333;
        }
        .info {
          font-weight: 600;
          font-size: 12px;
          color: #333333;
        }
      }
      .more {
        font-size: 12px;
        color: #54528e;
        text-decoration: underline;
        cursor: pointer;
        &.ml-10 {
          margin-left: 10px;
        }
      }
    }
  }
  .account-model {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.34);
    display: flex;
    align-items: center;
    justify-content: center;
    .account-wrap {
      width: 700px;
      background-color: #ffffff;
      border-radius: 10px;
    }
    .title-wrap {
      height: 65px;
      border-bottom: 1px solid #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        padding-left: 26px;
        font-weight: 600;
        font-size: 16px;
        color: #333333;
      }
      .close {
        width: 65px;
        height: 65px;
        position: relative;
        cursor: pointer;
        &::before {
          content: "";
          width: 20px;
          height: 1px;
          background-color: #333333;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        &::after {
          content: "";
          width: 20px;
          height: 1px;
          background-color: #333333;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
    .content-wrap {
      min-height: 200px;
      max-height: 400px;
      overflow: auto;
      padding: 15px;
      .item {
        padding: 0 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 34px;
        background: #ebebeb;
        border-radius: 5px;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
  }
}
