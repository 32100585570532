@import "../components/variables";

.dict-list {
  .table-wrap {
    .col-start {
      width: 22%;
      flex-shrink: 0;
      padding-left: 30px;
    }
    .col-city {
      width: 42%;
      flex-shrink: 0;
    }
    .col-21 {
      width: 21%;
      flex-shrink: 0;
    }
    .col-grow {
      flex-grow: 1;
    }
    .col-end {
      font-weight: 400;
      width: 15%;
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
    .table-head {
      display: flex;
      align-items: center;
      width: 100%;
      height: 44px;
      background: #f3f6ff;
      border-radius: 16px 16px 0px 0px;
      font-weight: 600;
      font-size: 12px;
      color: #4b5e76;
    }
    .table-content {
      background: #ffffff;
      box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.04);
      border-radius: 0 0 16px 16px;
      .row {
        border-top: 1px solid #e6e8f0;
        height: 44px;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        color: #333333;
      }
      .col-grow {
        color: #999999;
      }
      .del-btn {
        margin-right: 10px;
        width: 60px;
        height: 24px;
        background: #f7f4f6;
        border-radius: 6px;
        font-size: 12px;
        color: #d2a091;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .del-icon {
          width: 12px;
          height: 12px;
          background-image: url($img + "/all/d.png");
          background-size: cover;
          margin-right: 4px;
        }
        &:hover {
          background: #f66955;
          color: #ffffff;
          .del-icon {
            background-image: url($img + "/all/d1.png");
          }
        }
      }
      .edit-btn {
        width: 60px;
        height: 24px;
        background: #f2f1fd;
        border-radius: 6px;
        color: #7d79ec;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .edit-icon {
          width: 12px;
          height: 12px;
          background-image: url($img + "/all/e.png");
          background-size: cover;
          margin-right: 4px;
        }
        &:hover {
          background: #7d79ec;
          color: #ffffff;
          .edit-icon {
            background-image: url($img + "/all/e1.png");
          }
        }
      }
    }
  }
}

.dict-model {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.34);
  display: flex;
  align-items: center;
  justify-content: center;
  .title-wrap {
    height: 65px;
    border-bottom: 1px solid #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      padding-left: 26px;
      font-weight: 600;
      font-size: 16px;
      color: #333333;
    }
    .close {
      width: 65px;
      height: 65px;
      position: relative;
      cursor: pointer;
      &::before {
        content: "";
        width: 20px;
        height: 1px;
        background-color: #333333;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &::after {
        content: "";
        width: 20px;
        height: 1px;
        background-color: #333333;
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
  .button-wrap {
    padding: 8px 56px 30px 26px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .confirm {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 34px;
      background: #7f83f5;
      border-radius: 5px;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .city-wrap {
    width: 556px;
    background: #ffffff;
    border-radius: 10px;
    .content-wrap {
      height: 220px;
      display: flex;
      padding: 40px 56px 40px 26px;
      .label {
        margin-right: 15px;
        margin-top: 4px;
        font-size: 14px;
        color: #757575;
      }
    }
  }
  .city-form {
    padding: 40px 26px 60px 26px;
  }
  .wx-wrap {
    width: 556px;
    background: #ffffff;
    border-radius: 10px;
    &.wid {
      width: 600px;
    }
    .content-wrap {
      padding: 40px 46px 34px 26px;
      .input-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        .label {
          flex-shrink: 0;
          font-size: 14px;
          color: #757575;
        }
        .select {
          flex-grow: 1;
          height: 34px;
          border-radius: 8px;
          border: 1px solid #d6d6e6;
          font-size: 14px;
          padding: 0 14px;
          line-height: 34px;
          @include text-eclipse();
          color: #d2d4db;
          cursor: pointer;
          position: relative;
          &::after {
            content: "";
            width: 8px;
            height: 8px;
            border-bottom: 1px solid #3d4a62;
            border-right: 1px solid #3d4a62;
            transform: rotate(45deg);
            position: absolute;
            right: 13px;
            top: 11px;
          }
          &.true {
            color: #333333;
          }
          &:hover{
            border-color: $theme;
          }
        }
      }
    }
  }
  .select-city {
    width: 700px;
    background: #ffffff;
    border-radius: 10px;
    .content-wrap {
      max-height: 243px;
      background: #f8f8f8;
      overflow: auto;
      padding: 28px 15px 28px 25px;
    }
    .city-box {
      display: flex;
      flex-wrap: wrap;
      .item {
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 0 8px;
        min-width: 58px;
        height: 34px;
        background: #ebebeb;
        border-radius: 5px;
        font-size: 14px;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.true {
          background: #7f83f5;
          color: #ffffff;
          font-weight: 500;
        }
      }
    }
  }
}
