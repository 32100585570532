@import "../variables";

.com-toast {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .toast-msg {
    line-height: 42px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 8px;
    animation: zoomInOut 3s;
    background-color: #fff;
    box-shadow: 0 2px 6px 0 rgba(173,173,173,0.5);
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
    display: flex;
    align-items: center;
    .toast-icon {
      margin-right: 7px;
      background-image: url('https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_web/enterprise/icon-new.png');
      background-size: 80px 50px;
      &.success {
        @include size(16px);
        background-position: 0 0;
      }
      &.warn {
        @include size(16px);
        background-position: -20px 0;
      }
      &.error {
        @include size(16px);
        background-position: -40px 0;
      }
    }
  }
}

@keyframes zoomInOut {
  0%, 100% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  13%, 87% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
