@import "../components/variables";

.schedule-info-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.34);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  .info-panel{
    width: 868px;
    height: 534px;
    background: #FFFFFF;
    border-radius: 8px;
    .title-wrap{
      padding: 30px 0 20px 35px;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      position: relative;
      .close-wrap {
        position: absolute;
        right: 0;
        top: 15px;
        padding: 10px 20px;
        .close-icon {
          @include cross(18);
        }
      }
    }
    .table1{
      padding-left: 35px;
      padding-right: 20px;
      .col1{
        width: 13%;
      }
      .col2{
        width: 11%;
      }
      .col3{
        width: 28%;
      }
      .col4{
        width: 12%;
        color: #424292;
      }
      .col5{
        width: 12%;
        color: #424292;
      }
      .col6{
        width: 12%;
      }
      .col7{
        width: 12%;
        color: #BE2F2F;
        border: none !important;
      }
      .left{
        justify-content: flex-start !important;
        padding-left: 18px;
      }
      .head{
        display: flex;
        height: 34px;
        background: #F8F8FF;
        border-radius: 8px;
        font-weight: 500;
        font-size: 12px;
        color: #323232;
        overflow: hidden;
        >div{
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #DADAFA;
        }
        .col4,.col5,.col6,.col7{
          background: #D2D2F1;
          border-color: #B5B5E4;
        }
      }
      .body{
        .row{
          margin-top: 5px;
          display: flex;
          height: 34px;
          background: #F7F8F8;
          border-radius: 8px;
          font-weight: 400;
          font-size: 12px;
          color: #000000;
          >div{
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #E9F0F0;
          }
        }
      }
    }
    .tab-wrap{
      display: flex;
      margin-top: 40px;
      margin-left: 35px;
      margin-right: 20px;
      margin-bottom: 24px;
      border-bottom: 1px solid #F7F7F7;
      .tab{
        margin-right: 30px;
        padding:0 5px 12px ;
        font-weight: 600;
        font-size: 16px;
        color: #333333;
        cursor: pointer;
        &.true{
          color: #4944C7;
          border-bottom: 2px solid #7D79EC;
        }
      }
    }
    .table2{
      .col4,.col5{
        color: #006861;
      }
      .head{
        background: rgba(100,200,188,0.14);
        >div{
          border-color: #CBECEA;
        }
        .col4,.col5,.col6,.col7{
          background: #D0EDEB;
          border-color: #A8DBD7;
        }
      }
      .body{
        max-height: 200px;
        overflow: auto;
      }
    }
    .table3{
      padding-left: 35px;
      padding-right: 20px;
      .col1{
        width: 5%;
      }
      .col2{
        width: 12%;
      }
      .col3{
        width: 12%;
      }
      .col4{
        width: 12%;
      }
      .col5{
        width: 15%;
      }
      .col6{
        width: 11%;
      }
      .col7{
        width: 11%;
      }
      .col8{
        width: 11%;
      }
      .col9{
        width: 11%;
        border: none !important;
      }
      .left{
        padding-left: 10px;
        justify-content: flex-start !important;
      }
      .flex-c{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .green{
        color: #85C2BE;
      }
      .head{
        display: flex;
        height: 44px;
        background: #E9F7F6;
        border-radius: 8px;
        font-weight: 500;
        font-size: 12px;
        color: #323232;
        overflow: hidden;
        >div{
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #CBECEA;
        }
        .col6,.col7,.col8,.col9{
          background: #D0EDEB;
          border-color: #B5E2DF;
        }
      }
      .body{
        max-height: 200px;
        overflow: auto;
        .row{
          margin-top: 5px;
          height: 34px;
          background: rgba(141,158,156,0.08);
          border-radius: 8px;
          display: flex;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          >div{
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #EEEEEE;
          }
        }
      }
    }
    .over{
      @include text-eclipse;
    }
  }
}