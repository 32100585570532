@import "../components/variables";

.transform-page {
  min-width: 800px;
  display: flex;
  flex-direction: column;
  .head-title {
    color: #334463;
    padding-top: 20px;
    padding-bottom: 16px;
    flex-shrink: 0;
    .ant-picker{
      width: 110px;
    }
    .compare-box {
      background: #ffffff;
      border-width: 1px;
      border-style: solid;
      border-color: #d9d9d9;
      border-radius: 6px;
      margin-right: 12px;
      padding: 2px;
      cursor: pointer;
      .compare {
        display: flex;
        align-items: center;
        padding: 0 4px;
        border-radius: 6px;
        &:hover {
          background-color: rgba($theme, .1);
        }
        &.true {
          font-weight: 600;
          background-color: rgba($theme, .3);
        }
      }
    }
  }
  .content-wrap {
    flex-grow: 1;
    overflow: auto;
  }
  .table-wrap{
    display: flex;
    .left-head{
      position: sticky;
      left: 0;
    }
  }
  .icon-down {
    display: inline-flex;
    width: 8px;
    height: 10px;
    background-size: cover;
    background-image: url($img + "/data/down.png");
  }
  .icon-up {
    display: inline-flex;
    width: 8px;
    height: 10px;
    background-size: cover;
    background-image: url($img + "/data/up.png");
  }
  .red {
    color: #d24d49;
  }
  .green {
    color: #15a87d;
  }
  .transform-table {
    background-color: #fff;
    display: inline-block;
    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #ffffff;
      border-bottom: 1px solid #ffffff;
    }
    .table-head {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      color: #131415;
      .user {
        width: 60px;
        height: 60px;
        background: #a6a4e4;
      }
      .week {
        .week-name {
          height: 30px;
          background: #72c7ae;
        }
        .week-day {
          width: 34px;
          height: 30px;
          background: #c7e8df;
        }
        .week-end {
          width: 54px;
          height: 30px;
          background: #9adac7;
        }
      }
      .total {
        width: 70px;
        height: 60px;
        background: #a3a0ed;
      }
      .target {
        width: 56px;
        height: 60px;
        background: #c4c2ff;
      }
      .percent {
        width: 56px;
        height: 60px;
        background: #c4c2ff;
      }
      .average {
        width: 44px;
        height: 60px;
        background: #c4c2ff;
      }
      .month {
        .month-name {
          width: 180px;
          height: 30px;
          background: #e4cfa6;
        }
        .month-day {
          width: 55px;
          height: 30px;
          background: #f3e5cc;
        }
        .month-trend {
          width: 70px;
          height: 30px;
          background: #f4f4f4;
        }
      }
    }
    .table-body {
      font-weight: 600;
      font-size: 12px;
      color: #131415;
      text-wrap: nowrap;

      .area-wrap {
        margin-bottom: 1px;
      }

      .user {
        width: 60px;
        // background: #d2d1fb;
      }
      .city {
        width: 60px;
        height: 30px;
      }
      .week-day {
        width: 34px;
        height: 30px;
        background: #e2ebe9;
      }
      .week-end {
        width: 54px;
        height: 30px;
        background: #e2ebe9;
        font-size: 14px;
        &.green {
          background: #c7e8df;
        }
        &.red {
          background: #f1e2e2;
        }
      }
      .total {
        width: 70px;
        height: 30px;
        background: #d7d6f5;
      }
      .target {
        width: 56px;
        height: 30px;
        background: #e3e2ff;
      }
      .percent {
        width: 56px;
        height: 30px;
        background: #e3e2ff;
      }
      .average {
        width: 44px;
        height: 30px;
        background: #e3e2ff;
      }
      .month-day {
        width: 55px;
        height: 30px;
        background: #eee6d6;
      }
      .month-trend {
        width: 70px;
        height: 30px;
        background: #f4f4f4;
      }
    }
    .all-wrap {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      color: #fafbff;
      .label {
        width: 120px;
        height: 30px;
        background: #1f18a6;
      }
      .week-day {
        width: 34px;
        height: 30px;
        background: #4741c4;
      }
      .week-end {
        width: 54px;
        height: 30px;
        background: #1f18a6;
      }
      .total {
        width: 70px;
        height: 30px;
        background: #1f18a6;
      }
      .target {
        width: 56px;
        height: 30px;
        background: #1f18a6;
      }
      .percent {
        width: 56px;
        height: 30px;
        background: #4741c4;
      }
      .average {
        width: 44px;
        height: 30px;
        background: #4741c4;
      }
      .month-day {
        width: 55px;
        height: 30px;
        background: #b79a64;
      }
      .month-trend {
        width: 70px;
        height: 30px;
        background: #f4f4f4;
        color: #131415;
      }
    }
  }
}
