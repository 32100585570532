@import "../components/variables";

.com-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0,0,0,.34);
  display: flex;
  align-items: center;
  justify-content: center;
  .com-modal-wrap {
    width: 700px;
    background-color: #fff;
    border-radius: 8px;
    .modal-head {
      height: 64px;
      border-bottom: 2px solid #F7F7F7;
      padding-left: 30px;
      display: flex;
      align-items: center;
      .modal-title {
        font-size: 16px;
        font-weight: 600;
      }
      .close-wrap {
        margin-left: auto;
        padding: 10px 20px;
        .close-icon {
          @include cross(18);
        }
      }
    }
    .modal-action {
      display: flex;
      padding: 28px;
      justify-content: flex-end;
    }
  }
}

/* 新增HR */
.hr-form {
  .com-modal-wrap {
    width: 600px;
  }
  .modal-content {
    padding-left: 30px;
    padding-top: 16px;
    .input-group {
      margin-top: 20px;
    }
    .com-input {
      width: 126px;
    }
    .email .com-input {
      width: 200px;
    }
    .label {
      margin-left: 20px;
    }
  }
}
/* 新增职位 */
.pos-form {
  .pos-content {
    padding-right: 28px;
    .input-group {
      margin-top: 20px;
    }
    .fix-width {
      width: 90px;
      display: flex;
      justify-content: flex-end;
    }
    .with-text {
      height: 310px;
    }
  }
}

/* 职位详情弹窗 */
.pos-list-modal {
  .modal-content {
    height: 300px;
    overflow-y: auto;
    padding: 0 28px;
  }
  .position-item {
    margin-top: 10px;
    .position-head {
      padding-right: 8px;
      display: flex;
      background-color: #F6F7F7;
      border-radius: 8px;
      height: 34px;
      align-items: center;
      .com-check {
        margin-left: 8px;
        margin-right: 0;
      }
      &.true {
        background-color: #E9F7F5;
      }
    }
    .name-wrap {
      width: 220px;
      padding-left: 10px;
      @include text-eclipse;
    }
    .pro-wrap {
      width: 160px;
      @include text-eclipse;
    }
    .city-wrap {
      flex-grow: 1;
    }
    .edit-wrap {
      @include size(24);
      margin-right: 10px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .edit-icon {
        @include size(14);
        background-image: url($img + '/all/e.png');
        background-size: cover;
      }
      &:hover {
        background-color: #E9E9F5;
      }
    }
    .toggle-wrap {
      @include size(26);
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        @include size(8);
        border-top: 2px solid #8F95B2;
        border-left: 2px solid #8F95B2;
      }
      &.up:before {
        transform: translate(-50%, -25%) rotate(45deg);
      }
      &.down:before {
        transform: translate(-50%, -75%) rotate(225deg);
      }
      &:hover {
        background-color: #e8e8e8;
      }
    }
    .position-detail {
      padding: 0 6px;
      height: 0;
      overflow: hidden;
      &.true {
        height: unset;
      }
      .detail-wrap {
        background-color: #FCFCFC;
        border-radius: 0 0 8px 8px;
        padding: 15px 30px;
        max-height: 200px;
        overflow-y: auto;
        .f-600 {
          padding-bottom: 8px;
        }
        .detail {
          white-space: pre-wrap;
        }
      }
    }
  }
}

/*职能弹窗*/
.ab-form {
  .title {
    padding-top: 12px;
    padding-bottom: 10px;
  }
  .left-wrap {
    .title {
      padding-left: 36px;
    }
    .menu-wrap {
      width: 160px;
      padding-left: 20px;
      padding-right: 10px;
      height: 290px;
      overflow-y: scroll;
    }
  }
  .ab-item {
    padding: 0 16px;
    line-height: 32px;
    margin-bottom: 2px;
    border-radius: 4px;
    color: #666;
    @include text-eclipse;
    cursor: pointer;

    &:hover{
      background-color: #f9f9f9;
    }
    &.true {
      background-color: #f2f2f2;
    }
  }
  .right-wrap {
    padding-left: 20px;
    flex-grow: 1;
    .ab-item {
      background-color: #f2f2f2;
      margin-right: 14px;
      margin-bottom: 10px;
      &:hover{
        background-color: $theme-light;
      }
      &.true{
        color: #fff;
        background-color: $theme;
      }
    }
    .gray-bg {
      background-color: #f8f8f8;
      margin-right: 20px;
      border-radius: 6px;
      padding-left: 10px;
      margin-left: -10px;
      min-height: 200px;
      .tip {
        color: #a6a6a6;
      }
    }
  }
  .select-wrap {
    padding-left: 180px;
    height: 32px;
    margin-bottom: -10px;
    .ab-item {
      margin-bottom: 0;
      background-color: $theme-light;
      color: $theme;
    }
  }
}
/*B端开拓*/
.b-modal-form {
  .modal-content {
    height: 160px;
    padding: 20px;
    .b-modal {
      background-color: #F6F7F7;
      border-radius: 8px;
      line-height: 34px;
      padding-left: 16px;
      .item {
        margin-right: 50px;
      }
    }
  }
}
/*导出运营数据*/
.data-table-modal {
  .img-wrap {
    width: 700px;
    overflow-x: hidden;
    height: 300px;
    overflow-y: auto;
  }
  .btn-wrap {
    padding: 30px;
    .plus-button {
      width: 100px;
    }
  }
}