@import "../variables";

.com-choose-area-model {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.34);
  display: flex;
  align-items: center;
  justify-content: center;

  .area-wrap {
    width: 700px;
    background: #ffffff;
    border-radius: 10px;
    .area-title-wrap {
      height: 65px;
      border-bottom: 1px solid #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        padding-left: 26px;
        font-weight: 600;
        font-size: 16px;
        color: #333333;
      }
      .close {
        width: 65px;
        height: 65px;
        position: relative;
        cursor: pointer;
        &::before {
          content: "";
          width: 20px;
          height: 1px;
          background-color: #333333;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        &::after {
          content: "";
          width: 20px;
          height: 1px;
          background-color: #333333;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
    .area-content-wrap {
      padding: 24px 0;
      .input-wrap {
        display: flex;
        align-items: center;
        padding: 0 48px 18px 48px;
        .label {
          padding-right: 42px;
          flex-shrink: 0;
          font-size: 14px;
          color: #757575;
        }
      }
      .search-wrap {
        display: flex;
        padding-bottom: 16px;
        padding-left: 160px;
        .com-input {
          width: 384px;
          flex: none;
        }
        .search-btn {
          line-height: 30px;
          padding: 0 16px;
          &:hover {
            color: $theme;
          }
        }
      }
      .area-city-box {
        height: 300px;
        display: flex;
        .province-wrap {
          flex-shrink: 0;
          width: 160px;
          overflow: auto;
          .item {
            margin: 22px 0;
            line-height: 24px;
            font-size: 14px;
            color: #666666;
            text-align: center;
            border-left: 4px solid transparent;
            cursor: pointer;
            &.true {
              border-color: $theme;
              color: $theme;
            }
          }
        }
        .city-wrap {
          flex-grow: 1;
          background: #f8f8f8;
          overflow: auto;
          .tip {
            padding-left: 28px;
            padding-top: 28px;
            color: #999;
          }
          .item-wrap {
            display: flex;
            flex-wrap: wrap;
            padding: 28px 16px 28px 26px;
            .item {
              user-select: none;
              margin-right: 10px;
              margin-bottom: 10px;
              padding: 0 16px;
              height: 34px;
              line-height: 34px;
              background: #ebebeb;
              border-radius: 5px;
              font-weight: 400;
              font-size: 14px;
              color: #333333;
              cursor: pointer;
              &.true {
                background: #7f83f5;
                font-weight: 500;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .check-wrap {
      padding-left: 160px;
      display: flex;
      .label {
        flex-shrink: 0;
        font-size: 14px;
        color: #999999;
        margin-top: 6px;
      }
      .check-box {
        padding-right: 10px;
        display: flex;
        flex-wrap: wrap;
        .item {
          padding: 0 10px;
          height: 34px;
          line-height: 34px;
          background: #f2f2f2;
          border-radius: 5px;
          font-size: 14px;
          color: #333333;
          margin-right: 5px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          .del-icon {
            margin-left: 7px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: #d8d8d8;
            position: relative;
            cursor: pointer;
            &::before {
              content: "";
              width: 8px;
              height: 1px;
              background: #a6a6a6;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
            }
            &::after {
              content: "";
              width: 8px;
              height: 1px;
              background: #a6a6a6;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(-45deg);
            }
            &:hover{
              opacity: 0.75;
            }
          }
        }
      }
    }
    .button-wrap {
      flex-shrink: 0;
      padding: 8px 56px 30px 26px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .confirm {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 34px;
        background: #7f83f5;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
        &:hover {
          background-color: $dark;
        }
      }
    }
  }
}
