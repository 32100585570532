@import "../components/variables";

.com-layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: #F7F8FC;
  flex-direction: column;

  .com-header {
    flex: none;
    height: 70px;
    background-color: #fff;
    box-shadow: 0 25px 50px 0 rgba(0,0,0,0.04);
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      margin-left: 30px;
      width: 118px;
      height: 20px;
      background-image: url($img + '/all/logo.png');
      background-size: cover;
    }
    .nav-wrap {
      display: flex;
      .nav-item {
        //height: 70px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0 8px;
        &:hover {
          //background-color: #f8f8f8;
          color: $theme;
          .user-name:after {
            border-top-color: $theme;
          }
        }
        .avatar {
          @include size(40);
          background-color: #ddd;
          border-radius: 50%;
          margin-right: 16px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
        .user-name {
          position: relative;
          padding-right: 25px;
          &:after {
            content: '';
            position: absolute;
            right: 9px;
            top: 8px;
            border-top: 5px solid #333;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
          }
        }
      }
    }
  }
  .com-wrap {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }
  /*菜单侧栏*/
  .com-menu {
    flex: none;
    width: 220px;
    background-color: #fff;
    padding: 20px 10px;
    box-shadow: 0 25px 50px 0 rgba(0,0,0,0.04);
    position: relative;
    transition: width 0.5s;
    // 收起侧栏
    .toggle {
      position: absolute;
      background-color: #fff;
      top: 40%;
      right: -16px;
      width: 16px;
      height: 70px;
      border-radius: 0 20px 20px 0;
      cursor: pointer;
      &:before {
        content: '';
        @include size(8);
        left: 50%; top: 50%;
        position: absolute;
        border-style: solid;
        border-width: 2px 0 0 2px;
        transform: translate(-25%, -50%) rotate(-45deg);
      }
      &:hover:before {
        border-color: $theme;
      }
      &.false:before {
        transform: translate(-75%, -50%) rotate(135deg);
      }
    }
    /* 菜单 */
    .menu-item {
      position: relative;
      user-select: none;
    }
    .menu {
      height: 46px;
      display: flex;
      align-items: center;
      font-weight: 500;
      color: #8F95B2;
      cursor: pointer;
      margin-bottom: 10px;
      border-radius: 12px;
      overflow: hidden;
      padding-left: 20px;
      transition: padding-left 0.5s;
      .icon {
        width: 22px;
        height: 20px;
        flex: none;
        margin-right: 14px;
        background-size: cover;
        &.index {
          background-image: url($img + '/menu/c.png');
        }
        &.team {
          background-image: url($img + '/menu/d.png');
        }
        &.transform {
          background-image: url($img + '/menu/d.png');
        }
        &.progress {
          background-image: url($img + '/menu/d.png');
        }
        &.account {
          background-image: url($img + '/menu/a.png');
        }
        &.dict {
          background-image: url($img + '/menu/b.png');
        }
        &.operate-data {
          background-image: url($img + '/menu/d.png');
        }
        &.schedule {
          background-image: url($img + '/menu/d.png');
        }
      }
      .name {
        width: 98px;
        flex: none;
      }
      .arrow {
        @include size(46);
        flex: none;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          @include size(6);
          border-top: 1px solid #8F95B2;
          border-left: 1px solid #8F95B2;
          transform: translate(-50%, -75%) rotate(225deg);
        }
      }
      &:hover {
        background-color: $theme-light;
      }
    }
    .menu.true {
      color: #fff;
      background-color: #7D79EC;
      box-shadow: 0 2px 4px 0 #D7D6F1;
      .icon.index {
        background-image: url($img + '/menu/c1.png');
      }
      .icon.team {
        background-image: url($img + '/menu/d1.png');
      }
      .icon.transform {
        background-image: url($img + '/menu/d1.png');
      }.icon.progress {
        background-image: url($img + '/menu/d1.png');
      }
      .icon.account {
        background-image: url($img + '/menu/a1.png');
      }
      .icon.dict {
        background-image: url($img + '/menu/b1.png');
      }
      .icon.operate-data {
        background-image: url($img + '/menu/d1.png');
      }
      .icon.schedule {
        background-image: url($img + '/menu/d1.png');
      }
      .arrow:after {
        border-color: #fff;
      }
    }
    /* 菜单组 */
    .sub-menu-item {
      line-height: 46px;
      cursor: pointer;
      color: #8F95B2;
      white-space: nowrap;
      &:hover {
        color: lighten($theme, 8%);
      }
      &.true {
        color: $theme;
        font-weight: 600;
      }
    }
    .sub-menu {
      height: 0;
      overflow: hidden;
      .sub-menu-item {
        padding-left: 56px;
      }
    }
    .hover-menu-wrap {
      display: none;
      position: absolute;
      z-index: 1;
      top: 0;
      right: -105px;
      padding-left: 5px;
      .hover-menu {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
        .sub-menu-item {
          width: 100px;
          text-align: center;
        }
      }
    }
    // 菜单组展开
    .menu-item.true {
      .menu .arrow:after {
        transform: translate(-50%, -25%) rotate(45deg);
      }
      .sub-menu {
        height: unset;
      }
    }
    // 侧栏收缩
    &.false {
      width: 66px;
      .menu {
        padding-left: 13px;
      }
      .with-arrow:after {
        display: none;
      }
      .menu-item:hover {
        .hover-menu-wrap {
          display: unset;
        }
      }
    }
  }
  .com-content {
    flex-grow: 1;
    overflow: auto;
    padding: 0 24px 24px;
    .com-fix-wrap {
      width: 976px;
      margin: auto;
    }
  }
}

/* 面包屑 */
.breadcrumb-wrap {
  display: flex;
  color: #4B5E76;
  font-size: 12px;
  font-weight: 600;
  padding: 26px 0;
  .gap {
    padding: 0 4px;
  }
  .light {
    color: $theme;
  }
}

/* 未匹配的路由 */
.no-match {
  height: 90vh;
  color: #8F95B2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .no-match-text {
    margin-bottom: 16px;
  }
  .back-btn {
    color: #fff;
    background-color: $theme;
    line-height: 40px;
    width: 200px;
    text-align: center;
    border-radius: 8px;
    text-decoration: none;
    &:hover {
      background-color: darken($theme, 8%);
    }
  }
}