@import "../variables";

.com-confirm {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.34);
  display: flex;
  align-items: center;
  justify-content: center;

  &.true {
    background-color: transparent;
  }

  .confirm-modal {
    width: 480px;
    background-color: #fff;
    border-radius: 8px;
    .title-wrap {
      height: 65px;
      border-bottom: 1px solid #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        padding-left: 26px;
        font-weight: 600;
        font-size: 16px;
        color: #333333;
      }
      .close {
        width: 65px;
        height: 65px;
        position: relative;
        cursor: pointer;
        &::before {
          content: "";
          width: 20px;
          height: 1px;
          background-color: #333333;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        &::after {
          content: "";
          width: 20px;
          height: 1px;
          background-color: #333333;
          transform: rotate(45deg);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
    .content {
      padding: 25px;
      text-align: center;
      .message {
        font-size: 16px;
        color: #333;
      }
      .sub-message {
        margin-top: 8px;
        color: #aaa;
      }
    }
    .action {
      padding: 25px;
      display: flex;
      justify-content: center;
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 34px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        border: 1px solid #7f83f5;
        color: #7f83f5;
      }
      .contained{
        margin-left: 32px;
        color: #ffffff;
        background: #7f83f5;
      }
    }
  }
}
