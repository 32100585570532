@import "../components/variables";

.com-login {
  min-width: 1200px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient( 219deg, #C6C4F7 10%, #DAF0FF 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  .layout-section {
    width: 80%;
    max-width: 1400px;
    height: 540px;
    display: flex;
    position: relative;
    .logo {
      position: absolute;
      z-index: 2;
      top: -140px;
      left: -20px;
      width: 172px;
      height: 48px;
      background-image: url($img + '/login/logo1.png');
      background-size: cover;
    }
    .bg {
      position: absolute;
      bottom: 20px;
      left: -120px;
      z-index: 1;
      width: 724px;
      height: 232px;
      background-image: url($img + '/login/line.png');
      background-size: cover;
    }
    .caption {
      position: relative;
      z-index: 2;
      flex-grow: 1;
      font-size: 60px;
      padding-top: 60px;
      margin-left: -20px;
      .f-600 {
        margin-top: 10px;
      }
    }
  }

  .login-panel {
    position: relative;
    z-index: 2;
    width: 480px;
    height: 540px;
    background-color: #fff;
    box-shadow: 0 15px 16px 0 #B5B7E9;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-1 {
      margin-top: 52px;
      font-size: 32px;
      margin-bottom: 8px;
    }
    .title-2 {
      color: #5A607F;
      margin-bottom: 60px;
    }
    .input-wrap {
      width: 360px;
      height: 46px;
      background-color: #F6F7FD;
      box-shadow: 0 0 0 1px #D8D8F7;
      border-radius: 10px;
      display: flex;
      align-items: center;
      .input-icon {
        flex: none;
        @include size(18);
        margin-left: 20px;
        margin-right: 20px;
        background-size: cover;
        &.user {
          background-image: url($img + '/login/u1.png');
        }
        &.lock {
          background-image: url($img + '/login/l1.png');
        }
      }
      input {
        background-color: transparent;
        border: none;
        font-size: 16px;
        flex-grow: 1;
        width: 200px;
        &:focus {
          outline: 0;
        }
      }
      &.true {
        background-color: #fff;
        box-shadow: 0 0 0 2px $theme;
        .input-icon.user {
          background-image: url($img + '/login/u.png');
        }
        .input-icon.lock {
          background-image: url($img + '/login/l.png');
        }
      }
    }
    .check-wrap {
      padding-top: 10px;
      padding-bottom: 18px;
      width: 360px;
      display: flex;
      .check-check {
        cursor: pointer;
        color: #999999;
        font-size: 12px;
        line-height: 1;
        user-select: none;
        &:hover {
          color: #666;
        }
      }
      .check {
        @include size(12);
        border: 1px solid #E1E1E1;
        border-radius: 3px;
        margin-right: 8px;
        position: relative;
        &.true {
          background-color: $theme;
          border-color: $theme;
          &:after {
            content: '';
            position: absolute;
            left: 3px;
            top: 1px;
            width: 4px;
            height: 6px;
            border-right: 1px solid #fff;
            border-bottom: 1px solid #fff;
            transform: rotate(45deg);
          }
        }
      }
    }
    .code-text {
      padding-right: 16px;
      line-height: 14px;
    }
    .code-btn {
      line-height: 36px;
      background-color: #E9E9FB;
      color: $theme;
      font-weight: 600;
      border-radius: 8px;
      padding: 0 10px;
      flex: none;
      margin-right: 6px;
      cursor: pointer;
      &:hover {
        background-color: darken(#E9E9FB, 2%);
      }
    }
    .login-btn {
      width: 360px;
      line-height: 54px;
      color: #fff;
      background-color: $theme;
      text-align: center;
      border-radius: 8px;
      font-size: 20px;
      margin-top: 75px;
      cursor: pointer;
      &:hover {
        background-color: darken($theme, 8%);
      }
    }
  }
}