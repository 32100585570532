@import "../components/variables";

.operate-form {
  .form-panel {
    box-shadow: 0 25px 50px 0 rgba(0,0,0,0.04);
    background-color: #fff;
    border-radius: 14px;
    padding-left: 60px;
    padding-top: 32px;
    padding-right: 60px;
    margin-bottom: 40px;
    .caption {
      font-size: 20px;
      color: #B0BBC5;
      margin-bottom: 20px;
    }
    .wrap-wrap {
      width: 690px;
    }
    .title {
      font-size: 18px;
      padding-top: 20px;
      padding-bottom: 20px;
      font-weight: 500;
      &.required {
        position: relative;
        &:after {
          content: '*';
          font-size: 14px;
          position: absolute;
          left: -8px;
          font-weight: 800;
          color: #FA931C;
        }
      }
    }
    // 公共
    .input-line {
      margin-bottom: 16px;
    }
    .input-group.with-text {
      height: 140px;
    }
    .pos-number {
      width: 300px;
    }
  }

  // 基础信息
  .basic-info {
    .gap {
      width: 8px;
    }
    .content-maker .ant-select {
      width: 160px;
    }
  }

  // 企业信息
  .ep-name-wrap {
    width: 400px;
  }

  // HR信息
  .add-button {
    margin-top: 30px;
    font-weight: 600;
    color: $theme;
    display: flex;
    align-items: center;
    line-height: 1;
    cursor: pointer;
    padding: 6px 0;
    .add-icon {
      @include size(18);
      background-color: $theme;
      border-radius: 50%;
      margin-right: 10px;
      position: relative;
      &:before, &:after {
        content: '';
        position: absolute;
        background-color: #fff;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &:before {
        width: 1px;
        height: 10px;

      }
      &:after {
        width: 10px;
        height: 1px;
      }
    }
    &:hover {
      color: $dark;
      .add-icon {
        background-color: $dark;
      }
    }
    &.green {
      color: #64C8BC;
      .add-icon {
        background-color: #64C8BC;
      }
      &:hover {
        color: darken(#64C8BC, 10%);
        .add-icon {
          background-color: darken(#64C8BC, 10%);
        }
      }
    }
  }
  .hr-wrap {
    //margin-right: -60px;
  }
  .hr-item {
    height: 34px;
    display: flex;
    align-items: center;
    background-color: #F6F7F7;
    border-radius: 8px;
    padding-left: 10px;
    margin-bottom: 10px;
    padding-right: 6px;
    &:last-child {
      margin-bottom: 0;
    }
    cursor: pointer;
    &.new {
      background-color: #F1F1FC;
    }
    .new-badge {
      width: 40px;
      height: 20px;
      background-color: $theme;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      margin-right: 6px;
    }
    .name-wrap {
      padding-left: 5px;
      width: 200px;
      .gap {
        padding: 0 3px;
      }
    }
    .job-wrap {
      width: 180px;
    }
    .email-wrap {
      width: 180px;
    }
    .tel-wrap {
      width: 120px;
    }
    .time-wrap {
      margin-left: 20px;
      width: 90px;
      color: #9898BC;
    }
    .edit-btn {
      margin-left: auto;
      padding: 5px;
      border-radius: 4px;
      .edit-icon {
        @include size(16);
        background-size: cover;
        background-image: url($img + '/all/e.png');
      }
      &:hover {
        background-color: #ddd;
      }
    }
  }

  // 职位信息
  .old-pos {
    margin-bottom: 20px;
    display: flex;
    color: #64C8BC;
    .f-600 {
      padding: 0 3px;
    }
    .pos-btn {
      margin-left: 10px;
      line-height: 30px;
      background-color: #64C8BC;
      color: #fff;
      font-weight: 600;
      border-radius: 8px;
      padding: 0 8px;
      cursor: pointer;
      &:hover {
        background-color: darken(#64C8BC, 8%);
      }
    }
  }
  .pos-list {
    //margin-right: -60px;
    .pos-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      height: 34px;
      background-color: #F6F7F7;
      border-radius: 8px;
      padding-left: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      &.new {
        background-color: #E9F7F5;
      }
      .name-wrap {
        width: 300px;
        display: flex;
        font-weight: 600;
        .new-badge {
          width: 40px;
          height: 20px;
          background-color: #64C8BC;
          color: #fff;
          font-size: 12px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          margin-right: 6px;
        }
        .name {
          margin-right: 10px;
        }
      }
      .cate-wrap {
        width: 180px;
      }
      .city-wrap {
        display: flex;
        width: 240px;
      }
      .link {
        cursor: pointer;
        &:hover {
          color: #64C8BC;
        }
      }
      .del-wrap {
        padding: 8px 18px;
        margin-left: auto;
        cursor: pointer;
        .del-btn {
          @include size(18);
          background-color: #D8D8D8;
          border-radius: 50%;
          position: relative;
          &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: #A6A6A6;
            transform: translate(-50%, -50%) rotate(45deg);
          }
          &:before {
            width: 8px;
            height: 1px;
          }
          &:after {
            width: 1px;
            height: 8px;
          }
        }
        &:hover {
          .del-btn {
            background-color: #C7E7E4;
            &:before,&:after {
              background-color: #64C8BC;
            }
          }
        }
      }
    }
  }

  // 公众号数据
  .data-form-item {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background-color: #FCFCFF;
    border: 1px solid #F5F6FA;
    border-radius: 8px;
    padding: 0 16px;
    .com-input {
      width: 94px;
    }
    .city-wrap {
      width: 120px;
      display: flex;
      .city-badge {
        background-color: #EFEFFC;
        color: #7C86A9;
        font-size: 12px;
        font-weight: 600;
        padding: 0 8px;
        line-height: 24px;
        border-radius: 8px;
      }
    }
    .label {
      width: 90px;
      text-align: right;
    }
  }
  .wx-list-wrap {
    .data-form-item {
      margin-bottom: 8px;
      .city-wrap {
        width: 140px;
        margin-right: -20px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .b-wrap {
    .com-input {
      width: 100px;
    }
    .input-group {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
    .ep-name .com-input {
      width: 110px;
    }
  }
  .c-split {
    .input-group {
      margin-right: 20px;
    }
    .com-input {
      width: 100px;
    }
  }
  .o-wrap {
    flex-grow: 1;
    .com-input {
      width: 100px;
    }
    .label {
      padding-left: 20px;
    }
  }
  .hr-expand {
    margin-top: 20px;
    padding-bottom: 20px;
    .hr-group {
      flex-grow: 1;
    }
    .check-item.true {
      color: #ddd;
      .com-check {
        border-color: #eee;
      }
    }
  }
  .check-item {
    display: flex;
    align-items: center;
    line-height: 1;
    cursor: pointer;
    user-select: none;
    color: #757575;
    margin-left: 20px;
    margin-right: 40px;
  }

  // 操作栏
  .action-wrap {
    display: flex;
    padding: 40px 0;
    justify-content: flex-end;
    .block {
      margin-left: 20px;
    }
  }
}