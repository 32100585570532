@import "../variables";

// 表单公共样式
.com-input {
  height: 30px;
  box-shadow: 0 0 0 1px #d6d6e6;
  border-radius: 6px;
  padding-left: 14px;
  display: flex;
  flex-grow: 1;
  &:hover {
    box-shadow: 0 0 0 1px $theme;
  }
  .input {
    background-color: transparent;
    border: none;
    font-size: 14px;
    flex-grow: 1;
    @include text-eclipse;
    &:focus {
      outline: 0;
    }
    &.only-text {
      line-height: 30px;
    }
    &.gray-text {
      line-height: 30px;
      color: #d2d4db;
    }
    &[disabled] {
      cursor: not-allowed;
    }
  }
  &.with-arrow {
    position: relative;
    cursor: pointer;
    .input {
      padding-right: 30px;
    }
    &:after {
      content: "";
      @include size(9);
      position: absolute;
      top: 50%;
      right: 10px;
      border-top: 1px solid #3d4a62;
      border-left: 1px solid #3d4a62;
      transform: translate(-50%, -75%) rotate(225deg);
      pointer-events: none;
    }
  }
  &.true {
    box-shadow: 0 0 0 2px $theme;
  }
  &.disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:hover {
      box-shadow: 0 0 0 1px #d6d6e6;
    }
  }
}

// 文本框
.com-text {
  box-shadow: 0 0 0 1px #d6d6e6;
  border-radius: 6px;
  padding-left: 14px;
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  padding-top: 8px;
  padding-bottom: 8px;
  &:hover {
    box-shadow: 0 0 0 1px $theme;
  }
  .text {
    padding: 0;
    flex-grow: 1;
    border: none;
    resize: none;
    overflow: auto;
    &:focus {
      outline: 0;
    }
    font-family: system-ui, -apple-system, Roboto, "Helvetica Neue", "Noto Sans",
    Arial, sans-serif;
  }
  &.true {
    box-shadow: 0 0 0 2px $theme;
  }
}

.input-group {
  display: flex;
  align-items: center;
  position: relative;
  .label {
    color: #757575;
    &.required {
      position: relative;
      &:after {
        content: '*';
        position: absolute;
        left: -8px;
        font-weight: 800;
        color: #FA931C;
      }
    }
  }
  &.with-text {
    align-items: flex-start;
    .label {
      line-height: 32px;
    }
  }
  .unit {
    position: absolute;
    right: 0;
    @include size(30);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    font-weight: 500;
    border-radius: 0 6px 6px 0;
  }
}

// 薪酬选择器
.com-salary {
  display: flex;
  align-items: center;
  .type-wrap {
    margin-right: 10px;
    .ant-select {
      width: 80px;
    }
  }
  .gap {
    padding: 0 4px;
  }
}

// 城市选择器
.com-choose-city {
  flex-grow: 1;
  cursor: pointer;
}

// 下拉框样式
.ant-select {
  width: 120px;
  .ant-select-selector:hover {
    border-color: $theme !important;
  }
  .ant-select-arrow {
    .anticon-down {
      @include size(9);
      position: relative;
      &:after {
        content: "";
        @include size(9);
        position: absolute;
        top: 50%;
        right: 0;
        border-top: 1px solid #3d4a62;
        border-left: 1px solid #3d4a62;
        transform: translate(-50%, -75%) rotate(225deg);
      }
      svg {
        display: none;
      }
    }
  }
  &.ant-select-focused {
    .ant-select-selector {
      transition: none !important;
      border-color: $theme !important;
      box-shadow: 0 0 0 1px $theme !important;
    }
  }
}
.ant-select-item-option-selected {
  background-color: $theme-light!important;
}

// 日期选择器
.ant-picker-outlined {
  &:hover {
    border-color: $theme;
    box-shadow: none;
  }
  &.ant-picker-focused {
    box-shadow: 0 0 0 1px $theme;
  }
  .ant-picker-active-bar {
    background-color: $theme;
  }
}
.ant-picker-now-btn, .ant-picker-now-btn:hover {
  color: $theme;
}
.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: $theme !important;
}
.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-color: $theme !important;
}

// 分页器样式
.ant-pagination {
  .ant-pagination-item-active, .ant-pagination-item-active:hover {
    border-color: $theme;
    a {
      color: $theme;
    }
  }
}
// radio
.ant-radio-button-wrapper:hover {
  color: $theme;
}
.ant-radio-button-wrapper-checked {
  background: $theme !important;
  border-color: $theme !important;
}

/* normalize */
::placeholder {
  color: #d2d4db;
}
input {
  padding: 0;
  /* 火狐 */
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

//性别
.com-sex {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  .item {
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    border-radius: 8px;
    border: 1px solid #d6d6e6;
    margin-right: 10px;
    cursor: pointer;
    font-size: 14px;
    color: #404a60;
    &.w {
      width: 50px;
    }
    &.true {
      border: 2px solid #7d79ec;
      background: rgba(125, 121, 236, 0.12);
      font-weight: 600;
      color: #7d79ec;
    }
  }
}

//选择弹窗
.com-choose-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.34);
  display: flex;
  align-items: center;
  justify-content: center;
  .panel-wrap {
    width: 700px;
    background: #ffffff;
    border-radius: 10px;
    .title-wrap {
      height: 65px;
      border-bottom: 1px solid #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        padding-left: 26px;
        font-weight: 600;
        font-size: 16px;
        color: #333333;
      }
      .close {
        width: 65px;
        height: 65px;
        position: relative;
        cursor: pointer;
        &::before {
          content: "";
          width: 20px;
          height: 1px;
          background-color: #333333;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        &::after {
          content: "";
          width: 20px;
          height: 1px;
          background-color: #333333;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
    .content-wrap {
      max-height: 300px;
      background: #f8f8f8;
      .list-wrap {
        padding: 25px 15px 25px 25px;
        display: flex;
        flex-wrap: wrap;
        .item {
          user-select: none;
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 0 16px;
          height: 34px;
          line-height: 34px;
          background: #ebebeb;
          border-radius: 5px;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          cursor: pointer;
          &.true {
            background: #7f83f5;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
    .check-wrap {
      display: flex;
      padding: 16px 25px 0;
      .label {
        flex-shrink: 0;
        font-size: 14px;
        color: #999999;
        margin-top: 6px;
      }
      .check-box {
        padding-right: 10px;
        display: flex;
        flex-wrap: wrap;
        .item {
          padding: 0 10px;
          height: 34px;
          line-height: 34px;
          background: #f2f2f2;
          border-radius: 5px;
          font-size: 14px;
          color: #333333;
          margin-right: 5px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          .del-icon {
            margin-left: 7px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: #d8d8d8;
            position: relative;
            cursor: pointer;
            &::before {
              content: "";
              width: 8px;
              height: 1px;
              background: #a6a6a6;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
            }
            &::after {
              content: "";
              width: 8px;
              height: 1px;
              background: #a6a6a6;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(-45deg);
            }
            &:hover {
              opacity: 0.75;
            }
          }
        }
      }
    }
    .button-wrap {
      flex-shrink: 0;
      padding: 20px 56px 30px 26px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .confirm {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 34px;
        background: #7f83f5;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
        &:hover {
          background-color: $dark;
        }
      }
    }
  }
}
