.com-toast {
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.com-toast .toast-msg {
  opacity: 0;
  background-color: #fff;
  border-radius: 8px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 42px;
  animation: 3s zoomInOut;
  display: flex;
  transform: scale3d(.3, .3, .3);
  box-shadow: 0 2px 6px #adadad80;
}

.com-toast .toast-msg .toast-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_web/enterprise/icon-new.png");
  background-size: 80px 50px;
  margin-right: 7px;
}

.com-toast .toast-msg .toast-icon.success {
  background-position: 0 0;
  width: 16px;
  height: 16px;
}

.com-toast .toast-msg .toast-icon.warn {
  background-position: -20px 0;
  width: 16px;
  height: 16px;
}

.com-toast .toast-msg .toast-icon.error {
  background-position: -40px 0;
  width: 16px;
  height: 16px;
}

@keyframes zoomInOut {
  0%, 100% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  13%, 87% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.com-login {
  z-index: 999;
  background: linear-gradient(219deg, #c6c4f7 10%, #daf0ff 100%);
  justify-content: center;
  align-items: center;
  min-width: 1200px;
  display: flex;
  position: fixed;
  inset: 0;
}

.com-login .layout-section {
  width: 80%;
  max-width: 1400px;
  height: 540px;
  display: flex;
  position: relative;
}

.com-login .layout-section .logo {
  z-index: 2;
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/login/logo1.png");
  background-size: cover;
  width: 172px;
  height: 48px;
  position: absolute;
  top: -140px;
  left: -20px;
}

.com-login .layout-section .bg {
  z-index: 1;
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/login/line.png");
  background-size: cover;
  width: 724px;
  height: 232px;
  position: absolute;
  bottom: 20px;
  left: -120px;
}

.com-login .layout-section .caption {
  z-index: 2;
  flex-grow: 1;
  margin-left: -20px;
  padding-top: 60px;
  font-size: 60px;
  position: relative;
}

.com-login .layout-section .caption .f-600 {
  margin-top: 10px;
}

.com-login .login-panel {
  z-index: 2;
  background-color: #fff;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  width: 480px;
  height: 540px;
  display: flex;
  position: relative;
  box-shadow: 0 15px 16px #b5b7e9;
}

.com-login .login-panel .title-1 {
  margin-top: 52px;
  margin-bottom: 8px;
  font-size: 32px;
}

.com-login .login-panel .title-2 {
  color: #5a607f;
  margin-bottom: 60px;
}

.com-login .login-panel .input-wrap {
  background-color: #f6f7fd;
  border-radius: 10px;
  align-items: center;
  width: 360px;
  height: 46px;
  display: flex;
  box-shadow: 0 0 0 1px #d8d8f7;
}

.com-login .login-panel .input-wrap .input-icon {
  background-size: cover;
  flex: none;
  width: 18px;
  height: 18px;
  margin-left: 20px;
  margin-right: 20px;
}

.com-login .login-panel .input-wrap .input-icon.user {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/login/u1.png");
}

.com-login .login-panel .input-wrap .input-icon.lock {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/login/l1.png");
}

.com-login .login-panel .input-wrap input {
  background-color: #0000;
  border: none;
  flex-grow: 1;
  width: 200px;
  font-size: 16px;
}

.com-login .login-panel .input-wrap input:focus {
  outline: 0;
}

.com-login .login-panel .input-wrap.true {
  background-color: #fff;
  box-shadow: 0 0 0 2px #7d79ec;
}

.com-login .login-panel .input-wrap.true .input-icon.user {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/login/u.png");
}

.com-login .login-panel .input-wrap.true .input-icon.lock {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/login/l.png");
}

.com-login .login-panel .check-wrap {
  width: 360px;
  padding-top: 10px;
  padding-bottom: 18px;
  display: flex;
}

.com-login .login-panel .check-wrap .check-check {
  cursor: pointer;
  color: #999;
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
  line-height: 1;
}

.com-login .login-panel .check-wrap .check-check:hover {
  color: #666;
}

.com-login .login-panel .check-wrap .check {
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  position: relative;
}

.com-login .login-panel .check-wrap .check.true {
  background-color: #7d79ec;
  border-color: #7d79ec;
}

.com-login .login-panel .check-wrap .check.true:after {
  content: "";
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  width: 4px;
  height: 6px;
  position: absolute;
  top: 1px;
  left: 3px;
  transform: rotate(45deg);
}

.com-login .login-panel .code-text {
  padding-right: 16px;
  line-height: 14px;
}

.com-login .login-panel .code-btn {
  color: #7d79ec;
  cursor: pointer;
  background-color: #e9e9fb;
  border-radius: 8px;
  flex: none;
  margin-right: 6px;
  padding: 0 10px;
  font-weight: 600;
  line-height: 36px;
}

.com-login .login-panel .code-btn:hover {
  background-color: #e0e0f9;
}

.com-login .login-panel .login-btn {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #7d79ec;
  border-radius: 8px;
  width: 360px;
  margin-top: 75px;
  font-size: 20px;
  line-height: 54px;
}

.com-login .login-panel .login-btn:hover {
  background-color: #5a55e7;
}

.com-layout {
  background-color: #f7f8fc;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.com-layout .com-header {
  z-index: 1;
  background-color: #fff;
  flex: none;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  display: flex;
  position: relative;
  box-shadow: 0 25px 50px #0000000a;
}

.com-layout .com-header .logo {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/logo.png");
  background-size: cover;
  width: 118px;
  height: 20px;
  margin-left: 30px;
}

.com-layout .com-header .nav-wrap {
  display: flex;
}

.com-layout .com-header .nav-wrap .nav-item {
  cursor: pointer;
  align-items: center;
  padding: 0 8px;
  display: flex;
}

.com-layout .com-header .nav-wrap .nav-item:hover {
  color: #7d79ec;
}

.com-layout .com-header .nav-wrap .nav-item:hover .user-name:after {
  border-top-color: #7d79ec;
}

.com-layout .com-header .nav-wrap .nav-item .avatar {
  background-color: #ddd;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.com-layout .com-header .nav-wrap .nav-item .user-name {
  padding-right: 25px;
  position: relative;
}

.com-layout .com-header .nav-wrap .nav-item .user-name:after {
  content: "";
  border-top: 5px solid #333;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  top: 8px;
  right: 9px;
}

.com-layout .com-wrap {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.com-layout .com-menu {
  background-color: #fff;
  flex: none;
  width: 220px;
  padding: 20px 10px;
  transition: width .5s;
  position: relative;
  box-shadow: 0 25px 50px #0000000a;
}

.com-layout .com-menu .toggle {
  cursor: pointer;
  background-color: #fff;
  border-radius: 0 20px 20px 0;
  width: 16px;
  height: 70px;
  position: absolute;
  top: 40%;
  right: -16px;
}

.com-layout .com-menu .toggle:before {
  content: "";
  border-style: solid;
  border-width: 2px 0 0 2px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-25%, -50%)rotate(-45deg);
}

.com-layout .com-menu .toggle:hover:before {
  border-color: #7d79ec;
}

.com-layout .com-menu .toggle.false:before {
  transform: translate(-75%, -50%)rotate(135deg);
}

.com-layout .com-menu .menu-item {
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}

.com-layout .com-menu .menu {
  color: #8f95b2;
  cursor: pointer;
  border-radius: 12px;
  align-items: center;
  height: 46px;
  margin-bottom: 10px;
  padding-left: 20px;
  font-weight: 500;
  transition: padding-left .5s;
  display: flex;
  overflow: hidden;
}

.com-layout .com-menu .menu .icon {
  background-size: cover;
  flex: none;
  width: 22px;
  height: 20px;
  margin-right: 14px;
}

.com-layout .com-menu .menu .icon.index {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/menu/c.png");
}

.com-layout .com-menu .menu .icon.team, .com-layout .com-menu .menu .icon.transform, .com-layout .com-menu .menu .icon.progress {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/menu/d.png");
}

.com-layout .com-menu .menu .icon.account {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/menu/a.png");
}

.com-layout .com-menu .menu .icon.dict {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/menu/b.png");
}

.com-layout .com-menu .menu .icon.operate-data, .com-layout .com-menu .menu .icon.schedule {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/menu/d.png");
}

.com-layout .com-menu .menu .name {
  flex: none;
  width: 98px;
}

.com-layout .com-menu .menu .arrow {
  flex: none;
  width: 46px;
  height: 46px;
  position: relative;
}

.com-layout .com-menu .menu .arrow:after {
  content: "";
  border-top: 1px solid #8f95b2;
  border-left: 1px solid #8f95b2;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%)rotate(225deg);
}

.com-layout .com-menu .menu:hover {
  background-color: #e8e8fc;
}

.com-layout .com-menu .menu.true {
  color: #fff;
  background-color: #7d79ec;
  box-shadow: 0 2px 4px #d7d6f1;
}

.com-layout .com-menu .menu.true .icon.index {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/menu/c1.png");
}

.com-layout .com-menu .menu.true .icon.team, .com-layout .com-menu .menu.true .icon.transform, .com-layout .com-menu .menu.true .icon.progress {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/menu/d1.png");
}

.com-layout .com-menu .menu.true .icon.account {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/menu/a1.png");
}

.com-layout .com-menu .menu.true .icon.dict {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/menu/b1.png");
}

.com-layout .com-menu .menu.true .icon.operate-data, .com-layout .com-menu .menu.true .icon.schedule {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/menu/d1.png");
}

.com-layout .com-menu .menu.true .arrow:after {
  border-color: #fff;
}

.com-layout .com-menu .sub-menu-item {
  cursor: pointer;
  color: #8f95b2;
  white-space: nowrap;
  line-height: 46px;
}

.com-layout .com-menu .sub-menu-item:hover {
  color: #a09df1;
}

.com-layout .com-menu .sub-menu-item.true {
  color: #7d79ec;
  font-weight: 600;
}

.com-layout .com-menu .sub-menu {
  height: 0;
  overflow: hidden;
}

.com-layout .com-menu .sub-menu .sub-menu-item {
  padding-left: 56px;
}

.com-layout .com-menu .hover-menu-wrap {
  z-index: 1;
  padding-left: 5px;
  display: none;
  position: absolute;
  top: 0;
  right: -105px;
}

.com-layout .com-menu .hover-menu-wrap .hover-menu {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 30px #0000001a;
}

.com-layout .com-menu .hover-menu-wrap .hover-menu .sub-menu-item {
  text-align: center;
  width: 100px;
}

.com-layout .com-menu .menu-item.true .menu .arrow:after {
  transform: translate(-50%, -25%)rotate(45deg);
}

.com-layout .com-menu .menu-item.true .sub-menu {
  height: unset;
}

.com-layout .com-menu.false {
  width: 66px;
}

.com-layout .com-menu.false .menu {
  padding-left: 13px;
}

.com-layout .com-menu.false .with-arrow:after {
  display: none;
}

.com-layout .com-menu.false .menu-item:hover .hover-menu-wrap {
  display: unset;
}

.com-layout .com-content {
  flex-grow: 1;
  padding: 0 24px 24px;
  overflow: auto;
}

.com-layout .com-content .com-fix-wrap {
  width: 976px;
  margin: auto;
}

.breadcrumb-wrap {
  color: #4b5e76;
  padding: 26px 0;
  font-size: 12px;
  font-weight: 600;
  display: flex;
}

.breadcrumb-wrap .gap {
  padding: 0 4px;
}

.breadcrumb-wrap .light {
  color: #7d79ec;
}

.no-match {
  color: #8f95b2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  display: flex;
}

.no-match .no-match-text {
  margin-bottom: 16px;
}

.no-match .back-btn {
  color: #fff;
  text-align: center;
  background-color: #7d79ec;
  border-radius: 8px;
  width: 200px;
  line-height: 40px;
  text-decoration: none;
}

.no-match .back-btn:hover {
  background-color: #5a55e7;
}

.dashboard-index {
  width: 1000px;
  margin: 0 auto;
}

.dashboard-index .title-wrap {
  flex-shrink: 0;
  align-items: center;
  min-width: 1000px;
  padding-top: 20px;
  padding-bottom: 16px;
  display: flex;
}

.dashboard-index .title-wrap .title {
  color: #334463;
  margin-right: auto;
  font-size: 14px;
  font-weight: 600;
}

.dashboard-index .title-wrap .title .ant-picker {
  width: 160px;
  margin-left: 6px;
}

.dashboard-index .title-wrap .city-title {
  color: #8f95b2;
  font-size: 12px;
  font-weight: 500;
}

.dashboard-index .title-wrap .city-wrap {
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  align-items: center;
  height: 30px;
  margin-left: 15px;
  padding: 0 3px;
  display: flex;
  box-shadow: 0 25px 50px #0000000a;
}

.dashboard-index .title-wrap .city-wrap .city {
  color: #666;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 16px;
  font-size: 12px;
  line-height: 24px;
}

.dashboard-index .title-wrap .city-wrap .city.true {
  color: #fff;
  background: #7d79ec;
  font-weight: 600;
}

.dashboard-index .title-wrap .select-city {
  justify-content: flex-end;
  align-items: center;
  margin-left: 10px;
  display: flex;
}

.dashboard-index .content-wrap {
  padding-bottom: 70px;
}

.dashboard-index .content-wrap .count-wrap {
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.dashboard-index .content-wrap .count-wrap .item-wrap {
  background: #fff;
  border-radius: 8px;
  flex: 1;
  min-width: 235px;
  height: 325px;
  margin-right: 12px;
  position: relative;
  box-shadow: 0 -14px 20px #ededf2;
}

.dashboard-index .content-wrap .count-wrap .item-wrap:last-child {
  margin-right: 0;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .target {
  text-align: center;
  color: #131415;
  margin-top: 16px;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 14px;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .target .num {
  color: #2d4566;
  font-size: 14px;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.blue {
  background: #7d79ec;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.blue .item-type {
  color: #7d79ec;
  background: #fff;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-type {
  color: #fff;
  background: #7d79ec;
  border-radius: 5px 0 0 5px;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  position: absolute;
  top: 13px;
  right: 0;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info {
  width: 235px;
  margin: auto;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.day {
  color: #fff;
  padding-left: 18px;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.day .text1 {
  margin-top: 25px;
  font-size: 12px;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.day .text1 .num {
  margin-right: 4px;
  font-size: 70px;
  line-height: 70px;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.day .text2 {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.day .chart {
  background: #fff;
  border-radius: 6px;
  width: 200px;
  height: 6px;
  margin-top: 35px;
  margin-bottom: 24px;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.day .chart .end {
  background: #ffc000;
  border-radius: 6px;
  width: 0%;
  max-width: 100%;
  height: 6px;
  transition: all 1s;
  position: relative;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.day .chart .end .pre {
  font-size: 12px;
  position: absolute;
  top: -20px;
  right: -10px;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.day .desc {
  margin-bottom: 7px;
  font-size: 12px;
  line-height: 14px;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.day .desc .yellow {
  color: #ffc000;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.day .msg {
  margin-top: 40px;
  font-size: 12px;
  font-weight: 600;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.day .msg .num {
  font-size: 20px;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.week .week-chart {
  color: #9da3aa;
  align-items: center;
  margin-bottom: 17px;
  padding-left: 18px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.week .week-chart.end {
  color: #2e4d6f;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.week .week-chart.true {
  color: #254669;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.week .week-chart.true .chart .end {
  color: #fff;
  background: #7b77eb;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.week .week-chart .name {
  width: 60px;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.week .week-chart .chart {
  border: 1px solid #c8c7f7;
  border-radius: 5px;
  width: 110px;
  height: 14px;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.week .week-chart .chart .end {
  color: #7b77eb;
  background: #dddcfa;
  border-radius: 4px;
  justify-content: flex-end;
  align-items: center;
  max-width: 100%;
  height: 12px;
  padding-right: 2px;
  font-size: 12px;
  display: flex;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.week .week-chart .pre {
  color: #8f95b2;
  margin-left: 3px;
  font-size: 12px;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.month .desc {
  color: #8e95b5;
  align-items: center;
  margin-bottom: 7px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.month .desc .label {
  text-align: right;
  width: 55%;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.month .desc .gray {
  color: #8e95b5;
  font-weight: 600;
}

.dashboard-index .content-wrap .count-wrap .item-wrap .item-info.month .desc .red {
  color: #e2625e;
  font-weight: 600;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star {
  background: #efefef;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star .day-num {
  color: #131415;
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/red1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-size: 12px;
  display: flex;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star .day-num .num {
  color: #fefefe;
  background: #d26a63;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 29px;
  margin-right: 6px;
  font-size: 24px;
  font-weight: 600;
  display: flex;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star .table {
  background: #fff;
  border-radius: 8px;
  height: 265px;
  box-shadow: 0 14px 20px #0000001a;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star .table .col-1 {
  text-align: center;
  width: 32%;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star .table .col-2 {
  text-align: center;
  width: 25%;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star .table .col-3 {
  text-align: center;
  width: 20%;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star .table .col-4 {
  text-align: center;
  width: 23%;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star .table .table-head {
  color: #254669;
  align-items: center;
  height: 50px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star .table .table-body .row {
  color: #9d9aef;
  background: #fbfbfb;
  align-items: center;
  height: 30px;
  margin-bottom: 1px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star .table .table-body .row.true {
  margin-top: 10px;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star .table .table-body .row.hover:hover {
  color: #7b77eb;
  background: #efeef9;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star .table .table-body .row.star-1 {
  color: #e2625e;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star .table .table-body .row.star-1 .col-1 {
  color: #d26a63;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star .table .table-body .row.star-2 {
  color: #a67977;
}

.dashboard-index .content-wrap .count-wrap .item-wrap.star .table .table-body .row.star-2 .col-1 {
  color: #a07b79;
}

.dashboard-index .content-wrap .chart-wrap {
  background: #fff;
  border-radius: 8px;
  height: 446px;
  margin-top: 10px;
  padding: 14px 20px 12px 16px;
  box-shadow: 0 25px 50px #0000000a;
}

.dashboard-index .content-wrap .chart-wrap .info-wrap {
  align-items: center;
  display: flex;
}

.dashboard-index .content-wrap .chart-wrap .info-wrap .chart-title {
  color: #333;
  font-size: 16px;
  font-weight: 600;
}

.dashboard-index .content-wrap .chart-wrap .info-wrap .chart-desc {
  color: #8f95b2;
  margin-left: 10px;
  margin-right: auto;
  font-size: 12px;
  font-weight: 400;
}

.dashboard-index .content-wrap .chart-wrap .info-wrap .date-wrap {
  background: #fdfdfd;
  border: 1px solid #eee;
  border-radius: 4px;
  align-items: center;
  margin-right: 3px;
  padding: 3px 4px;
  display: flex;
}

.dashboard-index .content-wrap .chart-wrap .info-wrap .date-wrap.true {
  margin-left: auto;
}

.dashboard-index .content-wrap .chart-wrap .info-wrap .date-wrap .date-type {
  color: #666;
  cursor: pointer;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 12px;
  display: flex;
}

.dashboard-index .content-wrap .chart-wrap .info-wrap .date-wrap .date-type.true {
  color: #323232;
  background: #ebeaf9;
  font-weight: 600;
}

.dashboard-index .content-wrap .chart-wrap .info-wrap .now-wrap {
  background: #fdfdfd;
  border: 1px solid #eee;
  border-radius: 4px;
  margin: 0 8px;
  padding: 3px 4px;
}

.dashboard-index .content-wrap .chart-wrap .info-wrap .now-wrap .now {
  color: #666;
  cursor: pointer;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 24px;
  font-size: 12px;
  display: flex;
}

.dashboard-index .content-wrap .chart-wrap .info-wrap .now-wrap .now.true {
  color: #323232;
  background: #ebeaf9;
  font-weight: 600;
}

.dashboard-index .content-wrap .chart-wrap .info-wrap .pr .ant-select-selector {
  padding-right: 40px;
}

.dashboard-index .content-wrap .chart-wrap .info-wrap .ant-select-selection-overflow {
  flex-wrap: nowrap;
  overflow: hidden;
}

.dashboard-index .content-wrap .chart-wrap .info-wrap .chart-type {
  background: #fdfdfd;
  border: 1px solid #eee;
  border-radius: 4px;
  align-items: center;
  margin-left: 8px;
  padding: 3px;
  display: flex;
}

.dashboard-index .content-wrap .chart-wrap .info-wrap .chart-type .item {
  color: #666;
  cursor: pointer;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 24px;
  font-size: 12px;
  display: flex;
}

.dashboard-index .content-wrap .chart-wrap .info-wrap .chart-type .item.true {
  color: #323232;
  background: #ebeaf9;
  font-weight: 600;
}

.dashboard-index .content-wrap .chart-wrap .target-wrap {
  align-items: center;
  margin-left: 8px;
  display: flex;
}

.dashboard-index .contrast-wrap {
  margin-bottom: 10px;
  display: flex;
}

.dashboard-index .contrast-wrap .contrast-item {
  background: #fff;
  border-radius: 8px;
  flex: 1;
  height: 112px;
  margin-right: 12px;
  overflow: hidden;
  box-shadow: 0 14px 20px #0000001a;
}

.dashboard-index .contrast-wrap .contrast-item:last-child {
  margin-right: 0;
}

.dashboard-index .contrast-wrap .contrast-item .red-title {
  color: #d26a63;
  padding-top: 10px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
}

.dashboard-index .contrast-wrap .contrast-item .red-title .gray {
  color: #8e95b5;
}

.dashboard-index .contrast-wrap .contrast-item .red-title .span-title {
  color: #000;
  position: relative;
}

.dashboard-index .contrast-wrap .contrast-item .red-title .span-title:after {
  content: "";
  background: linear-gradient(270deg, #fff 0%, #fb9390 100%);
  width: 100%;
  height: 6px;
  position: absolute;
  bottom: 4px;
}

.dashboard-index .contrast-wrap .contrast-item .red-title .span-title .value {
  z-index: 2;
  position: relative;
}

.dashboard-index .contrast-wrap .contrast-item .number-wrap {
  flex-shrink: 0;
}

.dashboard-index .contrast-wrap .contrast-item .number-wrap .number {
  color: #2d4566;
  font-size: 38px;
  line-height: 1.4;
}

.dashboard-index .contrast-wrap .contrast-item .number-wrap .unit {
  color: #adb2b8;
  padding-left: 2px;
}

.dashboard-index .contrast-wrap .contrast-item .num-box {
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 16px;
  display: flex;
}

.dashboard-index .contrast-wrap .contrast-item .num-box .tip-text {
  flex-wrap: nowrap;
  align-items: center;
  margin-right: 10px;
  display: flex;
}

.dashboard-index .contrast-wrap .contrast-item .num-box .tip-text .arrow {
  border: 8px solid #0000;
  border-right: 18px solid #e8e7ff;
  flex-shrink: 0;
  width: 0;
  height: 0;
}

.dashboard-index .contrast-wrap .contrast-item .num-box .tip-text .text {
  color: #254669;
  background: #e8e7ff;
  border-radius: 7px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 400;
}

.dashboard-index .contrast-wrap .contrast-item .user-box {
  color: #254669;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  display: flex;
}

.dashboard-index .contrast-wrap .contrast-item .user-box .head-img {
  background-size: cover;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin-right: 7px;
}

.dashboard-index .contrast-wrap .contrast-item .first-user {
  background: #fff;
  border: 1px solid #7d79ec;
  border-radius: 8px;
  align-items: center;
  height: 57px;
  margin-top: 27px;
  display: flex;
  box-shadow: 0 -10px 12px #aea9a91a;
}

.dashboard-index .contrast-wrap .contrast-item .first-user .head-img {
  background-size: cover;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-left: 16px;
  margin-right: 7px;
  position: relative;
}

.dashboard-index .contrast-wrap .contrast-item .first-user .head-img .head-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/crown.png");
  background-size: cover;
  width: 16px;
  height: 16px;
  position: absolute;
  top: -8px;
  right: -2px;
}

.dashboard-index .contrast-wrap .contrast-item .first-user .first-name {
  color: #254669;
  font-size: 12px;
  font-weight: 600;
}

.dashboard-index .contrast-wrap .contrast-item .first-user .first-num {
  color: #d26a63;
  margin-left: auto;
  margin-right: 10px;
  font-size: 28px;
  font-weight: 600;
}

.dashboard-index .contrast-wrap .compare-panel {
  justify-content: space-between;
  min-width: 235px;
  height: 100%;
  padding-left: 16px;
}

.dashboard-index .contrast-wrap .compare-panel .title {
  color: #7d79ec;
  font-size: 12px;
}

.dashboard-index .contrast-wrap .compare-panel .title .gray {
  color: #9ba3ab;
}

.dashboard-index .contrast-wrap .compare-panel .green {
  color: #00df6c;
}

.dashboard-index .contrast-wrap .compare-panel .red {
  color: #e2625e;
}

.dashboard-index .contrast-wrap .compare-panel .trend {
  color: #254669;
}

.dashboard-index .city-panel {
  background: #fff;
  border-radius: 8px;
  margin-top: 12px;
  padding: 0 12px 8px;
  position: relative;
  box-shadow: 0 14px 20px #0000001a;
}

.dashboard-index .city-panel .title {
  z-index: 2;
  color: #2d4566;
  background-color: #fff;
  border-radius: 8px 0 0;
  padding: 8px 8px 0 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.dashboard-index .city-panel .title .icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/rank.png");
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-top: -1px;
  margin-right: 6px;
}

.dashboard-index .city-panel .city-wrap {
  overflow-x: auto;
}

.dashboard-index .city-panel .item-wrap {
  background: linear-gradient(to right, #7d79ec 0%, #c9c7f5 100%);
  flex-wrap: nowrap;
  margin-top: 60px;
  display: inline-flex;
  position: relative;
}

.dashboard-index .city-panel .item-wrap .item {
  color: #fff;
  border-right: 1px solid #fff;
  justify-content: center;
  height: 40px;
  padding-top: 6px;
  font-weight: 600;
  display: flex;
  position: relative;
}

.dashboard-index .city-panel .item-wrap .item .num {
  color: #fff6;
  justify-content: flex-end;
  padding-right: 6px;
  line-height: 1;
  display: flex;
}

.dashboard-index .city-panel .item-wrap .item.true {
  background-color: #d26a63;
}

.dashboard-index .city-panel .item-wrap .rank-badge {
  white-space: nowrap;
  color: #254669;
  background-color: #e4e3fb;
  border-radius: 7px;
  margin-left: 17px;
  padding: 0 6px;
  line-height: 22px;
  position: absolute;
  top: -54px;
  left: 100%;
}

.dashboard-index .city-panel .item-wrap .rank-badge:before {
  content: "";
  border-top: 1px solid #9e9bee;
  width: 44px;
  position: absolute;
  bottom: -10px;
  left: -32px;
  transform: rotate(-72deg);
}

.dashboard-index .city-panel .item-wrap .rank-badge:after {
  content: "";
  border-left: 1px solid #9e9bee;
  height: 8px;
  position: absolute;
  bottom: 2px;
  left: -4px;
}

.dashboard-index .city-panel .item-wrap .rank-badge.true {
  left: unset;
  margin-left: 0;
  right: 17px;
}

.dashboard-index .city-panel .item-wrap .rank-badge.true:before {
  left: unset;
  right: -32px;
  transform: rotate(73deg);
}

.dashboard-index .city-panel .item-wrap .rank-badge.true:after {
  left: unset;
  right: -4px;
}

.dashboard-index .city-panel .item-wrap .gif-icon {
  background-repeat: no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;
  animation-name: run;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  position: absolute;
  top: -38px;
  right: 0;
}

@keyframes run {
  from {
    right: 0;
  }
}

.dashboard-index .half-pie {
  width: 235px;
  height: 80px;
  margin-bottom: 32px;
  position: relative;
}

.dashboard-index .half-pie .half-pie-chart {
  width: 100%;
  height: 100%;
}

.dashboard-index .half-pie .num {
  color: #2d4566;
  align-items: center;
  font-size: 24px;
  display: flex;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.dashboard-index .half-pie .num .u {
  color: #adb2b8;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
}

.dashboard-index .water-pie {
  width: 235px;
  height: 174px;
  margin-bottom: 46px;
  position: relative;
}

.dashboard-index .water-pie .water-chart {
  width: 100%;
  height: 100%;
}

.dashboard-index .water-pie .num {
  color: #2d4566;
  align-items: center;
  font-size: 54px;
  font-weight: 600;
  display: flex;
  position: absolute;
  top: 38px;
  left: 50%;
  transform: translateX(-50%);
}

.dashboard-index .water-pie .num .u {
  color: #adb2b8;
  margin-top: 28px;
  margin-left: -2px;
  font-size: 12px;
  font-weight: 600;
}

.dashboard-index .line-chart {
  width: 100%;
  height: 400px;
}

.dashboard-index .blue {
  color: #7d79ec;
}

.replace-model {
  z-index: 99;
  background: #00000057;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.replace-model .account-wrap {
  background-color: #fff;
  border-radius: 10px;
  width: 700px;
}

.replace-model .title-wrap {
  border-bottom: 1px solid #f7f7f7;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  display: flex;
}

.replace-model .title-wrap .title {
  color: #333;
  padding-left: 26px;
  font-size: 16px;
  font-weight: 600;
}

.replace-model .title-wrap .close {
  cursor: pointer;
  width: 65px;
  height: 65px;
  position: relative;
}

.replace-model .title-wrap .close:before {
  content: "";
  background-color: #333;
  width: 20px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.replace-model .title-wrap .close:after {
  content: "";
  background-color: #333;
  width: 20px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.replace-model .content-wrap {
  min-height: 200px;
  max-height: 400px;
  margin: 26px 0;
  padding: 0 26px;
  overflow: auto;
}

.replace-model .content-wrap .row-line {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.replace-model .content-wrap .it-group {
  width: 25%;
}

.replace-model .content-wrap .it-group.time {
  width: 35%;
}

.replace-model .content-wrap .it-group .label {
  color: #666;
  font-weight: 600;
}

.replace-model .content-wrap .it-group .value {
  font-size: 12px;
}

.team-page {
  min-width: 1000px;
  max-width: 1600px;
  margin: 0 auto;
}

.team-page .blue {
  color: #7d79ec;
}

.team-page .indigo {
  color: #324463;
}

.team-page .green {
  color: #20b720;
}

.team-page .light {
  color: #8f95b2;
}

.team-page .red {
  color: #e2625e;
}

.team-page .icon-down {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/down.png");
  background-size: cover;
  width: 8px;
  height: 10px;
  margin-left: 4px;
  display: inline-flex;
}

.team-page .icon-up {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/up.png");
  background-size: cover;
  width: 8px;
  height: 10px;
  margin-left: 4px;
  display: inline-flex;
}

.team-page .ord {
  background-size: cover;
  width: 18px;
  height: 24px;
}

.team-page .ord.ord-0 {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/1.png");
}

.team-page .ord.ord-1 {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/2.png");
}

.team-page .ord.ord-2 {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/3.png");
}

.team-page .head-title {
  padding-top: 20px;
  padding-bottom: 16px;
}

.team-page .panel-wrap {
  height: 330px;
  position: relative;
}

.team-page .panel-wrap .panel {
  z-index: 2;
  background-color: #fff;
  border-radius: 8px;
  flex: 1;
  margin-right: 7px;
  position: relative;
}

.team-page .panel-wrap .panel .caption {
  text-align: center;
  margin-top: 10px;
  position: relative;
}

.team-page .panel-wrap .panel .caption .label {
  color: #fff;
  background-color: #7d79ec;
  border-radius: 0 5px 5px 0;
  width: 34px;
  height: 20px;
  font-weight: 600;
  position: absolute;
  left: 0;
}

.team-page .panel-wrap .panel .caption .heavy {
  padding-left: 3px;
}

.team-page .panel-wrap .panel .num-section {
  text-align: center;
  height: 115px;
}

.team-page .panel-wrap .panel .num-section .big-number-wrap {
  padding-top: 4px;
}

.team-page .panel-wrap .panel .num-section .big-number-wrap .big-number {
  margin-right: 2px;
  font-size: 50px;
}

.team-page .panel-wrap .panel .num-section .progress {
  background-color: #dde2dd;
  border-radius: 6px;
  width: 180px;
  height: 6px;
  margin: 4px auto 0;
  position: relative;
}

.team-page .panel-wrap .panel .num-section .progress .value {
  background-color: #0b0;
  border-radius: 6px;
  height: 6px;
  position: absolute;
}

.team-page .panel-wrap .panel .num-section .progress.dark {
  background-color: #fff;
}

.team-page .panel-wrap .panel .num-section .progress.dark .value {
  background-color: #707070;
}

.team-page .panel-wrap .panel .data-section {
  justify-content: center;
  height: 185px;
  display: flex;
}

.team-page .panel-wrap .panel .data-section .first-group {
  margin-top: 30px;
  line-height: 24px;
}

.team-page .panel-wrap .panel .data-section .first-group .c-0 {
  min-width: 36px;
  display: inline-flex;
}

.team-page .panel-wrap .panel .data-section .week-section {
  min-width: 160px;
  margin-top: 6px;
}

.team-page .panel-wrap .panel .data-section .week-section .week-line {
  margin-top: 10px;
}

.team-page .panel-wrap .panel .data-section .week-section .f-600 {
  min-width: 28px;
  padding-left: 4px;
  display: inline-flex;
}

.team-page .panel-wrap .panel .data-section .week-section .empty {
  justify-content: flex-end;
  margin-left: 38px;
}

.team-page .panel-wrap .panel .data-section .week-section .percent {
  background-color: #dde2dd;
  border-radius: 3px;
  height: 3px;
  margin-top: 2px;
  position: relative;
}

.team-page .panel-wrap .panel .data-section .week-section .percent .value {
  background-color: #0b0;
  border-radius: 3px;
  height: 3px;
  position: absolute;
  left: 0;
}

.team-page .panel-wrap .panel .data-section .count-day {
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  display: flex;
}

.team-page .panel-wrap .panel .data-section .count-day .day-badge {
  color: #fff;
  background-color: #d26a63;
  border-radius: 12px;
  padding: 0 11px;
  line-height: 24px;
}

.team-page .panel-wrap .panel .data-section .blank {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/empty.png");
  background-size: cover;
  width: 69px;
  height: 60px;
  margin: 20px auto 0;
}

.team-page .panel-wrap .panel .data-section .day-tip {
  margin-top: 16px;
}

.team-page .panel-wrap .panel .data-section .dot-wrap {
  padding-top: 10px;
}

.team-page .panel-wrap .panel .data-section .dot-line {
  margin-top: 12px;
  position: relative;
}

.team-page .panel-wrap .panel .data-section .dot-line:after {
  content: "";
  background-color: #707070;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 7px;
  left: -9px;
}

.team-page .panel-wrap .panel .data-section.blue-bg {
  background: linear-gradient(#f5f9fc 0%, #fffdfc 100%);
  border-radius: 8px;
  box-shadow: 0 25px 50px #0000000a;
}

.team-page .panel-wrap .panel.gray-bg {
  z-index: 1;
  background-color: #efefef;
  box-shadow: 0 25px 50px #0000001a;
}

.team-page .panel-wrap .panel.gray-bg .caption .label {
  color: #fff;
  background-color: #2d4566;
  width: 42px;
}

.team-page .panel-wrap .panel:last-child {
  margin-right: 0;
}

.team-page .bottom-half {
  margin-top: 7px;
}

.team-page .bottom-half .chart-panel {
  background-color: #fff;
  border-radius: 8px;
  flex: 1;
  margin-right: 7px;
  box-shadow: 0 15px 25px #0000000a;
}

.team-page .bottom-half .chart-panel .title-wrap {
  margin-top: 10px;
  padding: 0 16px;
  position: relative;
}

.team-page .bottom-half .chart-panel .title-wrap .title {
  position: relative;
}

.team-page .bottom-half .chart-panel .title-wrap .title .text {
  z-index: 1;
  position: relative;
}

.team-page .bottom-half .chart-panel .title-wrap .title:after {
  content: "";
  background: linear-gradient(270deg, #fff 0%, #c8c6ff 100%);
  width: 100px;
  height: 9px;
  position: absolute;
  bottom: 2px;
  left: 0;
}

.team-page .bottom-half .chart-panel .title-wrap .badge {
  background-color: #e6e5ff;
  border-radius: 6px;
  padding: 0 9px;
  line-height: 20px;
}

.team-page .bottom-half .chart-panel .desc-wrap {
  align-items: center;
  margin-top: 13px;
  padding-left: 16px;
  font-size: 12px;
  display: flex;
}

.team-page .bottom-half .chart-panel .desc-wrap .desc-title {
  color: #313131;
}

.team-page .bottom-half .chart-panel .desc-wrap .label {
  color: #686868;
  padding-left: 30px;
  font-weight: 600;
  position: relative;
}

.team-page .bottom-half .chart-panel .desc-wrap .label:before {
  content: "";
  width: 5px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.team-page .bottom-half .chart-panel .desc-wrap .label.green:before {
  background: linear-gradient(#a4d252 0%, #fff 100%);
}

.team-page .bottom-half .chart-panel .desc-wrap .label.red:before {
  background: linear-gradient(#d26a63 0%, #fff 100%);
}

.team-page .bottom-half .chart-panel .desc-wrap .gray {
  color: #8e95b5;
  font-weight: 400;
}

.team-page .bottom-half .rank-panel {
  flex: 1;
}

.team-page .bottom-half .rank-panel .first-line {
  margin-bottom: 7px;
}

.team-page .bottom-half .rank-panel .rank {
  background: linear-gradient(#ffe8e8 0%, #fff 30%);
  border-radius: 8px;
  flex: 1;
  height: 133px;
  margin-right: 7px;
  box-shadow: 0 15px 25px #0000000a;
}

.team-page .bottom-half .rank-panel .rank.false {
  background: linear-gradient(#e5e5e5 0%, #fff 30%);
}

.team-page .bottom-half .rank-panel .rank:last-child {
  margin-right: 0;
}

.team-page .bottom-half .rank-panel .rank .title {
  margin-top: 12px;
  padding-left: 13px;
}

.team-page .bottom-half .rank-panel .rank .item {
  justify-content: space-around;
  margin-top: 11px;
  font-size: 12px;
  display: flex;
}

.team-page .bottom-half .rank-panel .rank .item .index {
  justify-content: center;
  width: 46px;
  display: flex;
}

.team-page .bottom-half .rank-panel .rank .item .index .ord {
  margin-top: -2px;
  position: absolute;
}

.team-page .bottom-half .rank-panel .rank .item .city {
  width: 46px;
}

.team-page .bottom-half .rank-panel .rank .item .name {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 60px;
  overflow: hidden;
}

.team-page .bottom-half .rank-panel .rank .item .number {
  width: 30px;
}

.team-page .bottom-half .rank-panel .rank .item .percent {
  width: 40px;
}

.team-page .half-pie {
  display: inline-block;
  position: relative;
}

.team-page .half-pie .half-pie-chart {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/green.png");
  background-size: cover;
  width: 240px;
  height: 115px;
}

.team-page .half-pie .num {
  color: #324463;
  font-size: 34px;
  font-weight: 600;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.team-page .half-pie .label-wrap {
  color: #b2beab;
  align-items: center;
  font-size: 8px;
  display: flex;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
}

.team-page .half-pie .label-wrap .label {
  padding: 0 6px;
  position: relative;
}

.team-page .half-pie .label-wrap .label:before {
  content: "";
  background: #a4d252;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.team-page .half-pie .label-wrap .label.true:before {
  background: #74be8b;
}

.team-page .line-chart {
  width: 100%;
  height: 200px;
}

.transform-page {
  flex-direction: column;
  min-width: 800px;
  display: flex;
}

.transform-page .head-title {
  color: #334463;
  flex-shrink: 0;
  padding-top: 20px;
  padding-bottom: 16px;
}

.transform-page .head-title .ant-picker {
  width: 110px;
}

.transform-page .head-title .compare-box {
  cursor: pointer;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-right: 12px;
  padding: 2px;
}

.transform-page .head-title .compare-box .compare {
  border-radius: 6px;
  align-items: center;
  padding: 0 4px;
  display: flex;
}

.transform-page .head-title .compare-box .compare:hover {
  background-color: #7d79ec1a;
}

.transform-page .head-title .compare-box .compare.true {
  background-color: #7d79ec4d;
  font-weight: 600;
}

.transform-page .content-wrap {
  flex-grow: 1;
  overflow: auto;
}

.transform-page .table-wrap {
  display: flex;
}

.transform-page .table-wrap .left-head {
  position: sticky;
  left: 0;
}

.transform-page .icon-down {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/down.png");
  background-size: cover;
  width: 8px;
  height: 10px;
  display: inline-flex;
}

.transform-page .icon-up {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/up.png");
  background-size: cover;
  width: 8px;
  height: 10px;
  display: inline-flex;
}

.transform-page .red {
  color: #d24d49;
}

.transform-page .green {
  color: #15a87d;
}

.transform-page .transform-table {
  background-color: #fff;
  display: inline-block;
}

.transform-page .transform-table .center {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.transform-page .transform-table .table-head {
  color: #131415;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  display: flex;
}

.transform-page .transform-table .table-head .user {
  background: #a6a4e4;
  width: 60px;
  height: 60px;
}

.transform-page .transform-table .table-head .week .week-name {
  background: #72c7ae;
  height: 30px;
}

.transform-page .transform-table .table-head .week .week-day {
  background: #c7e8df;
  width: 34px;
  height: 30px;
}

.transform-page .transform-table .table-head .week .week-end {
  background: #9adac7;
  width: 54px;
  height: 30px;
}

.transform-page .transform-table .table-head .total {
  background: #a3a0ed;
  width: 70px;
  height: 60px;
}

.transform-page .transform-table .table-head .target, .transform-page .transform-table .table-head .percent {
  background: #c4c2ff;
  width: 56px;
  height: 60px;
}

.transform-page .transform-table .table-head .average {
  background: #c4c2ff;
  width: 44px;
  height: 60px;
}

.transform-page .transform-table .table-head .month .month-name {
  background: #e4cfa6;
  width: 180px;
  height: 30px;
}

.transform-page .transform-table .table-head .month .month-day {
  background: #f3e5cc;
  width: 55px;
  height: 30px;
}

.transform-page .transform-table .table-head .month .month-trend {
  background: #f4f4f4;
  width: 70px;
  height: 30px;
}

.transform-page .transform-table .table-body {
  color: #131415;
  text-wrap: nowrap;
  font-size: 12px;
  font-weight: 600;
}

.transform-page .transform-table .table-body .area-wrap {
  margin-bottom: 1px;
}

.transform-page .transform-table .table-body .user {
  width: 60px;
}

.transform-page .transform-table .table-body .city {
  width: 60px;
  height: 30px;
}

.transform-page .transform-table .table-body .week-day {
  background: #e2ebe9;
  width: 34px;
  height: 30px;
}

.transform-page .transform-table .table-body .week-end {
  background: #e2ebe9;
  width: 54px;
  height: 30px;
  font-size: 14px;
}

.transform-page .transform-table .table-body .week-end.green {
  background: #c7e8df;
}

.transform-page .transform-table .table-body .week-end.red {
  background: #f1e2e2;
}

.transform-page .transform-table .table-body .total {
  background: #d7d6f5;
  width: 70px;
  height: 30px;
}

.transform-page .transform-table .table-body .target, .transform-page .transform-table .table-body .percent {
  background: #e3e2ff;
  width: 56px;
  height: 30px;
}

.transform-page .transform-table .table-body .average {
  background: #e3e2ff;
  width: 44px;
  height: 30px;
}

.transform-page .transform-table .table-body .month-day {
  background: #eee6d6;
  width: 55px;
  height: 30px;
}

.transform-page .transform-table .table-body .month-trend {
  background: #f4f4f4;
  width: 70px;
  height: 30px;
}

.transform-page .transform-table .all-wrap {
  color: #fafbff;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  display: flex;
}

.transform-page .transform-table .all-wrap .label {
  background: #1f18a6;
  width: 120px;
  height: 30px;
}

.transform-page .transform-table .all-wrap .week-day {
  background: #4741c4;
  width: 34px;
  height: 30px;
}

.transform-page .transform-table .all-wrap .week-end {
  background: #1f18a6;
  width: 54px;
  height: 30px;
}

.transform-page .transform-table .all-wrap .total {
  background: #1f18a6;
  width: 70px;
  height: 30px;
}

.transform-page .transform-table .all-wrap .target {
  background: #1f18a6;
  width: 56px;
  height: 30px;
}

.transform-page .transform-table .all-wrap .percent {
  background: #4741c4;
  width: 56px;
  height: 30px;
}

.transform-page .transform-table .all-wrap .average {
  background: #4741c4;
  width: 44px;
  height: 30px;
}

.transform-page .transform-table .all-wrap .month-day {
  background: #b79a64;
  width: 55px;
  height: 30px;
}

.transform-page .transform-table .all-wrap .month-trend {
  color: #131415;
  background: #f4f4f4;
  width: 70px;
  height: 30px;
}

.progress-page {
  flex-direction: column;
  min-width: 800px;
  display: flex;
}

.progress-page .head-title {
  color: #334463;
  flex-shrink: 0;
  padding-top: 20px;
  padding-bottom: 16px;
}

.progress-page .head-title .ant-picker {
  width: 110px;
}

.progress-page .content-wrap {
  flex-grow: 1;
  overflow: auto;
}

.progress-page .content-wrap .info .info-left {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  flex-shrink: 0;
  width: 371px;
  height: 59px;
}

.progress-page .content-wrap .info .info-left .month {
  color: #131415;
  text-align: center;
  background: #72c7ae;
  height: 29px;
  font-size: 14px;
  font-weight: 600;
  line-height: 29px;
}

.progress-page .content-wrap .info .info-left .day-wrap {
  color: #666;
  background: #e3e4e6;
  place-content: center space-between;
  align-items: center;
  height: 21px;
  padding: 0 6px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
}

.progress-page .content-wrap .info .info-left .day-wrap .black {
  color: #000;
}

.progress-page .content-wrap .info .info-left .day-wrap .red {
  color: #d24d49;
}

.progress-page .content-wrap .info .info-left .progress {
  background: #e43f42;
  height: 8px;
}

.progress-page .content-wrap .info .info-left .progress .used {
  background: #cdcecf;
  height: 100%;
}

.progress-page .content-wrap .info .info-right {
  color: #131415;
  background: #e3e4e6;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 59px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  position: relative;
}

.progress-page .content-wrap .info .info-right .time {
  margin-left: 20px;
}

.progress-page .content-wrap .info .info-right .desc {
  color: #131415;
  background: #d8d8d8;
  justify-content: center;
  align-items: center;
  width: 204px;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  position: absolute;
  right: 9px;
}

.progress-page .content-wrap .table {
  color: #131415;
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
}

.progress-page .content-wrap .table .center {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.progress-page .content-wrap .table .col-1 {
  width: 61px;
  height: 31px;
}

.progress-page .content-wrap .table .col-2 {
  width: 81px;
  height: 31px;
}

.progress-page .content-wrap .table .col-3 {
  width: 67px;
  height: 31px;
}

.progress-page .content-wrap .table .col-4 {
  width: 95px;
  height: 31px;
}

.progress-page .content-wrap .table .col-5 {
  width: 67px;
  height: 31px;
}

.progress-page .content-wrap .table .col-6 {
  background: #ddebf7;
  width: 134px;
  height: 31px;
}

.progress-page .content-wrap .table .col-7 {
  background: #cfe3f4;
  width: 134px;
  height: 31px;
}

.progress-page .content-wrap .table .col-8 {
  background: #c0ddf6;
  width: 134px;
  height: 31px;
}

.progress-page .content-wrap .table .col-9 {
  background: #a1d2fc;
  width: 134px;
  height: 31px;
}

.progress-page .content-wrap .table .col-10 {
  background: #77b6eb;
  width: 98px;
  height: 31px;
}

.progress-page .content-wrap .table .col-11 {
  background: #e7c2c2;
  width: 79px;
  height: 31px;
}

.progress-page .content-wrap .table .col-12 {
  background: #e4cfa6;
  width: 79px;
  height: 31px;
}

.progress-page .content-wrap .table .table-head {
  display: flex;
}

.progress-page .content-wrap .table .table-head .col-1, .progress-page .content-wrap .table .table-head .col-2, .progress-page .content-wrap .table .table-head .col-3, .progress-page .content-wrap .table .table-head .col-4, .progress-page .content-wrap .table .table-head .col-5 {
  background: #72c7ae;
}

.progress-page .content-wrap .table .table-head .desc {
  color: #747e88;
  font-size: 12px;
  font-weight: 400;
}

.progress-page .content-wrap .table .table-body .area {
  border-bottom: 1px solid #fff;
}

.progress-page .content-wrap .table .table-body .user {
  display: flex;
}

.progress-page .content-wrap .table .table-body .user .col-1 {
  height: unset;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.progress-page .content-wrap .table .table-body .city-wrap .col-2 {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.progress-page .content-wrap .table .table-body .city-wrap .city-name {
  flex-shrink: 0;
  margin-left: 10px;
}

.progress-page .content-wrap .table .table-body .city-wrap .star {
  color: #d80408;
}

.progress-page .content-wrap .table .table-body .city-wrap .star-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/star.png");
  background-size: cover;
  width: 14px;
  height: 14px;
}

.progress-page .content-wrap .table .table-body .city-wrap .col-4 {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.progress-page .content-wrap .table .table-body .city-wrap .col-4 .percent {
  width: 0%;
  height: 100%;
}

.progress-page .content-wrap .table .table-body .city-wrap .col-4 .num {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.progress-page .content-wrap .table .table-body .city-wrap .col-11 {
  background: #f1e2e2;
}

.progress-page .content-wrap .table .table-body .city-wrap .col-12 {
  background: #eee6d6;
}

.progress-page .content-wrap .table .table-body .city-wrap .red {
  color: #e43f42;
}

.account-content .title-wrap {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.account-content .list-card {
  margin-bottom: 10px;
}

.account-content .list-card .group-name {
  color: #223343;
  background: #eee;
  border-radius: 7px;
  margin-bottom: -16px;
  padding: 6px 8px 20px;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  display: inline-block;
}

.account-content .list-card .info-wrap {
  background: #fff;
  border-radius: 16px;
  min-height: 126px;
  position: relative;
  box-shadow: 0 25px 50px #0000000a;
}

.account-content .list-card .info-wrap .wrap-1 {
  justify-content: space-between;
  align-items: center;
  height: 44px;
  display: flex;
}

.account-content .list-card .info-wrap .wrap-1 .left {
  align-items: center;
  padding-left: 5px;
  display: flex;
}

.account-content .list-card .info-wrap .wrap-1 .left .user-wrap {
  background: #fff8eb;
  border-radius: 16px;
  align-items: center;
  height: 30px;
  padding: 0 6px;
  display: flex;
}

.account-content .list-card .info-wrap .wrap-1 .left .user-wrap .head-img {
  background-color: #ddd;
  background-size: cover;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}

.account-content .list-card .info-wrap .wrap-1 .left .user-wrap .username {
  color: #223343;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 600;
}

.account-content .list-card .info-wrap .wrap-1 .left .user-wrap .line {
  background: #f8e4be;
  width: 1px;
  height: 15px;
  margin-left: 4px;
  margin-right: 6px;
}

.account-content .list-card .info-wrap .wrap-1 .left .user-wrap .line.org {
  background: #ff754c;
  margin-left: 5px;
  margin-right: 4px;
}

.account-content .list-card .info-wrap .wrap-1 .left .user-wrap .goal-wrap {
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
  position: relative;
}

.account-content .list-card .info-wrap .wrap-1 .left .user-wrap .more-goal {
  text-wrap: nowrap;
  color: #223343;
  background: #fff;
  border: 1px solid #ffffff29;
  border-radius: 10px;
  max-width: 500px;
  padding: 0 8px;
  font-size: 12px;
  line-height: 30px;
  display: flex;
  position: absolute;
  top: -40px;
  left: 0;
  box-shadow: 0 10px 10px #0000001c;
}

.account-content .list-card .info-wrap .wrap-1 .left .user-wrap .more-goal:after {
  content: "";
  border: 5px solid #0000;
  border-top: 8px solid #fff;
  border-bottom-width: 8px;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -15px;
  left: 45px;
}

.account-content .list-card .info-wrap .wrap-1 .left .user-wrap .more-goal .it-line {
  opacity: .26;
  background: #d8d8d8;
  width: 1px;
  height: 10px;
  margin: 0 4px;
}

.account-content .list-card .info-wrap .wrap-1 .left .user-wrap .more-goal .it {
  align-items: center;
  display: flex;
}

.account-content .list-card .info-wrap .wrap-1 .left .user-wrap .goal {
  color: #ff754c;
  align-items: center;
  margin-left: 6px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
}

.account-content .list-card .info-wrap .wrap-1 .left .user-wrap .goal .num {
  font-size: 16px;
  font-weight: 600;
}

.account-content .list-card .info-wrap .wrap-1 .left .user-wrap .star {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/star.png");
  background-size: cover;
  width: 14px;
  height: 14px;
}

.account-content .list-card .info-wrap .wrap-1 .left .sex {
  color: #999;
  margin-left: 10px;
  font-size: 12px;
}

.account-content .list-card .info-wrap .wrap-1 .left .tel {
  color: #7c86a9;
  margin-left: 15px;
  font-size: 12px;
  font-weight: 600;
}

.account-content .list-card .info-wrap .wrap-1 .right {
  justify-content: space-between;
  align-items: center;
  width: 30%;
  padding-right: 15px;
  display: flex;
}

.account-content .list-card .info-wrap .wrap-1 .right .time {
  color: #999;
  font-size: 12px;
}

.account-content .list-card .info-wrap .wrap-1 .right .stop-btn {
  color: #ff754c;
  cursor: pointer;
  background: #ffebf6;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 24px;
  font-size: 12px;
  display: flex;
}

.account-content .list-card .info-wrap .wrap-1 .right .stop-btn .stop-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/x.png");
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.account-content .list-card .info-wrap .wrap-1 .right .stop-btn:hover {
  color: #fff;
  background: #f66955;
}

.account-content .list-card .info-wrap .wrap-1 .right .stop-btn:hover .stop-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/x1.png");
}

.account-content .list-card .info-wrap .wrap-1 .right .start-btn {
  color: #fff;
  cursor: pointer;
  background: #7d79ec;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 24px;
  font-size: 12px;
  display: flex;
}

.account-content .list-card .info-wrap .wrap-1 .right .start-btn .start-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/active.png");
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.account-content .list-card .info-wrap .wrap-1 .right .start-btn:hover {
  background: #5a55e7;
}

.account-content .list-card .info-wrap .wrap-1 .right .edit-btn {
  color: #7d79ec;
  cursor: pointer;
  background: #f2f1fd;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 24px;
  margin-left: 10px;
  font-size: 12px;
  display: flex;
}

.account-content .list-card .info-wrap .wrap-1 .right .edit-btn .edit-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/e.png");
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.account-content .list-card .info-wrap .wrap-1 .right .edit-btn:hover {
  color: #fff;
  background: #7d79ec;
}

.account-content .list-card .info-wrap .wrap-1 .right .edit-btn:hover .edit-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/e1.png");
}

.account-content .list-card .info-wrap .col-1 {
  width: 34%;
  padding-left: 13px;
}

.account-content .list-card .info-wrap .col-2, .account-content .list-card .info-wrap .col-3 {
  width: 18%;
}

.account-content .list-card .info-wrap .col-4 {
  width: 20%;
}

.account-content .list-card .info-wrap .col-5 {
  width: 10%;
}

.account-content .list-card .info-wrap .wrap-2 {
  text-align: left;
  color: #7e86a6;
  background: #fafbff;
  border-top: 1px solid #e6e8f0;
  align-items: center;
  height: 30px;
  font-size: 12px;
  display: flex;
}

.account-content .list-card .info-wrap .wrap-3 {
  align-items: center;
  height: 52px;
  display: flex;
}

.account-content .list-card .info-wrap .wrap-3 .account {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
  background: #e8e8fc;
  border-radius: 5px;
  max-width: 110px;
  height: 24px;
  margin-right: 5px;
  padding: 0 5px;
  font-size: 12px;
  line-height: 24px;
  overflow: hidden;
}

.account-content .list-card .info-wrap .wrap-3 .info {
  color: #333;
  font-size: 12px;
  font-weight: 600;
}

.account-content .list-card .info-wrap .more {
  color: #54528e;
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
}

.account-content .list-card .info-wrap .more.ml-10 {
  margin-left: 10px;
}

.account-content .account-model {
  z-index: 99;
  background: #00000057;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.account-content .account-model .account-wrap {
  background-color: #fff;
  border-radius: 10px;
  width: 700px;
}

.account-content .account-model .title-wrap {
  border-bottom: 1px solid #f7f7f7;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  display: flex;
}

.account-content .account-model .title-wrap .title {
  color: #333;
  padding-left: 26px;
  font-size: 16px;
  font-weight: 600;
}

.account-content .account-model .title-wrap .close {
  cursor: pointer;
  width: 65px;
  height: 65px;
  position: relative;
}

.account-content .account-model .title-wrap .close:before {
  content: "";
  background-color: #333;
  width: 20px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.account-content .account-model .title-wrap .close:after {
  content: "";
  background-color: #333;
  width: 20px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.account-content .account-model .content-wrap {
  min-height: 200px;
  max-height: 400px;
  padding: 15px;
  overflow: auto;
}

.account-content .account-model .content-wrap .item {
  color: #333;
  background: #ebebeb;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  height: 34px;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
}

.com-confirm {
  z-index: 2000;
  background-color: #00000057;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.com-confirm.true {
  background-color: #0000;
}

.com-confirm .confirm-modal {
  background-color: #fff;
  border-radius: 8px;
  width: 480px;
}

.com-confirm .confirm-modal .title-wrap {
  border-bottom: 1px solid #f7f7f7;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  display: flex;
}

.com-confirm .confirm-modal .title-wrap .title {
  color: #333;
  padding-left: 26px;
  font-size: 16px;
  font-weight: 600;
}

.com-confirm .confirm-modal .title-wrap .close {
  cursor: pointer;
  width: 65px;
  height: 65px;
  position: relative;
}

.com-confirm .confirm-modal .title-wrap .close:before {
  content: "";
  background-color: #333;
  width: 20px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.com-confirm .confirm-modal .title-wrap .close:after {
  content: "";
  background-color: #333;
  width: 20px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.com-confirm .confirm-modal .content {
  text-align: center;
  padding: 25px;
}

.com-confirm .confirm-modal .content .message {
  color: #333;
  font-size: 16px;
}

.com-confirm .confirm-modal .content .sub-message {
  color: #aaa;
  margin-top: 8px;
}

.com-confirm .confirm-modal .action {
  justify-content: center;
  padding: 25px;
  display: flex;
}

.com-confirm .confirm-modal .action .button {
  cursor: pointer;
  color: #7f83f5;
  border: 1px solid #7f83f5;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 34px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.com-confirm .confirm-modal .action .contained {
  color: #fff;
  background: #7f83f5;
  margin-left: 32px;
}

.com-avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 7px;
  width: 88px;
  height: 88px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 0 1px #f3f3fe;
}

.com-avatar .tip {
  color: #fff;
  background: #0006;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  font-size: 12px;
  display: flex;
  position: absolute;
  bottom: 0;
}

.com-avatar .tip .tip-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/e1.png");
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.com-avatar .empty {
  background-color: #f3f3fe;
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/can.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 29px 26px;
  width: 88px;
  height: 88px;
}

.com-avatar .input-file {
  opacity: 0;
  cursor: pointer;
  width: 88px;
  height: 88px;
  position: absolute;
  top: 0;
  left: 0;
}

.com-choose-area-model {
  z-index: 999;
  background: #00000057;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.com-choose-area-model .area-wrap {
  background: #fff;
  border-radius: 10px;
  width: 700px;
}

.com-choose-area-model .area-wrap .area-title-wrap {
  border-bottom: 1px solid #f7f7f7;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  display: flex;
}

.com-choose-area-model .area-wrap .area-title-wrap .title {
  color: #333;
  padding-left: 26px;
  font-size: 16px;
  font-weight: 600;
}

.com-choose-area-model .area-wrap .area-title-wrap .close {
  cursor: pointer;
  width: 65px;
  height: 65px;
  position: relative;
}

.com-choose-area-model .area-wrap .area-title-wrap .close:before {
  content: "";
  background-color: #333;
  width: 20px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.com-choose-area-model .area-wrap .area-title-wrap .close:after {
  content: "";
  background-color: #333;
  width: 20px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.com-choose-area-model .area-wrap .area-content-wrap {
  padding: 24px 0;
}

.com-choose-area-model .area-wrap .area-content-wrap .input-wrap {
  align-items: center;
  padding: 0 48px 18px;
  display: flex;
}

.com-choose-area-model .area-wrap .area-content-wrap .input-wrap .label {
  color: #757575;
  flex-shrink: 0;
  padding-right: 42px;
  font-size: 14px;
}

.com-choose-area-model .area-wrap .area-content-wrap .search-wrap {
  padding-bottom: 16px;
  padding-left: 160px;
  display: flex;
}

.com-choose-area-model .area-wrap .area-content-wrap .search-wrap .com-input {
  flex: none;
  width: 384px;
}

.com-choose-area-model .area-wrap .area-content-wrap .search-wrap .search-btn {
  padding: 0 16px;
  line-height: 30px;
}

.com-choose-area-model .area-wrap .area-content-wrap .search-wrap .search-btn:hover {
  color: #7d79ec;
}

.com-choose-area-model .area-wrap .area-content-wrap .area-city-box {
  height: 300px;
  display: flex;
}

.com-choose-area-model .area-wrap .area-content-wrap .area-city-box .province-wrap {
  flex-shrink: 0;
  width: 160px;
  overflow: auto;
}

.com-choose-area-model .area-wrap .area-content-wrap .area-city-box .province-wrap .item {
  color: #666;
  text-align: center;
  cursor: pointer;
  border-left: 4px solid #0000;
  margin: 22px 0;
  font-size: 14px;
  line-height: 24px;
}

.com-choose-area-model .area-wrap .area-content-wrap .area-city-box .province-wrap .item.true {
  color: #7d79ec;
  border-color: #7d79ec;
}

.com-choose-area-model .area-wrap .area-content-wrap .area-city-box .city-wrap {
  background: #f8f8f8;
  flex-grow: 1;
  overflow: auto;
}

.com-choose-area-model .area-wrap .area-content-wrap .area-city-box .city-wrap .tip {
  color: #999;
  padding-top: 28px;
  padding-left: 28px;
}

.com-choose-area-model .area-wrap .area-content-wrap .area-city-box .city-wrap .item-wrap {
  flex-wrap: wrap;
  padding: 28px 16px 28px 26px;
  display: flex;
}

.com-choose-area-model .area-wrap .area-content-wrap .area-city-box .city-wrap .item-wrap .item {
  -webkit-user-select: none;
  user-select: none;
  color: #333;
  cursor: pointer;
  background: #ebebeb;
  border-radius: 5px;
  height: 34px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 34px;
}

.com-choose-area-model .area-wrap .area-content-wrap .area-city-box .city-wrap .item-wrap .item.true {
  color: #fff;
  background: #7f83f5;
  font-weight: 500;
}

.com-choose-area-model .area-wrap .check-wrap {
  padding-left: 160px;
  display: flex;
}

.com-choose-area-model .area-wrap .check-wrap .label {
  color: #999;
  flex-shrink: 0;
  margin-top: 6px;
  font-size: 14px;
}

.com-choose-area-model .area-wrap .check-wrap .check-box {
  flex-wrap: wrap;
  padding-right: 10px;
  display: flex;
}

.com-choose-area-model .area-wrap .check-wrap .check-box .item {
  color: #333;
  background: #f2f2f2;
  border-radius: 5px;
  align-items: center;
  height: 34px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0 10px;
  font-size: 14px;
  line-height: 34px;
  display: flex;
}

.com-choose-area-model .area-wrap .check-wrap .check-box .item .del-icon {
  cursor: pointer;
  background: #d8d8d8;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-left: 7px;
  position: relative;
}

.com-choose-area-model .area-wrap .check-wrap .check-box .item .del-icon:before {
  content: "";
  background: #a6a6a6;
  width: 8px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.com-choose-area-model .area-wrap .check-wrap .check-box .item .del-icon:after {
  content: "";
  background: #a6a6a6;
  width: 8px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.com-choose-area-model .area-wrap .check-wrap .check-box .item .del-icon:hover {
  opacity: .75;
}

.com-choose-area-model .area-wrap .button-wrap {
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 56px 30px 26px;
  display: flex;
}

.com-choose-area-model .area-wrap .button-wrap .confirm {
  color: #fff;
  cursor: pointer;
  background: #7f83f5;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 34px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.com-choose-area-model .area-wrap .button-wrap .confirm:hover {
  background-color: #5a55e7;
}

.account-form-wrap {
  width: 976px;
  margin: auto;
}

.account-form-wrap .form-panel {
  background-color: #fff;
  border-radius: 14px;
  padding: 40px 300px 40px 80px;
  box-shadow: 0 25px 50px #0000000a;
}

.account-form-wrap .form-panel .caption {
  color: #b0bbc5;
  font-size: 20px;
}

.account-form-wrap .form-panel .title {
  padding-top: 40px;
  padding-bottom: 30px;
  font-size: 18px;
}

.account-form-wrap .form-panel .fix-width {
  width: 172px;
}

.account-form-wrap .input-section {
  padding-bottom: 20px;
  display: flex;
}

.account-form-wrap .input-section.align {
  align-items: center;
}

.account-form-wrap .input-section .input-label {
  color: #757575;
  text-align: right;
  flex: none;
  width: 120px;
  padding-right: 14px;
  line-height: 34px;
}

.account-form-wrap .input-section .flex-label {
  color: #757575;
  padding-right: 14px;
  line-height: 34px;
}

.account-form-wrap .star-level .ant-select, .account-form-wrap .star-level .com-input {
  width: 184px;
}

.account-form-wrap .short-wrap {
  width: 172px;
}

.account-form-wrap .action-wrap {
  justify-content: flex-end;
}

.account-form-wrap .action-wrap .hollow {
  margin-right: 20px;
}

.account-form-wrap .goal-wrap {
  flex-wrap: wrap;
  margin-bottom: -10px;
  display: flex;
}

.account-form-wrap .goal-wrap .input-section {
  margin-right: 16px;
  padding-bottom: 10px;
}

.account-form-wrap .goal-wrap .input-label {
  width: unset;
  padding-right: 0;
}

.account-form-wrap .goal-wrap .fix-width {
  width: 76px;
}

.account-form-wrap .account-list {
  flex-direction: column;
  align-items: flex-start;
  margin-top: -10px;
  padding-bottom: 5px;
  padding-left: 120px;
  display: flex;
}

.account-form-wrap .account-list .city-item {
  color: #7c86a9;
  background-color: #f1f1fc;
  border-radius: 8px;
  margin-bottom: 10px;
  padding-left: 8px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
}

.account-form-wrap .account-list .city-item .label {
  flex: none;
}

.account-form-wrap .account-list .city-item .list {
  flex-wrap: wrap;
  display: flex;
}

.account-form-wrap .account-list .city-item .list .item {
  margin-right: 10px;
}

.account-form-wrap .account-list .city-item.red {
  color: #cf5660;
  background-color: #fbe4e4;
  font-weight: 400;
}

.dict-list .table-wrap .col-start {
  flex-shrink: 0;
  width: 22%;
  padding-left: 30px;
}

.dict-list .table-wrap .col-city {
  flex-shrink: 0;
  width: 42%;
}

.dict-list .table-wrap .col-21 {
  flex-shrink: 0;
  width: 21%;
}

.dict-list .table-wrap .col-grow {
  flex-grow: 1;
}

.dict-list .table-wrap .col-end {
  flex-shrink: 0;
  align-items: center;
  width: 15%;
  font-weight: 400;
  display: flex;
}

.dict-list .table-wrap .table-head {
  color: #4b5e76;
  background: #f3f6ff;
  border-radius: 16px 16px 0 0;
  align-items: center;
  width: 100%;
  height: 44px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
}

.dict-list .table-wrap .table-content {
  background: #fff;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 25px 50px #0000000a;
}

.dict-list .table-wrap .table-content .row {
  color: #333;
  border-top: 1px solid #e6e8f0;
  align-items: center;
  height: 44px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
}

.dict-list .table-wrap .table-content .col-grow {
  color: #999;
}

.dict-list .table-wrap .table-content .del-btn {
  color: #d2a091;
  cursor: pointer;
  background: #f7f4f6;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 24px;
  margin-right: 10px;
  font-size: 12px;
  display: flex;
}

.dict-list .table-wrap .table-content .del-btn .del-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/d.png");
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.dict-list .table-wrap .table-content .del-btn:hover {
  color: #fff;
  background: #f66955;
}

.dict-list .table-wrap .table-content .del-btn:hover .del-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/d1.png");
}

.dict-list .table-wrap .table-content .edit-btn {
  color: #7d79ec;
  cursor: pointer;
  background: #f2f1fd;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 24px;
  display: flex;
}

.dict-list .table-wrap .table-content .edit-btn .edit-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/e.png");
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.dict-list .table-wrap .table-content .edit-btn:hover {
  color: #fff;
  background: #7d79ec;
}

.dict-list .table-wrap .table-content .edit-btn:hover .edit-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/e1.png");
}

.dict-model {
  z-index: 99;
  background: #00000057;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.dict-model .title-wrap {
  border-bottom: 1px solid #f7f7f7;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  display: flex;
}

.dict-model .title-wrap .title {
  color: #333;
  padding-left: 26px;
  font-size: 16px;
  font-weight: 600;
}

.dict-model .title-wrap .close {
  cursor: pointer;
  width: 65px;
  height: 65px;
  position: relative;
}

.dict-model .title-wrap .close:before {
  content: "";
  background-color: #333;
  width: 20px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.dict-model .title-wrap .close:after {
  content: "";
  background-color: #333;
  width: 20px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.dict-model .button-wrap {
  justify-content: flex-end;
  align-items: center;
  padding: 8px 56px 30px 26px;
  display: flex;
}

.dict-model .button-wrap .confirm {
  color: #fff;
  cursor: pointer;
  background: #7f83f5;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 34px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.dict-model .city-wrap {
  background: #fff;
  border-radius: 10px;
  width: 556px;
}

.dict-model .city-wrap .content-wrap {
  height: 220px;
  padding: 40px 56px 40px 26px;
  display: flex;
}

.dict-model .city-wrap .content-wrap .label {
  color: #757575;
  margin-top: 4px;
  margin-right: 15px;
  font-size: 14px;
}

.dict-model .city-form {
  padding: 40px 26px 60px;
}

.dict-model .wx-wrap {
  background: #fff;
  border-radius: 10px;
  width: 556px;
}

.dict-model .wx-wrap.wid {
  width: 600px;
}

.dict-model .wx-wrap .content-wrap {
  padding: 40px 46px 34px 26px;
}

.dict-model .wx-wrap .content-wrap .input-wrap {
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.dict-model .wx-wrap .content-wrap .input-wrap .label {
  color: #757575;
  flex-shrink: 0;
  font-size: 14px;
}

.dict-model .wx-wrap .content-wrap .input-wrap .select {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #d2d4db;
  cursor: pointer;
  border: 1px solid #d6d6e6;
  border-radius: 8px;
  flex-grow: 1;
  height: 34px;
  padding: 0 14px;
  font-size: 14px;
  line-height: 34px;
  position: relative;
  overflow: hidden;
}

.dict-model .wx-wrap .content-wrap .input-wrap .select:after {
  content: "";
  border-bottom: 1px solid #3d4a62;
  border-right: 1px solid #3d4a62;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 11px;
  right: 13px;
  transform: rotate(45deg);
}

.dict-model .wx-wrap .content-wrap .input-wrap .select.true {
  color: #333;
}

.dict-model .wx-wrap .content-wrap .input-wrap .select:hover {
  border-color: #7d79ec;
}

.dict-model .select-city {
  background: #fff;
  border-radius: 10px;
  width: 700px;
}

.dict-model .select-city .content-wrap {
  background: #f8f8f8;
  max-height: 243px;
  padding: 28px 15px 28px 25px;
  overflow: auto;
}

.dict-model .select-city .city-box {
  flex-wrap: wrap;
  display: flex;
}

.dict-model .select-city .city-box .item {
  color: #333;
  cursor: pointer;
  background: #ebebeb;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  min-width: 58px;
  height: 34px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 0 8px;
  font-size: 14px;
  display: flex;
}

.dict-model .select-city .city-box .item.true {
  color: #fff;
  background: #7f83f5;
  font-weight: 500;
}

.operate-list .operate-fix-wrap {
  max-width: 1600px;
  margin: auto;
}

.operate-list .operate-fix-wrap .empty-msg {
  margin-top: 30px;
}

.operate-list .blue1 {
  color: #8891b2;
}

.operate-list .blue2 {
  color: #7e86a6;
}

.operate-list .green {
  color: #09af7b;
}

.operate-list .tab-wrap {
  align-items: center;
  height: 32px;
  display: flex;
}

.operate-list .tab-wrap .tab {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #eee;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 28px;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
}

.operate-list .tab-wrap .tab:hover {
  background-color: #e8e8fc;
}

.operate-list .tab-wrap .tab.true {
  color: #fff;
  background-color: #7d79ec;
}

.operate-list .search-section {
  margin-bottom: 10px;
  padding-top: 16px;
}

.operate-list .search-section .search-wrap {
  position: relative;
}

.operate-list .search-section .search-wrap .search-input {
  background-color: #fff;
  border: 0;
  border-radius: 8px;
  width: 320px;
  height: 36px;
  padding-left: 14px;
  box-shadow: 0 0 0 1px #d3d2e5;
}

.operate-list .search-section .search-wrap .search-input:hover {
  box-shadow: 0 0 0 1px #7d79ec;
}

.operate-list .search-section .search-wrap .search-input:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #7d79ec;
}

.operate-list .search-section .search-wrap .del-wrap {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  display: none;
  position: absolute;
  top: 0;
  right: 50px;
}

.operate-list .search-section .search-wrap .del-wrap .del-icon {
  cursor: pointer;
  width: 20px;
  height: 21px;
  position: relative;
}

.operate-list .search-section .search-wrap .del-wrap .del-icon:before, .operate-list .search-section .search-wrap .del-wrap .del-icon:after {
  content: "";
  background-color: #333;
  width: 16px;
  height: 1px;
  position: absolute;
  top: 10px;
  left: 2px;
}

.operate-list .search-section .search-wrap .del-wrap .del-icon:before {
  transform: rotate(45deg);
}

.operate-list .search-section .search-wrap .del-wrap .del-icon:after {
  transform: rotate(135deg);
}

.operate-list .search-section .search-wrap .del-wrap.true {
  display: flex;
}

.operate-list .search-section .search-wrap .search-icon {
  cursor: pointer;
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/search.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  border-radius: 8px;
  width: 50px;
  height: 36px;
  position: absolute;
  top: 0;
  right: 0;
}

.operate-list .search-section .search-wrap .search-icon:hover {
  background-color: #eee;
}

.operate-list .search-section .filter-wrap {
  align-items: center;
  display: flex;
}

.operate-list .search-section .filter-wrap .ant-select-selection-item {
  font-weight: 600;
}

.operate-list .search-section .filter-wrap .type-group {
  border: 1px solid #eee;
  border-radius: 4px;
  height: 32px;
  margin-left: 10px;
  padding: 3px 4px;
  display: flex;
}

.operate-list .search-section .filter-wrap .type-group .type {
  cursor: pointer;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 26px;
  margin-right: 4px;
  display: flex;
}

.operate-list .search-section .filter-wrap .type-group .type:last-child {
  margin-right: 0;
}

.operate-list .search-section .filter-wrap .type-group .type:hover {
  background-color: #ebeaf9;
}

.operate-list .search-section .filter-wrap .type-group .type.true {
  background-color: #ebeaf9;
  font-weight: 600;
}

.operate-list .search-section .filter-wrap .check-group {
  flex: none;
  align-items: center;
  padding-left: 16px;
  font-weight: 600;
  display: flex;
}

.operate-list .search-section .filter-wrap .filter-btn {
  color: #fff;
  background-color: #8f8dcf;
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/filter.png");
  background-position: 8px 7px;
  background-repeat: no-repeat;
  background-size: 14px;
  border-radius: 4px;
  flex: none;
  align-items: center;
  height: 28px;
  margin-left: 16px;
  padding-left: 28px;
  padding-right: 8px;
  display: flex;
  box-shadow: 0 2px 17px #cfe0df;
}

.operate-list .search-section .filter-wrap .filter-btn:hover {
  background-color: #7370c3;
}

.operate-list .search-panel {
  background-color: #fff;
  min-width: 1128px;
  height: 0;
  padding-left: 12px;
  padding-right: 10px;
  overflow: hidden;
}

.operate-list .search-panel.true {
  height: unset;
  border-radius: 8px;
  box-shadow: 0 5px 10px #0000000a;
}

.operate-list .search-panel .mr-10 {
  margin-right: 10px;
}

.operate-list .search-panel .first-wrap {
  align-items: center;
  display: flex;
}

.operate-list .search-panel .first-wrap .time-wrap {
  margin-top: 10px;
  margin-right: 20px;
}

.operate-list .search-panel .first-wrap .ep-wrap {
  margin-top: 10px;
}

.operate-list .search-panel .first-wrap .blue1 {
  padding-right: 10px;
}

.operate-list .search-panel .first-wrap .pl-20 {
  padding-left: 20px;
}

.operate-list .search-panel .first-wrap .ab-wrap {
  width: 120px;
  margin-right: 10px;
}

.operate-list .search-panel .second-wrap {
  padding-top: 10px;
  padding-bottom: 10px;
}

.operate-list .search-panel .second-wrap .act {
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 28px;
  margin-right: 10px;
  font-weight: 600;
  display: flex;
}

.operate-list .search-panel .second-wrap .act.search {
  background-color: #e1e0fb;
}

.operate-list .search-panel .second-wrap .act.search:hover {
  background-color: #bebcf6;
}

.operate-list .search-panel .second-wrap .act.reset {
  background-color: #eee;
}

.operate-list .search-panel .second-wrap .act.reset:hover {
  background-color: #ddd;
}

.operate-list .search-panel .second-wrap .tog-act {
  color: #8f95b2;
  border-radius: 7px;
  padding-left: 6px;
  padding-right: 22px;
  line-height: 28px;
  position: relative;
}

.operate-list .search-panel .second-wrap .tog-act:hover {
  background-color: #f8f8f8;
}

.operate-list .search-panel .second-wrap .tog-act:before {
  content: "";
  border-top: 2px solid #8f95b2;
  border-left: 2px solid #8f95b2;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translate(-50%, -25%)rotate(45deg);
}

.operate-list .search-panel .data-gap-wrap .blue1 {
  margin-right: 10px;
}

.operate-list .search-panel .data-gap-wrap .input-group {
  margin-right: 16px;
}

.operate-list .search-panel .data-gap-wrap .input-group .f-600 {
  margin-right: 10px;
}

.operate-list .search-panel .data-gap-wrap .input-group .gap {
  background-color: #d6d6e6;
  width: 12px;
  height: 1px;
  margin: 0 5px;
}

.operate-list .search-panel .data-gap-wrap input {
  border: 0;
  border-radius: 4px;
  width: 40px;
  height: 28px;
  padding-left: 7px;
  box-shadow: 0 0 0 1px #d6d6e6;
}

.operate-list .search-panel .data-gap-wrap input:focus {
  outline: 0;
  box-shadow: 0 0 0 1px #7d79ec;
}

.operate-list .city-panel {
  z-index: 0;
  align-items: center;
  height: 60px;
  margin-top: 20px;
  margin-bottom: -10px;
  display: flex;
  position: relative;
}

.operate-list .city-panel.false {
  display: none;
}

.operate-list .city-panel .user-day-data {
  background-color: #e1f0ff;
  border-radius: 14px 14px 0 0;
  flex-grow: 1;
  height: 80px;
  display: flex;
  position: relative;
  top: 10px;
}

.operate-list .city-panel .user-day-data .td {
  text-align: center;
  flex-grow: 1;
  position: relative;
}

.operate-list .city-panel .user-day-data .td:after {
  content: "";
  background-color: #b6cfe7;
  width: 1px;
  height: 27px;
  position: absolute;
  top: 17px;
  right: 0;
}

.operate-list .city-panel .user-day-data .td:last-child:after {
  display: none;
}

.operate-list .city-panel .user-day-data .td .num {
  color: #0096f9;
  padding-top: 7px;
  font-size: 18px;
  font-weight: bold;
}

.operate-list .city-panel .user-day-data .td .green {
  color: #47bb76;
}

.operate-list .city-panel .user-day-data .td .red {
  color: #ca000f;
}

.operate-list .city-panel .user-day-data .td .label {
  font-size: 12px;
}

.operate-list .city-panel .city-wrap {
  margin-left: auto;
  display: flex;
}

.operate-list .city-panel .city-wrap .city {
  cursor: pointer;
  background-color: #ededed;
  border-radius: 7px;
  flex: none;
  margin: 10px 0 8px 10px;
  padding: 0 8px;
  line-height: 28px;
}

.operate-list .city-panel .city-wrap .city.true {
  color: #fff;
  background-color: #8f8dcf;
  font-weight: 600;
}

.operate-list .operate-table-wrap {
  z-index: 1;
  position: relative;
}

.operate-list .operate-table {
  border-spacing: 0;
  background-color: #fff;
  border-radius: 16px;
  width: 100%;
  margin-top: 10px;
  font-size: 12px;
  overflow: hidden;
  box-shadow: 0 15px 50px #0000000a;
}

.operate-list .operate-table th, .operate-list .operate-table td {
  margin: 0;
  padding: 0;
}

.operate-list .operate-table .table-head {
  color: #7e86a6;
  background-color: #f2f4ff;
  height: 30px;
}

.operate-list .operate-table .table-head th {
  font-weight: 400;
}

.operate-list .operate-table .table-head .sort-wrap {
  justify-content: center;
  width: 100%;
  height: 18px;
  line-height: 20px;
  display: flex;
}

.operate-list .operate-table .table-head .sort-wrap .sort {
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 11px 9px;
  width: 22px;
  height: 18px;
}

.operate-list .operate-table .table-head .sort-wrap .sort:hover {
  background-color: #ddd;
  border-radius: 4px;
}

.operate-list .operate-table .table-head .sort-wrap .sort-0 {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/sort0.png");
}

.operate-list .operate-table .table-head .sort-wrap .sort-1 {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/sort1.png");
}

.operate-list .operate-table .table-head .sort-wrap .sort-2 {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/sort2.png");
}

.operate-list .operate-table .table-item {
  height: 60px;
}

.operate-list .operate-table .table-item td {
  text-align: center;
  border-bottom: 1px solid #f3f4f6;
}

.operate-list .operate-table .table-item:last-child td {
  border-bottom: 0;
}

.operate-list .operate-table .table-item .blue-bg {
  background-color: #fafbff;
}

.operate-list .operate-table .table-item .relative {
  position: relative;
}

.operate-list .operate-table .table-item .relative .pre {
  background-color: #eee;
  border-radius: 0 0 4px;
  padding: 0 4px;
  font-size: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.operate-list .operate-table .table-item.true:nth-child(2n) {
  background-color: #fbfaff;
}

.operate-list .operate-table .link {
  color: #615fad;
  cursor: pointer;
}

.operate-list .operate-table .link:hover {
  text-decoration: underline;
}

.operate-list .operate-table .cost {
  color: #d25b5b;
  background-color: #f7e3e3;
  border-radius: 6px;
  padding: 0 8px;
  font-weight: 600;
  line-height: 18px;
}

.operate-list .operate-table .date {
  width: 70px;
}

.operate-list .operate-table .brand {
  min-width: 82px;
}

.operate-list .operate-table .direct {
  width: 120px;
  padding: 0 10px;
}

.operate-list .operate-table .red-badge {
  background-color: #ffeff0;
  border-radius: 6px;
  padding: 0 5px;
  line-height: 18px;
  display: inline-flex;
}

.operate-list .operate-table .edit-btn {
  color: #7d79ec;
  cursor: pointer;
  background-color: #f2f1fd;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 24px;
  margin: auto;
  font-size: 12px;
  text-decoration: none;
  display: flex;
}

.operate-list .operate-table .edit-btn .edit-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/e.png");
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 6px;
}

.operate-list .operate-table .edit-btn:hover {
  color: #fff;
  background-color: #7d79ec;
}

.operate-list .operate-table .edit-btn:hover .edit-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/e1.png");
}

.operate-list .page-wrap {
  justify-content: flex-end;
  padding: 16px 0;
}

.operate-list .com-hover-modal {
  z-index: 999;
  background-color: #fff;
  border-radius: 8px;
  width: 700px;
  position: fixed;
  top: 40%;
  left: 60%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  box-shadow: 0 31px 50px #00000021;
}

.operate-list .com-hover-modal .head-wrap {
  background-color: #e4ebf2;
  padding-left: 26px;
  font-size: 16px;
  font-weight: 500;
  line-height: 44px;
}

.operate-list .com-hover-modal .hr-wrap {
  padding: 10px;
}

.operate-list .com-hover-modal .hr-wrap .hr {
  background-color: #f6f7f7;
  border-radius: 8px;
  padding-left: 16px;
  font-size: 12px;
  line-height: 34px;
}

.operate-list .com-hover-modal .hr-wrap .hr .group {
  width: 155px;
}

.com-ass-input {
  position: relative;
}

.com-ass-input .ass-pop {
  z-index: 1;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  padding: 4px;
  position: absolute;
  top: 34px;
  box-shadow: 0 2px 10px #0000001a;
}

.com-ass-input .ass-pop .pop-item {
  cursor: pointer;
  border-radius: 6px;
  padding: 4px 15px;
}

.com-ass-input .ass-pop .pop-item:hover {
  background-color: #f5f5f5;
}

.operate-form .form-panel {
  background-color: #fff;
  border-radius: 14px;
  margin-bottom: 40px;
  padding-top: 32px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 25px 50px #0000000a;
}

.operate-form .form-panel .caption {
  color: #b0bbc5;
  margin-bottom: 20px;
  font-size: 20px;
}

.operate-form .form-panel .wrap-wrap {
  width: 690px;
}

.operate-form .form-panel .title {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
}

.operate-form .form-panel .title.required {
  position: relative;
}

.operate-form .form-panel .title.required:after {
  content: "*";
  color: #fa931c;
  font-size: 14px;
  font-weight: 800;
  position: absolute;
  left: -8px;
}

.operate-form .form-panel .input-line {
  margin-bottom: 16px;
}

.operate-form .form-panel .input-group.with-text {
  height: 140px;
}

.operate-form .form-panel .pos-number {
  width: 300px;
}

.operate-form .basic-info .gap {
  width: 8px;
}

.operate-form .basic-info .content-maker .ant-select {
  width: 160px;
}

.operate-form .ep-name-wrap {
  width: 400px;
}

.operate-form .add-button {
  color: #7d79ec;
  cursor: pointer;
  align-items: center;
  margin-top: 30px;
  padding: 6px 0;
  font-weight: 600;
  line-height: 1;
  display: flex;
}

.operate-form .add-button .add-icon {
  background-color: #7d79ec;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  position: relative;
}

.operate-form .add-button .add-icon:before, .operate-form .add-button .add-icon:after {
  content: "";
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.operate-form .add-button .add-icon:before {
  width: 1px;
  height: 10px;
}

.operate-form .add-button .add-icon:after {
  width: 10px;
  height: 1px;
}

.operate-form .add-button:hover {
  color: #5a55e7;
}

.operate-form .add-button:hover .add-icon {
  background-color: #5a55e7;
}

.operate-form .add-button.green {
  color: #64c8bc;
}

.operate-form .add-button.green .add-icon {
  background-color: #64c8bc;
}

.operate-form .add-button.green:hover {
  color: #41b8aa;
}

.operate-form .add-button.green:hover .add-icon {
  background-color: #41b8aa;
}

.operate-form .hr-item {
  cursor: pointer;
  background-color: #f6f7f7;
  border-radius: 8px;
  align-items: center;
  height: 34px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 6px;
  display: flex;
}

.operate-form .hr-item:last-child {
  margin-bottom: 0;
}

.operate-form .hr-item.new {
  background-color: #f1f1fc;
}

.operate-form .hr-item .new-badge {
  color: #fff;
  background-color: #7d79ec;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
  margin-right: 6px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
}

.operate-form .hr-item .name-wrap {
  width: 200px;
  padding-left: 5px;
}

.operate-form .hr-item .name-wrap .gap {
  padding: 0 3px;
}

.operate-form .hr-item .job-wrap, .operate-form .hr-item .email-wrap {
  width: 180px;
}

.operate-form .hr-item .tel-wrap {
  width: 120px;
}

.operate-form .hr-item .time-wrap {
  color: #9898bc;
  width: 90px;
  margin-left: 20px;
}

.operate-form .hr-item .edit-btn {
  border-radius: 4px;
  margin-left: auto;
  padding: 5px;
}

.operate-form .hr-item .edit-btn .edit-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/e.png");
  background-size: cover;
  width: 16px;
  height: 16px;
}

.operate-form .hr-item .edit-btn:hover {
  background-color: #ddd;
}

.operate-form .old-pos {
  color: #64c8bc;
  margin-bottom: 20px;
  display: flex;
}

.operate-form .old-pos .f-600 {
  padding: 0 3px;
}

.operate-form .old-pos .pos-btn {
  color: #fff;
  cursor: pointer;
  background-color: #64c8bc;
  border-radius: 8px;
  margin-left: 10px;
  padding: 0 8px;
  font-weight: 600;
  line-height: 30px;
}

.operate-form .old-pos .pos-btn:hover {
  background-color: #46bdaf;
}

.operate-form .pos-list .pos-item {
  background-color: #f6f7f7;
  border-radius: 8px;
  align-items: center;
  height: 34px;
  margin-bottom: 10px;
  padding-left: 16px;
  display: flex;
}

.operate-form .pos-list .pos-item:last-child {
  margin-bottom: 0;
}

.operate-form .pos-list .pos-item.new {
  background-color: #e9f7f5;
}

.operate-form .pos-list .pos-item .name-wrap {
  width: 300px;
  font-weight: 600;
  display: flex;
}

.operate-form .pos-list .pos-item .name-wrap .new-badge {
  color: #fff;
  background-color: #64c8bc;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
  margin-right: 6px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
}

.operate-form .pos-list .pos-item .name-wrap .name {
  margin-right: 10px;
}

.operate-form .pos-list .pos-item .cate-wrap {
  width: 180px;
}

.operate-form .pos-list .pos-item .city-wrap {
  width: 240px;
  display: flex;
}

.operate-form .pos-list .pos-item .link {
  cursor: pointer;
}

.operate-form .pos-list .pos-item .link:hover {
  color: #64c8bc;
}

.operate-form .pos-list .pos-item .del-wrap {
  cursor: pointer;
  margin-left: auto;
  padding: 8px 18px;
}

.operate-form .pos-list .pos-item .del-wrap .del-btn {
  background-color: #d8d8d8;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  position: relative;
}

.operate-form .pos-list .pos-item .del-wrap .del-btn:before, .operate-form .pos-list .pos-item .del-wrap .del-btn:after {
  content: "";
  background-color: #a6a6a6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.operate-form .pos-list .pos-item .del-wrap .del-btn:before {
  width: 8px;
  height: 1px;
}

.operate-form .pos-list .pos-item .del-wrap .del-btn:after {
  width: 1px;
  height: 8px;
}

.operate-form .pos-list .pos-item .del-wrap:hover .del-btn {
  background-color: #c7e7e4;
}

.operate-form .pos-list .pos-item .del-wrap:hover .del-btn:before, .operate-form .pos-list .pos-item .del-wrap:hover .del-btn:after {
  background-color: #64c8bc;
}

.operate-form .data-form-item {
  background-color: #fcfcff;
  border: 1px solid #f5f6fa;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 16px;
  display: inline-flex;
}

.operate-form .data-form-item .com-input {
  width: 94px;
}

.operate-form .data-form-item .city-wrap {
  width: 120px;
  display: flex;
}

.operate-form .data-form-item .city-wrap .city-badge {
  color: #7c86a9;
  background-color: #efeffc;
  border-radius: 8px;
  padding: 0 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
}

.operate-form .data-form-item .label {
  text-align: right;
  width: 90px;
}

.operate-form .wx-list-wrap .data-form-item {
  margin-bottom: 8px;
}

.operate-form .wx-list-wrap .data-form-item .city-wrap {
  width: 140px;
  margin-right: -20px;
}

.operate-form .wx-list-wrap .data-form-item:last-child {
  margin-bottom: 0;
}

.operate-form .b-wrap .com-input {
  width: 100px;
}

.operate-form .b-wrap .input-group {
  margin-right: 20px;
}

.operate-form .b-wrap .input-group:last-child {
  margin-right: 0;
}

.operate-form .b-wrap .ep-name .com-input {
  width: 110px;
}

.operate-form .c-split .input-group {
  margin-right: 20px;
}

.operate-form .c-split .com-input {
  width: 100px;
}

.operate-form .o-wrap {
  flex-grow: 1;
}

.operate-form .o-wrap .com-input {
  width: 100px;
}

.operate-form .o-wrap .label {
  padding-left: 20px;
}

.operate-form .hr-expand {
  margin-top: 20px;
  padding-bottom: 20px;
}

.operate-form .hr-expand .hr-group {
  flex-grow: 1;
}

.operate-form .hr-expand .check-item.true {
  color: #ddd;
}

.operate-form .hr-expand .check-item.true .com-check {
  border-color: #eee;
}

.operate-form .check-item {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  color: #757575;
  align-items: center;
  margin-left: 20px;
  margin-right: 40px;
  line-height: 1;
  display: flex;
}

.operate-form .action-wrap {
  justify-content: flex-end;
  padding: 40px 0;
  display: flex;
}

.operate-form .action-wrap .block {
  margin-left: 20px;
}

.image-table {
  padding-top: 30px;
}

.schedule-calendar .filter-wrap {
  display: flex;
}

.schedule-calendar .filter-wrap .right-wrap {
  align-items: center;
  margin-left: auto;
  font-size: 12px;
  display: flex;
}

.schedule-calendar .filter-wrap .right-wrap .time-wrap {
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border: 1px solid #d3d2e5;
  border-radius: 6px;
  padding: 0 14px;
  line-height: 24px;
}

.schedule-calendar .filter-wrap .right-wrap .week-btn {
  color: #fff;
  background-color: #7d79ec;
  border-radius: 6px;
  margin: 0 6px;
  line-height: 24px;
  display: flex;
  box-shadow: 0 2px 17px #bab9d9;
}

.schedule-calendar .filter-wrap .right-wrap .week-btn .label {
  padding: 0 2px;
}

.schedule-calendar .filter-wrap .right-wrap .week-btn .arrow {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 24px;
  display: flex;
  position: relative;
}

.schedule-calendar .filter-wrap .right-wrap .week-btn .arrow:hover {
  background-color: #0003;
}

.schedule-calendar .filter-wrap .right-wrap .week-btn .arrow:after {
  content: "";
  border-top: 4px solid #0000;
  border-bottom: 4px solid #0000;
  border-right: 4px solid #fff;
  position: absolute;
}

.schedule-calendar .filter-wrap .right-wrap .week-btn .pre {
  border-radius: 6px 0 0 6px;
}

.schedule-calendar .filter-wrap .right-wrap .week-btn .after {
  border-radius: 0 6px 6px 0;
}

.schedule-calendar .filter-wrap .right-wrap .week-btn .after:after {
  transform: rotate(180deg);
}

.schedule-calendar .filter-wrap .right-wrap .week-btn .after.true:after {
  border-right-color: #ffffff75;
}

.schedule-calendar .filter-wrap .right-wrap .reset {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #7d79ec;
  border-radius: 6px;
  padding: 0 12px;
  line-height: 24px;
  box-shadow: 0 2px 17px #cdcce5;
}

.schedule-calendar .filter-wrap .right-wrap .reset:hover {
  background-color: #eee;
}

.schedule-calendar .table-wrap {
  border-radius: 18px;
  margin-top: 16px;
  font-size: 12px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 25px 50px #0000000a;
}

.schedule-calendar .table-wrap .t-h {
  background-color: #f2f4ff;
  border-bottom: 1px solid #e6e8f0;
  line-height: 30px;
  display: flex;
}

.schedule-calendar .table-wrap .t-1 {
  text-align: center;
  width: 32px;
}

.schedule-calendar .table-wrap .t-2 {
  width: 108px;
  padding-left: 18px;
}

.schedule-calendar .table-wrap .t-b .t-1 {
  background-color: #fafbff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.schedule-calendar .table-wrap .t-b .t-2 {
  background-color: #fff;
  border-bottom: 1px solid #f3f4f6;
  align-items: center;
  display: flex;
}

.schedule-calendar .table-wrap .t-b .t-2 .red {
  color: #d25b5b;
}

.schedule-calendar .table-wrap .table-left {
  flex: none;
}

.schedule-calendar .table-wrap .table-left.true {
  z-index: 1;
  position: relative;
  box-shadow: 2px 0 8px #0000001a;
}

.schedule-calendar .table-wrap .table-left .t-h {
  color: #7e86a6;
}

.schedule-calendar .table-wrap .table-left .t-b {
  height: 60px;
  display: flex;
}

.schedule-calendar .table-wrap .table-left .t-b:last-child .t-2 {
  border-bottom: 0;
}

.schedule-calendar .table-wrap .table-right {
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
}

.schedule-calendar .table-wrap .table-right .t-h {
  justify-content: center;
  padding-left: 0;
}

.schedule-calendar .table-wrap .table-right .t-b .t-2 {
  color: #22334394;
  justify-content: center;
  height: 60px;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: 600;
}

.schedule-calendar .table-wrap .table-right .t-b .t-2:last-child {
  border-bottom: 0;
}

.schedule-calendar .table-wrap .table-right .t-b .t-2 .link {
  text-align: center;
}

.schedule-calendar .table-wrap .table-right .t-b .t-2 .link:hover {
  color: #7d79ec;
  cursor: pointer;
}

.schedule-calendar .table-wrap .table-right .t-col.true .t-h {
  background-color: #eaecfe;
}

.schedule-calendar .table-wrap .table-right .t-col.true .t-2 {
  background-color: #f4f4fe;
  border-bottom: 1px solid #e6e8f0;
}

.schedule-calendar .table-wrap .table-right .t-col.dis {
  color: #7e86a6;
}

.schedule-calendar .table-wrap .table-right .t-col.dis .t-b .t-2 {
  color: #ddd;
}

.schedule-info-modal {
  z-index: 99;
  background: #00000057;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.schedule-info-modal .info-panel {
  background: #fff;
  border-radius: 8px;
  width: 868px;
  height: 534px;
}

.schedule-info-modal .info-panel .title-wrap {
  color: #333;
  align-items: center;
  padding: 30px 0 20px 35px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  position: relative;
}

.schedule-info-modal .info-panel .title-wrap .close-wrap {
  padding: 10px 20px;
  position: absolute;
  top: 15px;
  right: 0;
}

.schedule-info-modal .info-panel .title-wrap .close-wrap .close-icon {
  cursor: pointer;
  width: 22px;
  height: 23px;
  position: relative;
}

.schedule-info-modal .info-panel .title-wrap .close-wrap .close-icon:before, .schedule-info-modal .info-panel .title-wrap .close-wrap .close-icon:after {
  content: "";
  background-color: #333;
  width: 18px;
  height: 1px;
  position: absolute;
  top: 11px;
  left: 2px;
}

.schedule-info-modal .info-panel .title-wrap .close-wrap .close-icon:before {
  transform: rotate(45deg);
}

.schedule-info-modal .info-panel .title-wrap .close-wrap .close-icon:after {
  transform: rotate(135deg);
}

.schedule-info-modal .info-panel .table1 {
  padding-left: 35px;
  padding-right: 20px;
}

.schedule-info-modal .info-panel .table1 .col1 {
  width: 13%;
}

.schedule-info-modal .info-panel .table1 .col2 {
  width: 11%;
}

.schedule-info-modal .info-panel .table1 .col3 {
  width: 28%;
}

.schedule-info-modal .info-panel .table1 .col4, .schedule-info-modal .info-panel .table1 .col5 {
  color: #424292;
  width: 12%;
}

.schedule-info-modal .info-panel .table1 .col6 {
  width: 12%;
}

.schedule-info-modal .info-panel .table1 .col7 {
  color: #be2f2f;
  width: 12%;
  border: none !important;
}

.schedule-info-modal .info-panel .table1 .left {
  padding-left: 18px;
  justify-content: flex-start !important;
}

.schedule-info-modal .info-panel .table1 .head {
  color: #323232;
  background: #f8f8ff;
  border-radius: 8px;
  height: 34px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  overflow: hidden;
}

.schedule-info-modal .info-panel .table1 .head > div {
  border-right: 1px solid #dadafa;
  justify-content: center;
  align-items: center;
  display: flex;
}

.schedule-info-modal .info-panel .table1 .head .col4, .schedule-info-modal .info-panel .table1 .head .col5, .schedule-info-modal .info-panel .table1 .head .col6, .schedule-info-modal .info-panel .table1 .head .col7 {
  background: #d2d2f1;
  border-color: #b5b5e4;
}

.schedule-info-modal .info-panel .table1 .body .row {
  color: #000;
  background: #f7f8f8;
  border-radius: 8px;
  height: 34px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
}

.schedule-info-modal .info-panel .table1 .body .row > div {
  border-right: 1px solid #e9f0f0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.schedule-info-modal .info-panel .tab-wrap {
  border-bottom: 1px solid #f7f7f7;
  margin: 40px 20px 24px 35px;
  display: flex;
}

.schedule-info-modal .info-panel .tab-wrap .tab {
  color: #333;
  cursor: pointer;
  margin-right: 30px;
  padding: 0 5px 12px;
  font-size: 16px;
  font-weight: 600;
}

.schedule-info-modal .info-panel .tab-wrap .tab.true {
  color: #4944c7;
  border-bottom: 2px solid #7d79ec;
}

.schedule-info-modal .info-panel .table2 .col4, .schedule-info-modal .info-panel .table2 .col5 {
  color: #006861;
}

.schedule-info-modal .info-panel .table2 .head {
  background: #64c8bc24;
}

.schedule-info-modal .info-panel .table2 .head > div {
  border-color: #cbecea;
}

.schedule-info-modal .info-panel .table2 .head .col4, .schedule-info-modal .info-panel .table2 .head .col5, .schedule-info-modal .info-panel .table2 .head .col6, .schedule-info-modal .info-panel .table2 .head .col7 {
  background: #d0edeb;
  border-color: #a8dbd7;
}

.schedule-info-modal .info-panel .table2 .body {
  max-height: 200px;
  overflow: auto;
}

.schedule-info-modal .info-panel .table3 {
  padding-left: 35px;
  padding-right: 20px;
}

.schedule-info-modal .info-panel .table3 .col1 {
  width: 5%;
}

.schedule-info-modal .info-panel .table3 .col2, .schedule-info-modal .info-panel .table3 .col3, .schedule-info-modal .info-panel .table3 .col4 {
  width: 12%;
}

.schedule-info-modal .info-panel .table3 .col5 {
  width: 15%;
}

.schedule-info-modal .info-panel .table3 .col6, .schedule-info-modal .info-panel .table3 .col7, .schedule-info-modal .info-panel .table3 .col8 {
  width: 11%;
}

.schedule-info-modal .info-panel .table3 .col9 {
  width: 11%;
  border: none !important;
}

.schedule-info-modal .info-panel .table3 .left {
  padding-left: 10px;
  justify-content: flex-start !important;
}

.schedule-info-modal .info-panel .table3 .flex-c {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.schedule-info-modal .info-panel .table3 .green {
  color: #85c2be;
}

.schedule-info-modal .info-panel .table3 .head {
  color: #323232;
  background: #e9f7f6;
  border-radius: 8px;
  height: 44px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  overflow: hidden;
}

.schedule-info-modal .info-panel .table3 .head > div {
  border-right: 1px solid #cbecea;
  justify-content: center;
  align-items: center;
  display: flex;
}

.schedule-info-modal .info-panel .table3 .head .col6, .schedule-info-modal .info-panel .table3 .head .col7, .schedule-info-modal .info-panel .table3 .head .col8, .schedule-info-modal .info-panel .table3 .head .col9 {
  background: #d0edeb;
  border-color: #b5e2df;
}

.schedule-info-modal .info-panel .table3 .body {
  max-height: 200px;
  overflow: auto;
}

.schedule-info-modal .info-panel .table3 .body .row {
  color: #333;
  background: #8d9e9c14;
  border-radius: 8px;
  height: 34px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
}

.schedule-info-modal .info-panel .table3 .body .row > div {
  border-right: 1px solid #eee;
  justify-content: center;
  align-items: center;
  display: flex;
}

.schedule-info-modal .info-panel .over {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@font-face {
  font-family: Heavy;
  src: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/talk_admin/font/PuHui-Heavy.otf") format("truetype");
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  color: #333;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: auto;
  margin: 0;
  font-family: system-ui, -apple-system, Roboto, Helvetica Neue, Noto Sans, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.heavy {
  font-family: Heavy, sans-serif;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-400 {
  font-weight: 400;
}

.f-500 {
  font-weight: 500;
}

.f-600 {
  font-weight: 600 !important;
}

.l1 {
  line-height: 1;
}

.c-75 {
  color: #757575;
}

.c-9 {
  color: #999;
}

.c-0 {
  color: #000;
}

.red {
  color: red;
}

.orange {
  color: #fa931c;
}

.gray {
  color: #bebebe;
}

.black {
  color: #333;
}

.underline {
  text-decoration: underline;
}

.hand {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.flex {
  display: flex;
}

.flex.align {
  align-items: center;
}

.flex.between {
  justify-content: space-between;
}

.flex.wrap {
  flex-wrap: wrap;
}

.flex.center {
  justify-content: center;
}

.flex.left {
  justify-content: flex-start;
}

.button {
  cursor: pointer;
  border-radius: 6px;
  padding: 0 20px;
  line-height: 40px;
}

.button.block {
  color: #fff;
  background-color: #7d79ec;
}

.button.block:hover {
  background-color: #5a55e7;
}

.button.hollow {
  color: #7d79ec;
  border: 1px solid #7d79ec;
}

.button.hollow:hover {
  background-color: #eee;
}

.button.false {
  cursor: not-allowed;
  background-color: #ccc;
}

.button.false:hover {
  background-color: #ccc;
}

.plus-button {
  color: #fff;
  cursor: pointer;
  letter-spacing: 1px;
  background: #7d79ec;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 12px 0 28px;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  position: relative;
  box-shadow: 0 2px 10px #bab9d9;
}

.plus-button:hover {
  background-color: #5a55e7;
}

.plus-button:before {
  content: "";
  background-color: #fff;
  border-radius: 1px;
  width: 10px;
  height: 2px;
  position: absolute;
  top: 15px;
  left: 12px;
}

.plus-button:after {
  content: "";
  background-color: #fff;
  border-radius: 1px;
  width: 2px;
  height: 10px;
  position: absolute;
  top: 11px;
  left: 16px;
}

.plus-button.no-icon {
  padding-left: 12px;
}

.plus-button.no-icon:before, .plus-button.no-icon:after {
  display: none;
}

.plus-button .download-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/download.png");
  background-size: cover;
  width: 14px;
  height: 14px;
  margin-top: -2px;
  margin-right: 6px;
}

.com-check {
  border: 2px solid #c7c5c5;
  border-radius: 6px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.com-check.true {
  background-color: #7d79ec;
  border-color: #7d79ec;
  position: relative;
}

.com-check.true:after {
  content: "";
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  width: 6px;
  height: 10px;
  position: absolute;
  top: 2px;
  left: 5px;
  transform: rotate(45deg);
}

.com-check-solid {
  background-color: #9f9eae;
  border-radius: 4px;
  width: 14px;
  height: 14px;
  margin-right: 5px;
  position: relative;
}

.com-check-solid:after {
  content: "";
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  width: 5px;
  height: 8px;
  position: absolute;
  top: 2px;
  left: 5px;
  transform: rotate(45deg);
}

.com-check-solid.true {
  background-color: #7d79ec;
}

.pagination {
  margin-top: 16px;
  margin-bottom: 16px;
}

.empty-msg {
  text-align: center;
  color: #d2d4db;
  padding: 10px 0;
  font-size: 12px;
}

.com-input {
  border-radius: 6px;
  flex-grow: 1;
  height: 30px;
  padding-left: 14px;
  display: flex;
  box-shadow: 0 0 0 1px #d6d6e6;
}

.com-input:hover {
  box-shadow: 0 0 0 1px #7d79ec;
}

.com-input .input {
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #0000;
  border: none;
  flex-grow: 1;
  font-size: 14px;
  overflow: hidden;
}

.com-input .input:focus {
  outline: 0;
}

.com-input .input.only-text {
  line-height: 30px;
}

.com-input .input.gray-text {
  color: #d2d4db;
  line-height: 30px;
}

.com-input .input[disabled] {
  cursor: not-allowed;
}

.com-input.with-arrow {
  cursor: pointer;
  position: relative;
}

.com-input.with-arrow .input {
  padding-right: 30px;
}

.com-input.with-arrow:after {
  content: "";
  pointer-events: none;
  border-top: 1px solid #3d4a62;
  border-left: 1px solid #3d4a62;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -75%)rotate(225deg);
}

.com-input.true {
  box-shadow: 0 0 0 2px #7d79ec;
}

.com-input.disabled {
  cursor: not-allowed;
  background-color: #ddd;
}

.com-input.disabled:hover {
  box-shadow: 0 0 0 1px #d6d6e6;
}

.com-text {
  border-radius: 6px;
  flex-grow: 1;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 14px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 0 1px #d6d6e6;
}

.com-text:hover {
  box-shadow: 0 0 0 1px #7d79ec;
}

.com-text .text {
  resize: none;
  border: none;
  flex-grow: 1;
  padding: 0;
  font-family: system-ui, -apple-system, Roboto, Helvetica Neue, Noto Sans, Arial, sans-serif;
  overflow: auto;
}

.com-text .text:focus {
  outline: 0;
}

.com-text.true {
  box-shadow: 0 0 0 2px #7d79ec;
}

.input-group {
  align-items: center;
  display: flex;
  position: relative;
}

.input-group .label {
  color: #757575;
}

.input-group .label.required {
  position: relative;
}

.input-group .label.required:after {
  content: "*";
  color: #fa931c;
  font-weight: 800;
  position: absolute;
  left: -8px;
}

.input-group.with-text {
  align-items: flex-start;
}

.input-group.with-text .label {
  line-height: 32px;
}

.input-group .unit {
  background-color: #eee;
  border-radius: 0 6px 6px 0;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-weight: 500;
  display: flex;
  position: absolute;
  right: 0;
}

.com-salary {
  align-items: center;
  display: flex;
}

.com-salary .type-wrap {
  margin-right: 10px;
}

.com-salary .type-wrap .ant-select {
  width: 80px;
}

.com-salary .gap {
  padding: 0 4px;
}

.com-choose-city {
  cursor: pointer;
  flex-grow: 1;
}

.ant-select {
  width: 120px;
}

.ant-select .ant-select-selector:hover {
  border-color: #7d79ec !important;
}

.ant-select .ant-select-arrow .anticon-down {
  width: 9px;
  height: 9px;
  position: relative;
}

.ant-select .ant-select-arrow .anticon-down:after {
  content: "";
  border-top: 1px solid #3d4a62;
  border-left: 1px solid #3d4a62;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -75%)rotate(225deg);
}

.ant-select .ant-select-arrow .anticon-down svg {
  display: none;
}

.ant-select.ant-select-focused .ant-select-selector {
  border-color: #7d79ec !important;
  transition: none !important;
  box-shadow: 0 0 0 1px #7d79ec !important;
}

.ant-select-item-option-selected {
  background-color: #e8e8fc !important;
}

.ant-picker-outlined:hover {
  box-shadow: none;
  border-color: #7d79ec;
}

.ant-picker-outlined.ant-picker-focused {
  box-shadow: 0 0 0 1px #7d79ec;
}

.ant-picker-outlined .ant-picker-active-bar {
  background-color: #7d79ec;
}

.ant-picker-now-btn, .ant-picker-now-btn:hover {
  color: #7d79ec;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #7d79ec !important;
}

.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-color: #7d79ec !important;
}

.ant-pagination .ant-pagination-item-active, .ant-pagination .ant-pagination-item-active:hover {
  border-color: #7d79ec;
}

.ant-pagination .ant-pagination-item-active a, .ant-pagination .ant-pagination-item-active:hover a, .ant-radio-button-wrapper:hover {
  color: #7d79ec;
}

.ant-radio-button-wrapper-checked {
  background: #7d79ec !important;
  border-color: #7d79ec !important;
}

::placeholder {
  color: #d2d4db;
}

input {
  -moz-appearance: textfield;
  padding: 0;
}

input::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.com-sex {
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.com-sex .item {
  cursor: pointer;
  color: #404a60;
  border: 1px solid #d6d6e6;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  margin-right: 10px;
  font-size: 14px;
  display: flex;
}

.com-sex .item.w {
  width: 50px;
}

.com-sex .item.true {
  color: #7d79ec;
  background: #7d79ec1f;
  border: 2px solid #7d79ec;
  font-weight: 600;
}

.com-choose-panel {
  z-index: 99;
  background: #00000057;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.com-choose-panel .panel-wrap {
  background: #fff;
  border-radius: 10px;
  width: 700px;
}

.com-choose-panel .panel-wrap .title-wrap {
  border-bottom: 1px solid #f7f7f7;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  display: flex;
}

.com-choose-panel .panel-wrap .title-wrap .title {
  color: #333;
  padding-left: 26px;
  font-size: 16px;
  font-weight: 600;
}

.com-choose-panel .panel-wrap .title-wrap .close {
  cursor: pointer;
  width: 65px;
  height: 65px;
  position: relative;
}

.com-choose-panel .panel-wrap .title-wrap .close:before {
  content: "";
  background-color: #333;
  width: 20px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.com-choose-panel .panel-wrap .title-wrap .close:after {
  content: "";
  background-color: #333;
  width: 20px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.com-choose-panel .panel-wrap .content-wrap {
  background: #f8f8f8;
  max-height: 300px;
}

.com-choose-panel .panel-wrap .content-wrap .list-wrap {
  flex-wrap: wrap;
  padding: 25px 15px 25px 25px;
  display: flex;
}

.com-choose-panel .panel-wrap .content-wrap .list-wrap .item {
  -webkit-user-select: none;
  user-select: none;
  color: #333;
  cursor: pointer;
  background: #ebebeb;
  border-radius: 5px;
  height: 34px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 34px;
}

.com-choose-panel .panel-wrap .content-wrap .list-wrap .item.true {
  color: #fff;
  background: #7f83f5;
  font-weight: 500;
}

.com-choose-panel .panel-wrap .check-wrap {
  padding: 16px 25px 0;
  display: flex;
}

.com-choose-panel .panel-wrap .check-wrap .label {
  color: #999;
  flex-shrink: 0;
  margin-top: 6px;
  font-size: 14px;
}

.com-choose-panel .panel-wrap .check-wrap .check-box {
  flex-wrap: wrap;
  padding-right: 10px;
  display: flex;
}

.com-choose-panel .panel-wrap .check-wrap .check-box .item {
  color: #333;
  background: #f2f2f2;
  border-radius: 5px;
  align-items: center;
  height: 34px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0 10px;
  font-size: 14px;
  line-height: 34px;
  display: flex;
}

.com-choose-panel .panel-wrap .check-wrap .check-box .item .del-icon {
  cursor: pointer;
  background: #d8d8d8;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-left: 7px;
  position: relative;
}

.com-choose-panel .panel-wrap .check-wrap .check-box .item .del-icon:before {
  content: "";
  background: #a6a6a6;
  width: 8px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.com-choose-panel .panel-wrap .check-wrap .check-box .item .del-icon:after {
  content: "";
  background: #a6a6a6;
  width: 8px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.com-choose-panel .panel-wrap .check-wrap .check-box .item .del-icon:hover {
  opacity: .75;
}

.com-choose-panel .panel-wrap .button-wrap {
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 56px 30px 26px;
  display: flex;
}

.com-choose-panel .panel-wrap .button-wrap .confirm {
  color: #fff;
  cursor: pointer;
  background: #7f83f5;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 34px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.com-choose-panel .panel-wrap .button-wrap .confirm:hover {
  background-color: #5a55e7;
}

.com-modal {
  z-index: 999;
  background-color: #00000057;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.com-modal .com-modal-wrap {
  background-color: #fff;
  border-radius: 8px;
  width: 700px;
}

.com-modal .com-modal-wrap .modal-head {
  border-bottom: 2px solid #f7f7f7;
  align-items: center;
  height: 64px;
  padding-left: 30px;
  display: flex;
}

.com-modal .com-modal-wrap .modal-head .modal-title {
  font-size: 16px;
  font-weight: 600;
}

.com-modal .com-modal-wrap .modal-head .close-wrap {
  margin-left: auto;
  padding: 10px 20px;
}

.com-modal .com-modal-wrap .modal-head .close-wrap .close-icon {
  cursor: pointer;
  width: 22px;
  height: 23px;
  position: relative;
}

.com-modal .com-modal-wrap .modal-head .close-wrap .close-icon:before, .com-modal .com-modal-wrap .modal-head .close-wrap .close-icon:after {
  content: "";
  background-color: #333;
  width: 18px;
  height: 1px;
  position: absolute;
  top: 11px;
  left: 2px;
}

.com-modal .com-modal-wrap .modal-head .close-wrap .close-icon:before {
  transform: rotate(45deg);
}

.com-modal .com-modal-wrap .modal-head .close-wrap .close-icon:after {
  transform: rotate(135deg);
}

.com-modal .com-modal-wrap .modal-action {
  justify-content: flex-end;
  padding: 28px;
  display: flex;
}

.hr-form .com-modal-wrap {
  width: 600px;
}

.hr-form .modal-content {
  padding-top: 16px;
  padding-left: 30px;
}

.hr-form .modal-content .input-group {
  margin-top: 20px;
}

.hr-form .modal-content .com-input {
  width: 126px;
}

.hr-form .modal-content .email .com-input {
  width: 200px;
}

.hr-form .modal-content .label {
  margin-left: 20px;
}

.pos-form .pos-content {
  padding-right: 28px;
}

.pos-form .pos-content .input-group {
  margin-top: 20px;
}

.pos-form .pos-content .fix-width {
  justify-content: flex-end;
  width: 90px;
  display: flex;
}

.pos-form .pos-content .with-text {
  height: 310px;
}

.pos-list-modal .modal-content {
  height: 300px;
  padding: 0 28px;
  overflow-y: auto;
}

.pos-list-modal .position-item {
  margin-top: 10px;
}

.pos-list-modal .position-item .position-head {
  background-color: #f6f7f7;
  border-radius: 8px;
  align-items: center;
  height: 34px;
  padding-right: 8px;
  display: flex;
}

.pos-list-modal .position-item .position-head .com-check {
  margin-left: 8px;
  margin-right: 0;
}

.pos-list-modal .position-item .position-head.true {
  background-color: #e9f7f5;
}

.pos-list-modal .position-item .name-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 220px;
  padding-left: 10px;
  overflow: hidden;
}

.pos-list-modal .position-item .pro-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
  overflow: hidden;
}

.pos-list-modal .position-item .city-wrap {
  flex-grow: 1;
}

.pos-list-modal .position-item .edit-wrap {
  cursor: pointer;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  display: flex;
}

.pos-list-modal .position-item .edit-wrap .edit-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/all/e.png");
  background-size: cover;
  width: 14px;
  height: 14px;
}

.pos-list-modal .position-item .edit-wrap:hover {
  background-color: #e9e9f5;
}

.pos-list-modal .position-item .toggle-wrap {
  cursor: pointer;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  position: relative;
}

.pos-list-modal .position-item .toggle-wrap:before {
  content: "";
  border-top: 2px solid #8f95b2;
  border-left: 2px solid #8f95b2;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.pos-list-modal .position-item .toggle-wrap.up:before {
  transform: translate(-50%, -25%)rotate(45deg);
}

.pos-list-modal .position-item .toggle-wrap.down:before {
  transform: translate(-50%, -75%)rotate(225deg);
}

.pos-list-modal .position-item .toggle-wrap:hover {
  background-color: #e8e8e8;
}

.pos-list-modal .position-item .position-detail {
  height: 0;
  padding: 0 6px;
  overflow: hidden;
}

.pos-list-modal .position-item .position-detail.true {
  height: unset;
}

.pos-list-modal .position-item .position-detail .detail-wrap {
  background-color: #fcfcfc;
  border-radius: 0 0 8px 8px;
  max-height: 200px;
  padding: 15px 30px;
  overflow-y: auto;
}

.pos-list-modal .position-item .position-detail .detail-wrap .f-600 {
  padding-bottom: 8px;
}

.pos-list-modal .position-item .position-detail .detail-wrap .detail {
  white-space: pre-wrap;
}

.ab-form .title {
  padding-top: 12px;
  padding-bottom: 10px;
}

.ab-form .left-wrap .title {
  padding-left: 36px;
}

.ab-form .left-wrap .menu-wrap {
  width: 160px;
  height: 290px;
  padding-left: 20px;
  padding-right: 10px;
  overflow-y: scroll;
}

.ab-form .ab-item {
  color: #666;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 2px;
  padding: 0 16px;
  line-height: 32px;
  overflow: hidden;
}

.ab-form .ab-item:hover {
  background-color: #f9f9f9;
}

.ab-form .ab-item.true {
  background-color: #f2f2f2;
}

.ab-form .right-wrap {
  flex-grow: 1;
  padding-left: 20px;
}

.ab-form .right-wrap .ab-item {
  background-color: #f2f2f2;
  margin-bottom: 10px;
  margin-right: 14px;
}

.ab-form .right-wrap .ab-item:hover {
  background-color: #e8e8fc;
}

.ab-form .right-wrap .ab-item.true {
  color: #fff;
  background-color: #7d79ec;
}

.ab-form .right-wrap .gray-bg {
  background-color: #f8f8f8;
  border-radius: 6px;
  min-height: 200px;
  margin-left: -10px;
  margin-right: 20px;
  padding-left: 10px;
}

.ab-form .right-wrap .gray-bg .tip {
  color: #a6a6a6;
}

.ab-form .select-wrap {
  height: 32px;
  margin-bottom: -10px;
  padding-left: 180px;
}

.ab-form .select-wrap .ab-item {
  color: #7d79ec;
  background-color: #e8e8fc;
  margin-bottom: 0;
}

.b-modal-form .modal-content {
  height: 160px;
  padding: 20px;
}

.b-modal-form .modal-content .b-modal {
  background-color: #f6f7f7;
  border-radius: 8px;
  padding-left: 16px;
  line-height: 34px;
}

.b-modal-form .modal-content .b-modal .item {
  margin-right: 50px;
}

.data-table-modal .img-wrap {
  width: 700px;
  height: 300px;
  overflow: hidden auto;
}

.data-table-modal .btn-wrap {
  padding: 30px;
}

.data-table-modal .btn-wrap .plus-button {
  width: 100px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

.logout-link:hover {
  color: #7d79ec;
}
/*# sourceMappingURL=index.6ff983d1.css.map */
