@import "../variables";

.com-avatar {
  @include size(88);
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 0 1px #F3F3FE;
  .tip {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: rgba(0,0,0,.4);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    height: 20px;
    .tip-icon {
      @include size(12);
      background-image: url($img + '/all/e1.png');
      background-size: cover;
      margin-right: 4px;
    }
  }
  .empty {
    @include size(88);
    background-color: #F3F3FE;
    background-image: url($img + '/all/can.png');
    background-repeat: no-repeat;
    background-size: 29px 26px;
    background-position: center;
  }
  .input-file {
    position: absolute;
    top: 0;
    left: 0;
    @include size(88);
    opacity: 0;
    cursor: pointer;
  }
}