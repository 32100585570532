@import "../components/variables";

.com-ass-input {
  position: relative;
  .ass-pop {
    position: absolute;
    z-index: 1;
    top: 34px;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    border-radius: 8px;
    padding: 4px;
    .pop-item {
      padding: 4px 15px;
      cursor: pointer;
      border-radius: 6px;
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}