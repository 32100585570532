@import "../components/variables";

.account-form-wrap {
  width: 976px;
  margin: auto;
  .form-panel {
    background-color: #fff;
    box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.04);
    border-radius: 14px;
    padding: 40px 300px 40px 80px;

    .caption {
      font-size: 20px;
      color: #b0bbc5;
    }
    .title {
      font-size: 18px;
      padding-top: 40px;
      padding-bottom: 30px;
    }
    .fix-width {
      width: 172px;
    }
  }

  .input-section {
    display: flex;
    padding-bottom: 20px;
    &.align {
      align-items: center;
    }
    .input-label {
      line-height: 34px;
      color: #757575;
      width: 120px;
      flex: none;
      text-align: right;
      padding-right: 14px;
    }
    .flex-label {
      line-height: 34px;
      color: #757575;
      padding-right: 14px;
    }
  }

  .star-level {
    .ant-select,
    .com-input {
      width: 184px;
    }
  }

  .short-wrap {
    width: 172px;
  }

  .action-wrap {
    justify-content: flex-end;
    .hollow {
      margin-right: 20px;
    }
  }

  .goal-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;
    .input-section {
      margin-right: 16px;
      padding-bottom: 10px;
    }
    .input-label {
      width: unset;
      padding-right: 0;
    }
    .fix-width {
      width: 76px;
    }
  }

  .account-list {
    padding-left: 120px;
    margin-top: -10px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .city-item {
      display: flex;
      color: #7C86A9;
      background-color: #F1F1FC;
      padding-left: 8px;
      border-radius: 8px;
      line-height: 24px;
      margin-bottom: 10px;
      font-weight: 600;
      .label {
        flex: none;
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        .item {
          margin-right: 10px;
        }
      }
      &.red {
        background-color: #FBE4E4;
        color: #CF5660;
        font-weight: 400;
      }
    }
  }
}
